import { AddBox as AddIcon, Delete as DeleteIcon,Edit as EditIcon } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import type { ReactNode } from "react"

//was heigTH

interface CardActionProps {
    actionType?: "add" | "edit" | "delete"
    onClick?: (e: any) => void
    children?: ReactNode
    icon?: any
    custom?: boolean
}
export function CardAction(props: CardActionProps) {
    const { actionType, children, custom, icon, onClick } = props

    const Icon = icon || { add: AddIcon, edit: EditIcon, delete: DeleteIcon }[actionType] || null

    if (!custom) {
        return (
            <div style={{ height: "100%" }}>
                <IconButton onClick={onClick} size="large">
                    <Icon />
                </IconButton>
            </div>
        )
    } else {
        return <div style={{ height: "100%" }}>{children}</div>
    }
}
