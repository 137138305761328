import { jsx } from "@emotion/react"
import { CheckBox } from "@mui/icons-material"
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, List, ListItem, ListItemIcon, ListItemText, Radio, RadioGroup, RadioGroupProps, ToggleButton, ToggleButtonGroup, ToggleButtonProps, Tooltip } from "@mui/material"
import { useStateWithDeps } from "core/utils"
import { ReactNode, useEffect, useState } from "react"

export interface RawRadioGroupProps<OptionData, M extends boolean> {
    multiple?: M
    size?: "small" | "medium"
    value?: M extends true ? Array<any> : any
    row?: boolean
    options?: Array<OptionData>
    legend?: string
    error?: boolean
    helperText?: string
    missing?: boolean
    necessary?: boolean
    onChange?: M extends true ? (selectedValues: Array<any>, selectedDatas: Array<OptionData>) => void : (selectedValue: any, selectedData: OptionData) => void
    reversible?: boolean
    configure: (option: OptionData) => { value: any; label?: string | ReactNode; disabled?: boolean; secondaryLabel?: string | ReactNode; renderListItem?: (option: OptionX<OptionData>, options: OptionX<OptionData>[]) => JSX.Element }
    disabled?: boolean
    orientation?: "vertical" | "horizontal"
    variant: "checkbox" | "radio" | "button" | "checkbox-list" | "radio-list"
    _toggleButton?: Partial<ToggleButtonProps>
}

export function useRadioGroup<OptionData, M extends boolean>(params: { initialValue?: M extends true ? OptionData[] : OptionData } = {}) {
    const { initialValue } = params
    // const initialValue = multiple ? (Array.isArray(initialValue) ? initialValue : []) : initialValue
    const [state, setState] = useState([initialValue, null])

    const [selectedValue, selectedData] = state
    return {
        selectedValue,
        selectedData,
        handleChange: (newValues, newDatas) => {
            setState([newValues, newDatas])
        },
    }
}

type OptionX<OptionData> = { value: any; label?: any; secondaryLabel?: string; renderListItem?: (option: OptionX<OptionData>, options: OptionX<OptionData>[]) => JSX.Element; disabled: boolean; selected: boolean; data: OptionData }
export function RawRadioGroup<OptionData, M extends boolean>(props: RawRadioGroupProps<OptionData, M>) {
    const { configure, legend, options, row = true, error, helperText, missing, necessary, value = null, onChange, reversible, size = "small", orientation = "horizontal", disabled = false, multiple, variant, _toggleButton } = props
    const optionsX = options.map((option) => {
        const optX = configure(option)
        return {
            ...optX,
            data: option,
        }
    }) as any as Array<OptionX<OptionData>>

    const initialValue = multiple ? (Array.isArray(value) ? value : []) : value
    const [currentValue, setCurrentValue] = useStateWithDeps(initialValue)

    const handleChange = (clickedIndex: number) => {
        const clickedOptionX = optionsX[clickedIndex]

        if (!multiple) {
            let newValue
            let newData

            if (reversible && clickedOptionX.selected) {
                newValue = null
                newData = null
            } else {
                newValue = clickedOptionX.value
                newData = clickedOptionX.data
            }
            setCurrentValue(newValue)
            onChange && onChange(newValue, newData)
        } else {
            let newValues: any[]
            if (!clickedOptionX.selected) {
                newValues = [...currentValue, clickedOptionX.value]
            } else {
                newValues = (currentValue as any[]).reduce((acc, val) => {
                    if (val !== clickedOptionX.value) acc.push(val)
                    return acc
                }, [])
            }

            const newDatas = optionsX.reduce((acc, optX) => {
                if (newValues.includes(optX.value)) acc.push(optX.data)
                return acc
            }, [])

            setCurrentValue(newValues)
            onChange && onChange(newValues, newDatas as any)
        }
    }

    //check from value
    if (multiple) {
        for (const val of currentValue as any[]) {
            if (currentValue !== undefined || currentValue !== null) {
                const selectedOpt = optionsX.find((optX) => optX.value === val)
                if (selectedOpt) selectedOpt.selected = true
            }
        }
    } else {
        if (currentValue !== undefined || currentValue !== null) {
            const selectedOpt = optionsX.find((optX) => optX.value === currentValue)
            if (selectedOpt) selectedOpt.selected = true
        }
    }

    const isMissing = missing ?? (necessary && (multiple ? !(currentValue as any[]).length : currentValue === undefined || currentValue === null || (currentValue as any) === ""))

    return (
        <FormControl component="fieldset" error={error} disabled={disabled}>
            {(legend || isMissing) && (
                <FormLabel
                    component="legend"
                    sx={{
                        fontSize: size,
                        "&, &.Mui-focused": {
                            ...(!error && isMissing && { color: (theme) => theme.palette.warning.main }),
                            ...(error && { color: (theme) => theme.palette.error.main }),
                            ...((variant === "radio" || variant === "checkbox") && orientation === "horizontal" && { marginTop: "-7px" }),
                        },
                    }}
                >
                    {!error && isMissing ? (
                        <Tooltip title="Nécessaire" placement="right" arrow>
                            <span>{`${legend || ""} ⚠️`}</span>
                        </Tooltip>
                    ) : (
                        legend || ""
                    )}
                </FormLabel>
            )}

            <>
                {variant === "radio" && (
                    <RadioGroup
                        // value={currentValue}
                        // onChange={e => onChange(e.target.value)}
                        row={orientation === "horizontal"}
                    >
                        {optionsX.map((optionX, index) => (
                            <FormControlLabel
                                key={index}
                                value={optionX.value}
                                label={optionX.label}
                                disabled={disabled || optionX.disabled}
                                control={
                                    <Radio
                                        size={size}
                                        checked={optionX.selected ?? false}
                                        onClick={() => {
                                            handleChange(index)
                                        }}
                                        sx={{
                                            "&, &.Mui-checked": {
                                                ...(!error && isMissing && { color: (theme) => theme.palette.warning.main }),
                                                ...(error && { color: (theme) => theme.palette.error.main }),
                                            },
                                        }}
                                    />
                                }
                            />
                        ))}
                    </RadioGroup>
                )}

                {variant === "checkbox" && (
                    <FormGroup
                        // value={currentValue}
                        // onChange={e => onChange(e.target.value)}
                        row={orientation === "horizontal"}
                    >
                        {optionsX.map((optionX, index) => (
                            <FormControlLabel
                                key={index}
                                value={optionX.value}
                                label={optionX.label}
                                disabled={disabled || optionX.disabled}
                                control={
                                    <Checkbox
                                        size={size}
                                        checked={optionX.selected ?? false}
                                        onClick={() => {
                                            handleChange(index)
                                        }}
                                        sx={{
                                            "&, &.Mui-checked": {
                                                ...(!error && isMissing && { color: (theme) => theme.palette.warning.main }),
                                                ...(error && { color: (theme) => theme.palette.error.main }),
                                            },
                                        }}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                )}

                {variant === "button" && (
                    <ToggleButtonGroup orientation={orientation} disabled={disabled} size={size}>
                        {optionsX.map((optionX, index) => (
                            <ToggleButton
                                key={optionX.value}
                                selected={optionX.selected ?? false}
                                value={optionX.value}
                                disabled={disabled || optionX.disabled}
                                size={size}
                                onClick={() => {
                                    handleChange(index)
                                }}
                                children={optionX.label}
                                {..._toggleButton}
                                sx={{
                                    ..._toggleButton?.sx,
                                    ...(!error && isMissing && { border: (theme) => `1px solid ${theme.palette.warning.main}` }),
                                    ...(error && { border: (theme) => `1px solid ${theme.palette.error.main}` }),
                                }}
                            />
                        ))}
                    </ToggleButtonGroup>
                )}

                {variant === "checkbox-list" && (
                    <List
                    // value={currentValue}
                    // onChange={e => onChange(e.target.value)}
                    // row={orientation === 'horizontal'}
                    >
                        {optionsX.map((optionX, index) => (
                            <ListItem
                                key={index}
                                disabled={disabled || optionX.disabled}
                                // dense
                                button
                                onClick={() => {
                                    handleChange(index)
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        size={size}
                                        checked={optionX.selected ?? false}
                                        tabIndex={-1}
                                        disableRipple
                                        sx={{
                                            "&, &.Mui-checked": {
                                                ...(!error && isMissing && { color: (theme) => theme.palette.warning.main }),
                                                ...(error && { color: (theme) => theme.palette.error.main }),
                                            },
                                        }}
                                    />
                                </ListItemIcon>

                                {optionX.renderListItem ? optionX.renderListItem(optionX, optionsX) : <ListItemText primary={optionX.label} secondary={optionX.secondaryLabel} />}
                            </ListItem>
                        ))}
                    </List>
                )}

                {variant === "radio-list" && (
                    <List
                    // value={currentValue}
                    // onChange={e => onChange(e.target.value)}
                    // row={orientation === 'horizontal'}
                    >
                        {optionsX.map((optionX, index) => (
                            <ListItem
                                key={index}
                                disabled={disabled || optionX.disabled}
                                // dense
                                button
                                onClick={() => {
                                    handleChange(index)
                                }}
                            >
                                <ListItemIcon>
                                    <Radio
                                        size={size}
                                        checked={optionX.selected ?? false}
                                        tabIndex={-1}
                                        disableRipple
                                        sx={{
                                            "&, &.Mui-checked": {
                                                ...(!error && isMissing && { color: (theme) => theme.palette.warning.main }),
                                                ...(error && { color: (theme) => theme.palette.error.main }),
                                            },
                                        }}
                                    />
                                </ListItemIcon>

                                {optionX.renderListItem ? optionX.renderListItem(optionX, optionsX) : <ListItemText primary={optionX.label} secondary={optionX.secondaryLabel} />}
                            </ListItem>
                        ))}
                    </List>
                )}
            </>

            {helperText && <FormHelperText sx={{ marginLeft: "0px", ...(!error && isMissing && { color: (theme) => theme.palette.warning.main }) }}>{helperText}</FormHelperText>}
        </FormControl>
    )
}
