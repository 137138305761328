import { useAuthContext } from "core/technic/auth"
import { isApiError } from "core/utils"
import { OptionsObject as NotistackOptionsObject, useSnackbar } from "notistack"
import { useErrorHandler as useBoundaryErrorHandler } from "react-error-boundary"

// export function isApiError<T extends { name: string }>(type: T["name"], err: any): err is T {
//     return err.name && err.name === type
// }

export type ErrorHandlerOptions = BoundaryErrorHandlerOptions | SnackbarErrorHandlerOptions

export type BoundaryErrorHandlerOptions = {
    mode: "boundary"
    log?: boolean
    disableCommonErrorsHandling?: boolean
}

export type SnackbarErrorHandlerOptions = {
    mode: "snackbar"
    log?: boolean
    message?: string
    hideDetails?: boolean
    disableCommonErrorsHandling?: boolean
} & NotistackOptionsObject

export function useErrorHandler() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const forwardErrorToClosestErrorBoundary = useBoundaryErrorHandler()
    const handleCommonErrors = useCommonErrorsHandler()
    const defaultErrorHandler = (err: any, options?: ErrorHandlerOptions) => {
        const defaultOptions: ErrorHandlerOptions = { mode: "snackbar", persist: true, log: true, variant: "error", hideDetails: false, message: "Une erreur imprévue s'est produite" }
        const mergedOptions = Object.assign(defaultOptions, options)

        mergedOptions.log && console.log(err)

        if (!mergedOptions.disableCommonErrorsHandling) {
            const isHandled = handleCommonErrors(err)
            if (isHandled) return
        }

        if (mergedOptions.mode === "snackbar") {
            const { mode, log, message, hideDetails, ...snackbarOptions } = mergedOptions
            const snackbarKey = enqueueSnackbar(<SnackbarErrorMessage message={mergedOptions.message!} details={!mergedOptions.hideDetails && err.message ? err.message : null} />, snackbarOptions)
            return { snackbarKey }
        }

        if (mergedOptions.mode === "boundary") {
            forwardErrorToClosestErrorBoundary(err)
            return
        }
    }

    return { defaultErrorHandler }
}

function SnackbarErrorMessage({ message, details }: { message: string; details?: string }) {
    return (
        <div>
            <div>{message}</div>
            {details && <pre style={{ fontSize: "8pt" }}>{details}</pre>}
        </div>
    )
}

/** Traite les erreurs communes et récurentes */
function useCommonErrorsHandler() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const auth = useAuthContext()

    return (err: unknown) => {
        let isHandled = false

        if (isApiError("ComptaLocked", err)) {
            isHandled = true
            enqueueSnackbar(
                `La comptabilité est verrouillée jusqu'au ${new Date(err.data.comptaLockedOn).toLocaleDateString(undefined, { dateStyle: "short" })}. Vous ne pouvez pas appliquer des modifications au ${new Date(err.data.opDateOn).toLocaleDateString(undefined, {
                    dateStyle: "short",
                })}`,
                { variant: "warning" }
            )
            //TODO: faire fonctionner pour les requètes rasmik. (pour l'instant elles throw une RasmikError, ce qui ne convient pas)
        } else if (isApiError("InsufficientPermissions", err)) {
            enqueueSnackbar("Vous ne disposez pas des droits nécessaires pour effectuer cette action.", { variant: "warning" })
        } else if (isApiError("UnsuppliedToken", err) || isApiError("ExpiredToken", err) || isApiError("InvalidToken", err)) {
            enqueueSnackbar("Session invalide, veuillez vous reconnecter", { variant: "error" })
            auth.eraseToken()
        }

        return isHandled
    }
}

//TODO: improve error handling
// case "DATABASE_FOREING_KEY":
//     typeMsg = "Opération non autorisée par la base de données car l'élément est lié à d'autres tableaux de données"
//     break

// case "NODEMAILER_UNDEFINED_SENDER":
//     typeMsg = "L'adresse email de l'expediteur n'a pas été paramétrée sur le serveur"
//     break

// case "DATABASE_VALIDATION":
//     typeMsg = "Données incomplètes ou incorrectes : " + err.response.data.error_message
//     break
