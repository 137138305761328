import { useEzActions } from "core/store"
import { useAuthContext } from "core/technic/auth"
import React, { useEffect } from "react"
import { Navigate, useLocation } from "react-router-dom"

//to avoid rendering a page without a valid token (and current user info)
//It is used in combination with a middleware before every request that display login modal without breaking the flow
// export function PrivateRoute(props: ComponentProps<typeof Route>) {

//   if(hasUnexpiredToken()){
//     return <Route  {...props} element={<Navigate to="login" /*state={{ from: props.location }}*//>}   />
//   }else{
//     return <Route  {...props}    />
//   }

// }

interface Props {
    component: React.ComponentType
    path?: string
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
    const auth = useAuthContext()
    const { pathname } = useLocation()

    const initDb = useEzActions((s) => s.core.db.initDb)
    useEffect(() => {
        auth.isLoggedIn && initDb(300)
    }, [auth.isLoggedIn])

    //On ne redirige que si pas loggué (si le token est expiré, pas grave, ce sera géré par le login popup.)
    if (auth.isLoggedIn) {
        return <RouteComponent />
    } else {
        console.log("auth.isLoggedIn is false : redirecting to login ")
        return <Navigate to={`/login?next=${pathname || "/home"}`} />
    }
}
