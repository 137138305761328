import { Grid, GridProps } from "@mui/material"

type GridOn<P extends object> = P & {
    grid: true
    GridProps?: GridProps
} & Pick<GridProps, "xs" | "sm" | "md" | "lg" | "xl">

type GridOff<P extends object> = P & {
    grid?: false | undefined | never
} & { [K in "GridProps" | "xs" | "sm" | "md" | "lg" | "xl"]?: never }

export type WithGridProps<P extends object> = GridOn<P> | GridOff<P>

export function withGrid<P extends object>(Component: React.ComponentType<P>): React.ComponentType<WithGridProps<P>> {
    return function (props) {
        if (props.grid) {
            const { grid, GridProps, lg, md, sm, xl, xs, ...componentProps } = props
            return (
                <Grid item {...{ ...GridProps, lg, md, sm, xl, xs }}>
                    <Component {...(componentProps as any)} />
                </Grid>
            )
        } else {
            const { grid, ...componentProps } = props
            return <Component {...(componentProps as any)} />
        }
    }
}
