import { withGrid, WithGridProps } from "core/misc"
import { RawSwitch, RawSwitchProps } from "core/rawFields"
import { FastField, FastFieldProps, useField, useFormikContext } from "formik"

export interface FmkFastSwitchProps<OptionData extends object> extends Omit<RawSwitchProps, "value" | "onChange" | "error"> {
    name: string
    validateImmediately?: boolean
}

export const FmkFastSwitch = withGrid(<OptionData extends object>(props: FmkFastSwitchProps<OptionData>) => {
    const { name, validateImmediately, ...otherProps } = props

    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps<string>) => {
                const handleChange = (newValue: any, optionData: any) => {
                    form.setFieldTouched(name, true, false)
                    form.setFieldValue(name, newValue, true)
                }

                const defaultProps: Partial<RawSwitchProps> = {
                    onChange: handleChange,
                }

                const config = {
                    ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
                    ...defaultProps,
                    ...otherProps,
                }
                if (meta && (meta.touched || validateImmediately) && meta.error) {
                    config.error = true
                    config.helperText = meta.error
                }

                config.disabled = config.disabled ?? form.isSubmitting

                return <RawSwitch {...config} />
            }}
        </FastField>
    )
})
