import { EzModel } from "core/store/base"
import { Action, Computed, Model, Property } from "easy-peasy-classes"

@Model
export class commonTimeRangeSliderModel extends EzModel {
    @Property
    startTime: number = 8 * 60

    @Property
    endTime: number = 22 * 60

    @Property
    minTime: number = 8 * 60

    @Property
    maxTime: number = 22 * 60

    @Property
    stepSize: number = 30

    @Action
    handleChange([newStartTime, newEndTime]: [number, number]) {
        this.startTime = newStartTime
        this.endTime = newEndTime
    }

    @Computed
    get getMarks() {
        const marks = []
        for (let h = Math.ceil(this.minTime / 60) * 60; h <= this.maxTime; h += 60) {
            marks.push({
                value: h,
                label: (h / 60).toString() + "h",
            })
        }
        return marks
    }

    @Computed
    get getStepsCount() {
        return (this.maxTime - this.minTime) / this.stepSize
    }
}
