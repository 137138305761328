import { notistack } from "core/utils/notistack-global"
import { isArray, isString } from "lodash"
import { useQuery, UseQueryOptions } from "react-query"

export const useQueryError: typeof useQuery = (...args: any[]) => {
    const onError = (err: Error) => {
        console.log(err)
        notistack.legacy({ severity: "error", msg: "Erreur pendant la récupération des données. Voir détails dans la console (F12)" })
    }

    if (args.length === 1 && !isString(args[0]) && !isArray(args[0])) {
        const options: UseQueryOptions = args[0] ?? {}
        options.onError ??= onError
        args[0] = options
    } else if (typeof args[1] === "function") {
        const options: UseQueryOptions = args[2] ?? {}
        options.onError ??= onError
        args[2] = options
    } else {
        const options: UseQueryOptions = args[1] ?? {}
        options.onError ??= onError
        args[1] = options
    }

    //@ts-ignore
    return useQuery(...args)
}
