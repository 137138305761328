import { commonDialogModel } from "core/controls"
import type { EmailTemplate } from "core/services/rasmik"
import { action } from "easy-peasy"
import { Action, Child, Model, Property, Thunk } from "easy-peasy-classes"

type templateData = { template: EmailTemplate; newDesign: Record<string, any>; newHtml: string }
@Model
export class editTemplateModel extends commonDialogModel<templateData> {
    @Property
    template: EmailTemplate

    @Child
    unlayer = {
        html: null as string,
        design: null as any,
        //ref:undefined,

        onCollect: action<{ html: string; design: string }>((state, { html, design }) => {
            state.html = html
            state.design = design
        }),
    }

    @Action
    setDesign(design: string) {
        this.unlayer.design = design
    }

    @Action
    setTemplate(template: EmailTemplate) {
        this.template = template
    }

    @Thunk
    async init({ template }: { template: EmailTemplate }) {
        this.setTemplate(template)
        const parsedDesign = JSON.parse(template.design)
        this.setDesign(parsedDesign)

        this.show()
        return this.promise()
    }

    @Thunk
    validate({ design, html }: { design: any; html: string }) {
        const templateData: templateData = { template: this.template, newDesign: design, newHtml: html }
        this.endDialog(["success", templateData])
    }

    @Thunk
    cancel() {
        this.endDialog(["cancel"])
    }
}
