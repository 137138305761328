import { alertTitleClasses, Grid, TextField, TextFieldProps } from "@mui/material"
import { withGrid } from "core/misc"
import { RawDateField, RawDateFieldProps } from "core/rawFields/RawDateField.com"
import { useField, useFormikContext } from "formik"

export interface FmkDateFieldProps extends Omit<RawDateFieldProps, "value" | "onChange" | "error"> {
    name: string
    validateImmediately?: boolean
}

export const FmkDateField = withGrid((props: FmkDateFieldProps) => {
    const { name, validateImmediately, ...otherProps } = props
    const [field, meta, { setValue }] = useField<string>(name)
    const { isSubmitting } = useFormikContext()

    const defaultProps: Partial<RawDateFieldProps> = {
        fullWidth: true,
        variant: "outlined",
        size: "small",
    }

    const config = {
        ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
        ...defaultProps,
        ...otherProps,
    }

    if (meta && (meta.touched || validateImmediately) && meta.error) {
        config.error = true
        config.helperText = meta.error
    }

    config.disabled = config.disabled ?? isSubmitting

    return <RawDateField {...config} onChange={(dateString) => setValue(dateString)} />
})
