import { isString } from "lodash"
import * as Yup from "yup"
export function testSecu(enumss: string) {
    // qnumss est un controle type input type=text, qnumss.value en est le contenu
    // traitement cas de la Corse on rend la zone a tester numérique

    var s7 = enumss.substring(6, 7)
    if (s7 === "A" || s7 === "B") {
        enumss = enumss.substring(0, 6) + "0" + enumss.substring(7, 15)
    }

    // La zone doit alors etre numérique et ne contenir que 15 digits on valide par expression régulière
    var filter = /^\d{15}$/
    if (!filter.test(enumss)) {
        return false
    }
    // la zone est numérique avec 15 caractères
    else {
        if (enumss.substring(0, 1) !== "1" && enumss.substring(0, 1) !== "2") return false
        var deb13 = parseInt(enumss.substring(0, 13), 10)
        // traiter le cas de la corse
        if (s7 === "A") deb13 -= 1000000
        if (s7 === "B") deb13 -= 2000000
        // La Clé SS est égale au reste de la division par 97
        var div97 = 97 - (deb13 % 97)
        const sKey = enumss.substring(13, 15)
        const nKey = parseInt(sKey, 10)

        if (nKey !== div97) {
            console.log("Clé Sécurité Sociale incorrecte devrait etre " + div97.toString().padStart(2, "0"))
            return false
        }

        return true
    }
}

export function testIban(rawInput: any) {
    if (!rawInput || !isString(rawInput)) return false

    const input = rawInput.replace(/\s/g, "")

    var CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
        AL: 28,
        BY: 28,
        EG: 29,
        GE: 22,
        IQ: 23,
        LC: 32,
        SC: 31,
        ST: 25,
        SV: 28,
        TL: 23,
        UA: 29,
        VA: 22,
        VG: 24,
        XK: 20,
    }

    var iban = String(input)
            .toUpperCase()
            .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits
    // check syntax and length
    //@ts-ignore
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3]! + code[1]! + code[2]!).replace(/[A-Z]/g, function (letter) {
        return String(letter.charCodeAt(0) - 55)
    })
    // final check
    return mod97Iban(digits) === 1
}

function mod97Iban(string: string) {
    let checksum: string = string.slice(0, 2),
        fragment: string
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = checksum + string.substring(offset, offset + 7)
        checksum = String(parseInt(fragment, 10) % 97)
    }
    return parseInt(checksum)
}

export function testBic(rawInput: any) {
    if (!rawInput || !isString(rawInput)) return false
    return /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/.test(rawInput)
}

export function validateSync(schema: Yup.AnySchema, value: any): [valid: boolean, messages: string[], yupError: Yup.ValidationError] {
    let validationError: Yup.ValidationError
    let isValid = true
    try {
        schema.validateSync(value, { abortEarly: false })
    } catch (err) {
        if (err instanceof Yup.ValidationError) {
            validationError = err
            isValid = false
        } else throw err
    }

    return [isValid, validationError?.errors || [], validationError]
}
