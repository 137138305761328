import { Button, Dialog, DialogActions, DialogContent,DialogTitle } from "@mui/material/"
import { useEzActions, useEzState, useMapper } from "core/store"

import { CheckBoxList$ } from "../controls"

export function LevelsOfTeachingCheckBoxList() {
    const µ = useMapper((s) => s.core.dialogs.levelsOfTeaching)
    const unitActions = useEzActions((s) => µ(s))
    const isOpen = useEzState((s) => µ(s).dialog.isOpen)
    const title = useEzState((s) => µ(s).dialog.title)

    return (
        <Dialog open={isOpen}>
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                <CheckBoxList$ id="checkBoxList" mapper={(s) => µ(s).checkBoxList} />
            </DialogContent>

            <DialogActions>
                <Button onClick={() => unitActions.endDialog(["cancel"])} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => unitActions.validate()} color="primary" autoFocus>
                    Accepter
                </Button>
            </DialogActions>
        </Dialog>
    )
}

//onClose={unitState.onClose}
