import { defineQuery } from "core/queries"
import { DBMonth, rasmik } from "core/services/rasmik"
import { max } from "lodash"

export const db_months = defineQuery("dbMonthsQuery", () => ({
    key: ["db_months"],
    queryFn: async (qryCtx, auth) => {
        await auth!.ensureValidToken()
        const matches = await rasmik
            .readMany(DBMonth)
            .options({ include: ["month", "legalInvoicesLockedAt"] })
            .run()
        return matches
    },
    defaultOptions: { useErrorBoundary: true },
})).withHelpers(({ data }) => {
    return {
        lastLegalInvoiceMonth: max((data ?? []).filter((item) => !!item.legalInvoicesLockedAt).map((item) => item.month)) ?? null,
    }
})

export type DBMonthsData = (typeof db_months)["dataShape"]
export type DBMonthsItem = DBMonthsData[number]
