import { compact } from "lodash"
import type React from "react"

type Def<P extends React.ComponentType> = { provider: P; props?: Omit<React.ComponentProps<P>, "children"> }
export const withContext = function <
    P1 extends React.ComponentType,
    P2 extends React.ComponentType,
    P3 extends React.ComponentType,
    P4 extends React.ComponentType,
    P5 extends React.ComponentType,
    P6 extends React.ComponentType,
    P7 extends React.ComponentType,
    P8 extends React.ComponentType,
    P9 extends React.ComponentType,
    P10 extends React.ComponentType
>(def1: Def<P1>, def2?: Def<P2>, def3?: Def<P3>, def4?: Def<P4>, def5?: Def<P5>, def6?: Def<P6>, def7?: Def<P7>, def8?: Def<P8>, def9?: Def<P9>, def10?: Def<P10>) {
    return (Component) => (props) =>
        compact([def1, def2, def3, def4, def5, def6, def7, def8, def9, def10]).reduceRight((acc, provDef) => {
            //@ts-ignore
            return <provDef.provider {...provDef.props}>{acc}</provDef.provider>
        }, <Component {...props} />)
}
