import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material"
import { styled } from "@mui/material"
import * as colors from "@mui/material/colors"
import clsx from "clsx"
import type { ReactNode, useEffect } from "react"

const TdStyled = styled("td", { shouldForwardProp: (key) => !["keyMaxWidth", "cellType", "rowSpacing"].includes(String(key)) })<{ keyMaxWidth?: string; cellType: "value" | "key" | "action"; rowSpacing?: number }>(({ theme, keyMaxWidth, cellType, rowSpacing = 3 }) => ({
    paddingRight: theme.spacing(rowSpacing),
    color: colors.grey[600],
    maxWidth: keyMaxWidth,
    ...(cellType === "key" && {
        paddingRight: theme.spacing(rowSpacing),
        color: colors.grey[600],
        maxWidth: keyMaxWidth,
    }),
    ...(cellType === "value" && {}),
}))

export type DoubleColumnsListDataItem = {
    divider?: boolean
    action?: React.ElementType
    key?: React.ReactNode
    value?: React.ReactNode
}
export type DoubleColumnsListData = DoubleColumnsListDataItem[]
interface DoubleColumnsListProps {
    data?: Array<DoubleColumnsListDataItem>
    keyMaxWidth?: string
    rowSpacing?: number
}

export const DoubleColumnsList = ({ data = [], keyMaxWidth = "", rowSpacing = 1 }: DoubleColumnsListProps) => {
    const KeyItem = ({ content }: { content: ReactNode }) => {
        return <Typography>{content}</Typography>
    }

    const ValueItem = ({ content }: { content: ReactNode }) => {
        if (typeof content === "function") {
            const Content = content
            return Content
        } else {
            return <Typography component="div">{content}</Typography>
        }
    }

    const ActionItem = ({ action: Action }: { action: React.ElementType }) => {
        if (Action) {
            return <Action />
        } else {
            return null
        }
    }

    return (
        <table style={{ width: "100%" }}>
            <tbody>
                {data.map((row, index) => {
                    if (row.divider)
                        return (
                            <tr key={index}>
                                <td colSpan={3}>
                                    <Divider />
                                </td>
                            </tr>
                        )

                    return (
                        <tr key={index}>
                            <TdStyled cellType="key" keyMaxWidth={keyMaxWidth} rowSpacing={rowSpacing}>
                                <KeyItem content={row.key} />
                            </TdStyled>
                            <TdStyled cellType="value" keyMaxWidth={keyMaxWidth} rowSpacing={rowSpacing}>
                                <ValueItem content={row.value} />
                            </TdStyled>
                            <TdStyled cellType="action" keyMaxWidth={keyMaxWidth} rowSpacing={rowSpacing} style={{ textAlign: "right" }}>
                                <ActionItem action={row.action} />
                            </TdStyled>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
