import { Button, ButtonProps } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

function LinkForButtonLink({ focusElementRef, ...props }: any) {
    return <Link {...props} />
}

export const ButtonLink = React.forwardRef(function ButtonLink(props: ButtonProps & { to: string; target?: string }, ref) {
    return <Button {...props} component={LinkForButtonLink} />
})
