import { useFormikContext } from "formik"
import type { CSSProperties } from "react"

export function PreJson({ data, indent = 2, style }: { data: any; indent?: number | null; style?: CSSProperties }) {
    return <pre style={{ padding: "5px", ...style }}>{JSON.stringify(data, null, indent)}</pre>
}

export function PreFmkValues({ indent = 2, style }: { indent?: number | null; style?: CSSProperties }) {
    const { values, errors } = useFormikContext()
    if (process.env.NODE_ENV === "production") return null
    return <PreJson data={{ ...(values as object), errors }} indent={indent} />
}
