export const DOPPLE_API_URL = process.env.REACT_APP_DOPPLE_API_URL
export const YOUSIGN_URL = process.env.REACT_APP_YOUSIGN_URL

//export const APPLI_API_URL = 'http://vps390501.ovh.net:3002';
export const SESSION_DURATION_WARNING1 = 20
export const SESSION_DURATION_WARNING2 = 30

export const exercises = [
    { id: 1, start: "2017-09-01", end: "2018-07-31" },
    { id: 2, start: "2018-08-01", end: "2019-07-31" },
    { id: 3, start: "2019-08-01", end: "2020-07-31" },
    { id: 4, start: "2020-08-01", end: "2021-07-31" },
    { id: 5, start: "2021-08-01", end: "2022-07-31" },
    { id: 6, start: "2022-08-01", end: "2023-07-31" },
]
