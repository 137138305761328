import type { PhoneCall } from "core/services/rasmik-client"

import styles from "./PhoneCallIcon.module.css"
export function PhoneCallIcon({ phoneCall }: { phoneCall: Pick<PhoneCall, "lastState" | "io"> }) {
    if (phoneCall.io === "i") {
        switch (phoneCall.lastState) {
            case "ANSWERED":
                return <div className={`${styles.phoneCallIcon} ${styles.in_answered}`} />

            case "MISSED":
            case "QUEUE_TIMEOUT":
            case "NOANSWER_TRANSFERED":
            case "BLIND_TRANSFERED":
                return <div className={`${styles.phoneCallIcon} ${styles.missed}`} />

            case "FAILED":
                return <div className={`${styles.phoneCallIcon} ${styles.missed}`} />

            case "VOICEMAIL":
                return <div className={`${styles.phoneCallIcon} ${styles.voicemail}`} />

            case "ANNOUNCE":
                return <div className={`${styles.phoneCallIcon} ${styles.closed}`} />

            default:
                return <div>{phoneCall.lastState}</div>
        }
    } else {
        switch (phoneCall.lastState) {
            case "ANSWERED":
                return <div className={`${styles.phoneCallIcon} ${styles.out_answered}`} />

            case "CANCELLED":
                return <div className={`${styles.phoneCallIcon} ${styles.out_cancelled}`} />

            case "FAILED":
                return <div className={`${styles.phoneCallIcon} ${styles.out_failed}`} />

            // case "CANCELLED":
            //     return
            default:
                return <div>{phoneCall.lastState}</div>
        }
    }
}
