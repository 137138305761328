import type { AuthCtx } from "core/technic/auth/auth-context"
import { Action, Child, Model, Property, Thunk, ToStoreType } from "easy-peasy-classes"
import type { familyHubModel } from "pages/family-hub/familyHub.model"
//only type is imported -> valid for code splitting
import type { mainHubModel } from "pages/main-hub"
import type { teacherHubModel } from "pages/teacher-hub"

import { EzModel } from "./base"
import { coreModel } from "./core.model"
//type tabDef = [string,string]
//type tabs = Array<tabDef>

@Model
export class topModel extends EzModel {
    @Child
    core: coreModel = coreModel.obj

    @Property
    initialState: any /*State<ToStoreType<Omit<topModel,'initialState'>>>*/ = null

    @Action
    setInitialState(initialState: any) {
        this.initialState = initialState
    }

    @Child
    mainHub: mainHubModel = null

    @Child
    teacherHub: teacherHubModel = null

    @Child
    familyHub: familyHubModel = null

    @Property
    auth: AuthCtx = {} as AuthCtx

    @Action
    setAuth(auth: AuthCtx) {
        this.auth = auth
    }
}
