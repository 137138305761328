import { EzModel } from "core/store/base"
import { Action, Model, Property, Thunk } from "easy-peasy-classes"

interface dialog {
    title: string
    isOpen: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const resolveMap = new Map<symbol, Function>()

@Model
export class commonDialogModel<outcome, status = "success" | "cancel"> extends EzModel {
    @Property
    symbol: symbol

    @Property
    dialog: dialog = {
        title: "",
        isOpen: false,
    }

    @Thunk
    endDialog(payload: [status: status, outcome?: outcome]) {
        const outsideResolve = resolveMap.get(this.symbol)
        this.hide()
        outsideResolve(payload)
    }

    @Action
    show({ title }: { title?: string } = { title: "" }) {
        this.dialog.isOpen = true
        if (title) {
            this.dialog.title = title
        }
    }

    @Action
    hide() {
        this.dialog.isOpen = false
    }

    @Thunk
    promise() {
        const symbol = Symbol()
        this.setStateFn((s) => {
            s.symbol = symbol
        })
        return new Promise<[status: status, outcome: outcome]>((resolve) => {
            resolveMap.set(symbol, resolve)
        })
    }
}
