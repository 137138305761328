/* eslint-disable simple-import-sort/exports */
import axios, { AxiosHeaders } from "axios"
import { RasmikClient } from "core/services/rasmik-client"
import { RasmikError } from "core/services/rasmik-client/lib/client"
import { DOPPLE_API_URL } from "core/utils/constants"
import { ClientError, logError } from "core/utils/errors"
import { notistack } from "core/utils/notistack-global"

import { getUserToken } from "./userService"

export * from "./rasmik-client/entities"
export type { PushData, ReadData } from "./rasmik-client/lib"

const isoDateFormat = /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/
const isIsoDateString = (val: any) => typeof val === "string" && isoDateFormat.test(val)

const rasmikAxios = axios.create({
    baseURL: process.env.REACT_APP_DOPPLE_API_URL,

    //revive dates
    transformResponse: (data) => {
        if (typeof data !== "string") return data
        try {
            return JSON.parse(data, (key, value) => {
                return isIsoDateString(value) ? new Date(value) : value
            })
        } catch (err) {
            return data
        }
    },
})

//set auth header from localeStorage
rasmikAxios.interceptors.request.use((config) => {
    if (!config.headers) config.headers = new AxiosHeaders()
    if (!config.headers.has("Authorization")) config.headers.set("Authorization", getUserToken())
    return config
})

//return res.data instead of res
// rasmikAxios.interceptors.response.use(
//     (res) => {
//         if (res.data) return res.data
//         else return res
//     },
//     (err) => {
//         if (axios.isAxiosError(err) && err.response && err.response.data) {
//             throw err.response.data
//         } else {
//             throw err
//         }
//     }
// )

export const rasmik = new RasmikClient({
    axios: rasmikAxios,
    baseUrl: DOPPLE_API_URL + "/rasmik",
    onError: (err) => {
        if (err instanceof RasmikError) {
            const snackbarId = notistack.enqueueSnackbar(`Erreur en lien avec une requète rasmik. Voir détails dans la console`, { variant: "error", persist: true })
            const rethrownError = new ClientError({ message: "Rasmik request responded with error", previous: err })
            logError(rethrownError)
            rethrownError.snackbarId = snackbarId
            throw rethrownError
        } else {
            const snackbarId = notistack.enqueueSnackbar(`Erreur en lien avec une requète rasmik. Voir détails dans la console`, { variant: "error", persist: true })
            const rethrownError = new ClientError({ message: "Failed to execute rasmik request", previous: err })
            logError(rethrownError)
            rethrownError.snackbarId = snackbarId
            throw rethrownError
        }
    },
})
