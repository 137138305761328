import { createElement, ReactNode, useEffect, useRef, useState } from "react"

function createPopup(_ref: { url: string; title: string; height: number; width: number; shouldAutoClose?: (url: string) => boolean; onManualClose?: (url: string) => any; onAutoClose?: (url: string) => any }) {
    const { title = "", url, height = 500, width = 500, shouldAutoClose, onManualClose, onAutoClose } = _ref

    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    const externalPopup = window.open(url, title, "width=".concat(String(width), ",height=").concat(String(height), ",left=").concat(`${left}`, ",top=").concat(`${top}`))

    externalPopup?.addEventListener("close", (event) => {
        const wind = event.target as Window
        const url = wind.location.href
        if (shouldAutoClose && shouldAutoClose(url)) {
            // onAutoClose && onAutoClose(url)
        } else {
            onManualClose && onManualClose(url)
        }
    })
    return externalPopup
}

export interface PopupProps {
    open: boolean
    url?: string
    urlFn?: () => string
    title?: string
    height?: number
    width?: number
    children?: ReactNode
    onUrlChange?: (url: string) => any
    onManualClose?: (url: string | null) => any
    onAutoClose?: (url: string) => any
    shouldAutoClose?: (url: string) => boolean
}
export function Popup(props: PopupProps) {
    return <>{props.open && <PopupContent {...props} />}</>
}

export function PopupContent(props: PopupProps) {
    const { open, title = "", url, urlFn, height = 500, width = 500, children, shouldAutoClose, onUrlChange, onManualClose, onAutoClose } = props

    const [externalWindow, setExternalWindow] = useState<Window | null>()

    const intervalRef = useRef<number>()
    const urlRef = useRef<string>()

    const clearTimer = function clearTimer() {
        window.clearInterval(intervalRef.current)
    }

    async function init() {
        if (!url && !urlFn) {
            return
        } else {
            clearTimer()
            setExternalWindow(
                createPopup({
                    url: urlFn ? await urlFn() : url!, //added
                    title: title,
                    width: width,
                    height: height,
                })
            )
        }
    }

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, urlFn])

    function detectUrlChanges({ withClearTimer }: { withClearTimer: boolean }) {
        if (externalWindow) {
            try {
                const currentUrl = externalWindow.location.href
                console.log("popup url : " + currentUrl)
                if (currentUrl !== urlRef.current) {
                    urlRef.current = currentUrl
                    onUrlChange && onUrlChange(currentUrl)
                    if (shouldAutoClose && shouldAutoClose(currentUrl)) {
                        externalWindow.close()
                        withClearTimer && clearTimer()
                        onAutoClose && onAutoClose(currentUrl)
                    }
                }
            } catch (error) {
                // eslint-ignore-line
            } finally {
                if (!externalWindow || externalWindow.closed) {
                    setExternalWindow(null)
                    onManualClose && onManualClose(externalWindow?.location.href ?? null)
                    withClearTimer && clearTimer()
                }
            }
        }
    }

    useEffect(function () {
        if (externalWindow) {
            detectUrlChanges({ withClearTimer: false })
            intervalRef.current = window.setInterval(() => detectUrlChanges({ withClearTimer: true }), 700)
        }

        //effect cleanup function
        return function () {
            // if (externalWindow) externalWindow.close(); //retiré car le popup se fermait tt seul pour les free payments
            clearTimer()
        }
    })

    return <>{children}</>
}
