import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file

import { Button, Dialog, DialogActions, DialogContent,DialogTitle } from "@mui/material/"
import { useEzActions, useEzState, useMapper } from "core/store"
import { DayDate } from "core/utils/tools"
import frLocale from "date-fns/locale/fr"
import type { ToStoreType } from "easy-peasy-classes"
import { DateRange } from "react-date-range"

export function DateRanges() {
    const µ = useMapper((s) => s.core.dialogs.dateRanges)
    const dialogState = useEzState((s) => µ(s).dialog)
    const rangesState = useEzState((s) => µ(s).getRanges)
    const unitActions = useEzActions((s) => µ(s))

    function unpack<T>(x: T): T extends ToStoreType<infer U> ? U : T {
        //TODO: generalise unpack logic
        return x as any
    }

    return (
        <Dialog open={dialogState.isOpen}>
            <DialogTitle>Plage horaire</DialogTitle>

            <DialogContent>
                <DateRange
                    editableDateInputs={true}
                    onChange={(rangesByKey) => unitActions.handlePickerChange(rangesByKey)}
                    moveRangeOnFirstSelection={false}
                    ranges={unpack(rangesState)}
                    scroll={{ enabled: true }}
                    minDate={new Date()}
                    maxDate={DayDate.today().toLuxonDateTime().plus({ years: 2 }).toJSDate()}
                    locale={frLocale}
                    dateDisplayFormat="d MMM yyyy"
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={() => unitActions.endDialog(["cancel"])} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => unitActions.validate()} color="primary" autoFocus>
                    Accepter
                </Button>
            </DialogActions>
        </Dialog>
    )
}
