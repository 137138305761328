import { usePrevious } from "core/hooks/usePrevious"
import { useEffect, useState } from "react"

export function useStateWithDeps<T>(initialValue: T, deps?: React.DependencyList) {
    deps = deps ?? [initialValue]

    const [state, setState] = useState<T>(initialValue)

    useEffect(() => {
        setState(initialValue)
    }, [deps])

    return [state, setState] as const
}

//Semble créer des bugs

export function useStateReinitialise<T>(initialValue: T, hasChanged: (current: T, previous: T) => boolean = (current, previous) => previous !== current) {
    const [state, setState] = useState<T>(initialValue)

    const previous = usePrevious(initialValue)

    useEffect(() => {
        if (previous !== undefined && hasChanged(initialValue, previous)) {
            setState(initialValue)
        }
    })

    return [state, setState] as const
}
