import { Tooltip, Typography } from "@mui/material"
import clsx from "clsx"
import type { LiteralUnion } from "core/utils/types"
import { useFormikContext } from "formik"
import type { CSSProperties, ReactNode } from "react"

import { Bx } from "."

// export function SectionBox({ children, title, borderColor = 'black', autoRainbow = false, overlay, overlayMessage }: { children: ReactNode | ReactNode[], title: string, borderColor?: LiteralUnion<'rainbow', CSSProperties['borderColor']>, autoRainbow?: boolean, overlay?: boolean, overlayMessage?: string }) {

//     const ctx = useFormikContext()
//     if (ctx && ctx.dirty && autoRainbow) borderColor = 'rainbow'

//     return (
//         <Bx id="margin" width="100%" height="100%" padding="20px" overflowY="auto">
//             <Bx position="relative" width="100%" height="100%" padding="40px" border={borderColor !== 'rainbow' ? "0px solid" : "1px solid"} borderRadius="15px" borderColor={borderColor !== 'rainbow' && borderColor} className={clsx((borderColor === 'rainbow' && "rainbow-border"))} boxShadow="rgba(0, 0, 0, 0.2) 0px 14px 30px -10px">
//                 {overlay && <div id="overlay" style={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     position: "absolute",
//                     top: '0px',
//                     bottom: "0px",
//                     left: "0px",
//                     right: "0px",
//                     zIndex: 100,
//                     background: "rgba( 255, 255, 255, 0.2 )",
//                     // boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
//                     backdropFilter: "blur( 2.3px )",
//                     WebkitBackdropFilter: "blur( 2.3px )",
//                     borderRadius: "10px"
//                 }}
//                 >{overlayMessage && <Typography style={{ color: 'rgb(160 160 160)', fontSize: "2em" }}>{overlayMessage}</Typography>}  </div>}

//                 {/* <div id="scoll-container" style={{ overflowY: "auto", height:"100%" }} > */}

//                 <Typography style={{ position: "absolute", color: "black", top: "-22px", left: "10px" }}>{title}</Typography>
//                 {children}
//                 {/* </div> */}
//                 <div />

//             </Bx>
//         </Bx>
//     )
// }

export function SectionBox({
    children,
    title,
    borderColor = "black",
    autoRainbow = false,
    overlay,
    overlayMessage,
    style,
}: {
    children: ReactNode | ReactNode[]
    title?: string
    borderColor?: LiteralUnion<"rainbow", CSSProperties["borderColor"]>
    autoRainbow?: boolean
    overlay?: boolean
    overlayMessage?: string
    style?: CSSProperties
}) {
    if (autoRainbow) {
        //eslint-disable-next-line
        const ctx = useFormikContext()
        if (ctx && ctx.dirty && autoRainbow) borderColor = "rainbow"
    }

    return (
        <Bx id="margin" width="100%" height="100%" padding="20px" position="relative">
            {title && <Typography style={{ position: "absolute", color: "black", top: "-10px", left: "20px" }}>{title}</Typography>}

            <Bx
                position="relative"
                width="100%"
                height="100%"
                padding="40px"
                border={borderColor !== "rainbow" ? "0px solid" : "1px solid"}
                borderRadius="15px"
                borderColor={borderColor !== "rainbow" && borderColor}
                className={clsx(borderColor === "rainbow" && "rainbow-border")}
                boxShadow="rgba(0, 0, 0, 0.2) 0px 14px 30px -10px"
                overflowY="auto"
            >
                {overlay && (
                    <Tooltip title={overlayMessage}>
                        <div
                            id="overlay"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                top: "0px",
                                bottom: "0px",
                                left: "0px",
                                right: "0px",
                                zIndex: 100,
                                background: "rgba( 255, 255, 255, 0.2 )",
                                // boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                                backdropFilter: "blur( 2.3px )",
                                WebkitBackdropFilter: "blur( 2.3px )",
                                borderRadius: "10px",
                                // ...style
                            }}
                        >
                            {" "}
                        </div>
                    </Tooltip>
                )}

                {/* <div id="scoll-container" style={{  position: "relative", top: "-40px", left: "-40px", right: "-40px", bottom: "-40px" }} >
                    <div id="scoll-zone" style={{ overflowY: "auto", height: "100%", width: "100%", padding: "40px" }} > */}

                {children}
                {/* </div>
                </div> */}
            </Bx>
        </Bx>
    )
}
