import { ToggleButton,ToggleButtonGroup } from "@mui/material"
import { createLocalHooks } from "core/store"

import type { commonWeekDayToggleGroupModel } from "./weekDayToggleGroup.model"

export function WeekDayToggleGroup$(props: { mapper: (s: any) => any }) {
    const { mapper: µ, ...rest } = props
    const { useLocalState, useLocalActions } = createLocalHooks<commonWeekDayToggleGroupModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    return (
        <ToggleButtonGroup value={unitState.value} exclusive onChange={(e, newValue) => unitActions.handleChange(newValue)} aria-label="day of week">
            <ToggleButton value={1}>Lundi</ToggleButton>

            <ToggleButton value={2}>Mardi</ToggleButton>

            <ToggleButton value={3}>Mercredi</ToggleButton>

            <ToggleButton value={4}>Jeudi</ToggleButton>

            <ToggleButton value={5}>Vendredi</ToggleButton>

            <ToggleButton value={6}>Samedi</ToggleButton>

            <ToggleButton value={7}>Dimanche</ToggleButton>
        </ToggleButtonGroup>
    )
}
