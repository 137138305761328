import { EzModel } from "core/store/base"
import { Action, Computed, Model, Property } from "easy-peasy-classes"

@Model
export class commonComboBoxModel extends EzModel {
    @Property
    value: any = null

    @Property
    inputValue: string = ""

    @Property
    rows: any[] = []

    @Property
    isDisabled: boolean = false

    @Property
    isError: boolean = false

    @Property
    verifyFn: (value: any) => boolean = (value) => true

    @Property
    helperText: string = ""

    @Property
    labelKey: string = "name"

    @Property
    lookupKey: string = "id"

    @Property
    label: string = ""

    @Action
    verify() {
        this.isError = this.verifyFn(this.value)
    }

    @Action
    handleChange(newValue: any) {
        this.value = newValue
    }

    @Action
    handleInputChange(newValue: string) {
        this.inputValue = newValue
    }

    @Action
    _setData(data: any[]) {
        this.rows = data
    }

    @Action
    setRows(rows: any[]) {
        this.rows = rows
    }

    @Action
    setValue(lookupKeyValue: string | number) {
        if (!this.lookupKey) return

        const row = this.rows?.find((row) => row[this.lookupKey] === lookupKeyValue)

        if (!row) return
        this.value = row
        this.inputValue = row[this.labelKey]
    }

    @Action
    setSettings({ lookupKey, labelKey, helperText, verifyFn }: Partial<Pick<commonComboBoxModel, "lookupKey" | "labelKey" | "helperText" | "verifyFn">>) {
        if (lookupKey) this.lookupKey = lookupKey
        if (labelKey) this.labelKey = labelKey
        if (verifyFn) this.verifyFn = verifyFn
        if (helperText) this.helperText = helperText
    }

    @Action
    setError(error: string) {
        if (!error) {
            this.isError = false
            this.helperText = ""
        } else {
            this.isError = true
            this.helperText = error
        }
    }

    @Computed
    get getValue() {
        return typeof this.value === "object" && this.value !== null ? this.value[this.lookupKey] : null
    }
}

export const commonComboBox = () => commonComboBoxModel.obj

// export const commonComboBox = ()=>({

//     value:null,
//     inputValue:'',
//     rows:[],
//     isDisabled:false,
//     isError:false,
//     verifyFn: (value)=>(value===value),
//     helperText: '',
//     labelKey:'name',
//     lookupKey:'id',

//     verify:action((state) => {
//         //@ts-ignore
//         state.isError = state.verifyFn(state.value);
//     }),

//     handleChange: action((state,newValue)=>{
//         //@ts-ignore
//         state.value = newValue;
//     }),

//     handleInputChange: action((state,newValue)=>{
//         //@ts-ignore
//         state.inputValue = newValue;
//     }),

//     _setData: action((state,data)=>{
//         //@ts-ignore
//         state.rows = data;
//     }),

//     setRows: action((state,rows)=>{
//         //@ts-ignore
//         state.rows = rows;
//     }),

//     setValue:action((state,lookupKeyValue)=>{
//         //@ts-ignore
//         if(!state.lookupKey) return;

//         //@ts-ignore
//         const row = state.rows?.find(row=>row[state.lookupKey] == lookupKeyValue)

//         if(!row) return;
//         //@ts-ignore
//         state.value = row;
//         //@ts-ignore
//         state.inputValue = row[state.labelKey];
//     }),

//     setSettings:action((state,payload)=>{
//         const {lookupKey,labelKey,helperText, verifyFn} = payload;

//         //@ts-ignore
//         if (lookupKey) state.lookupKey = lookupKey;
//         //@ts-ignore
//         if (labelKey) state.labelKey = labelKey;
//         //@ts-ignore
//         if (verifyFn) state.verifyFn = verifyFn;
//         //@ts-ignore
//         if (helperText) state.helperText = helperText;

//     }),
//     //@ts-ignore
//     getValue: computed(state => (typeof state.value === "object" && state.value !== null)  ? state.value[state.lookupKey] :null) ,

//     setError:action((state,error) => {
//         if(!error){
//             //@ts-ignore
//             state.isError=false
//             //@ts-ignore
//             state.helperText = ''
//         }else{
//             //@ts-ignore
//             state.isError = true;
//             //@ts-ignore
//             state.helperText = error
//         }
//     }),

// })

/**
 handleChange: thunk((actions,newValue,{getState, getStoreState})=>{
        actions._handleChange(newValue);
        //if(sourceMapFn) && 
    }),
    _handleChange: action((state,newValue)=>{
        state.value = newValue;
    })
 */
