import { EzModel } from 'core/store/base';
import { Child, Model } from 'easy-peasy-classes';
import { exempleModel } from './exemple/example.model';


@Model
export class playgroundModel extends EzModel {

    @Child
    exemple: exempleModel = exempleModel.obj
}