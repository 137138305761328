/* eslint-disable simple-import-sort/imports */
import { Action, Model, Property, Thunk } from "easy-peasy-classes"
import { EzModel } from "core/store/base"
import type { ButtonProps } from "@mui/material"
import { store } from "core/store"

let outsideResolve: (value: unknown) => void

type msgBoxButtons = {
    [result: string]: string | itemConf
}

type itemConf = {
    label: string
    buttonProps: ButtonProps
}

type initPayload = Partial<Pick<msgBoxModel, "title" | "msg" | "buttons">>

@Model
export class msgBoxModel extends EzModel {
    @Property
    isOpen: boolean = false

    @Property
    title: string = "Boite de message"

    @Property
    msg: string = "Voici un message"

    @Property
    buttons: msgBoxButtons = {
        cancel: "Annuler",
        accept: "Accepter",
    }

    @Action
    endDialog(payload: any) {
        outsideResolve(payload)
        this.isOpen = false
    }

    @Thunk
    async init<T extends initPayload>(payload: T): Promise<T["buttons"] extends object ? Extract<keyof T["buttons"], string> : "cancel" | "accept"> {
        this.show(payload)

        return new Promise((resolve) => {
            outsideResolve = resolve
        })
    }

    @Action
    show(payload: initPayload) {
        Object.assign(this, payload)
        this.isOpen = true
    }

    @Thunk
    handleClick(result: string) {
        this.endDialog(result)
    }
}

export async function msgBox<T extends initPayload>(payload: T): Promise<T["buttons"] extends object ? Extract<keyof T["buttons"], string> : "cancel" | "accept"> {
    const promise = await store.getActions().core.dialogs.msgBox.init(payload)
    return promise as any
}
