import axios, { AxiosRequestConfig } from "axios"
import type { AuthCtx } from "core/technic/auth/auth-context"

import { authHeader } from "./userService"

export async function downloadFile(endpoint: string, auth: AuthCtx, mode: "preview" | "download" = "download", config: Partial<AxiosRequestConfig<any>> = {}) {
    await auth.ensureValidToken()
    const res = await axios.request({ method: "GET", url: `${process.env.REACT_APP_DOPPLE_API_URL}${endpoint}`, responseType: "arraybuffer", ...config, headers: { ...authHeader(), ...config?.headers } })
    const fileData = res.data

    const contentDisposition: string = res.headers["content-disposition"]
    const fileName = contentDisposition.replace("attachment; filename=", "")

    //Create fake download link and click it
    const url = window.URL.createObjectURL(new Blob([fileData], { type: "application/pdf" }))
    const link = document.createElement("a")
    link.href = url
    if (mode === "download") {
        link.setAttribute("download", fileName)
    } else {
        link.setAttribute("target", "_blank")
    }

    document.body.appendChild(link)
    link.click()

    URL.revokeObjectURL(url)
}
