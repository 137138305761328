import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file

import { Button, ButtonGroup,Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material/"
import { TimeRangeSlider$,WeekDayToggleGroup$ } from "core/controls"
import { useEzActions, useEzState, useMapper } from "core/store"

export function WeekTimeRange() {
    const µ = useMapper((s) => s.core.dialogs.weekTimeRange)

    const unitState = useEzState((s) => s.core.dialogs.weekTimeRange.dialog)
    const unitActions = useEzActions((s) => s.core.dialogs.weekTimeRange)

    return (
        <Dialog open={unitState.isOpen} maxWidth="xl">
            <DialogTitle>{unitState.title}</DialogTitle>

            <DialogContent>
                <WeekDayToggleGroup$ mapper={(s) => µ(s).weekDayToggleGroup$} />
                <TimeRangeSlider$ mapper={(s) => µ(s).timeRangeSlider$} />
            </DialogContent>

            <DialogActions>
                <Button onClick={() => unitActions.endDialog(["cancel"])} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => unitActions.validate()} color="primary" autoFocus>
                    Accepter
                </Button>
            </DialogActions>
        </Dialog>
    )
}
