/* eslint-disable simple-import-sort/imports */

import { EzModel } from "core/store/base"
import { Action, Child, Model, Property, Thunk } from "easy-peasy-classes"

//type tabDef = [string,string]
//type tabs = Array<tabDef>

@Model
export class appModel extends EzModel {
    // @Action
    // clearToken() {
    //     localStorage.removeItem("user-token")
    // }

    // @Thunk
    // async reLogin() {
    //     await this.getStoreActions().core.dialogs.loginPage.init()
    // }

    @Property
    isLoading: boolean = false

    @Action
    startSpinner() {
        this.isLoading = true
    }

    @Action
    stopSpinner() {
        this.isLoading = false
    }
}
