/* eslint-disable simple-import-sort/imports */
import { useEzActions, useEzState } from "core/store"

import { Dialog, DialogTitle, DialogActions, Button, DialogContent, DialogContentText, TextField } from "@mui/material/"
import type { inputBoxModel } from "./inputBox.model"

export function InputBox() {
    const unitState = useEzState((s) => s.core.dialogs.inputBox)
    const unitActions = useEzActions((s) => s.core.dialogs.inputBox)

    return (
        <Dialog open={unitState.dialog.isOpen}>
            <DialogTitle>{unitState.title}</DialogTitle>

            <DialogContent>
                <DialogContentText>{unitState.msg}</DialogContentText>
                {GeneratedFields({ fields: unitState.fields, unitActions: unitActions })}
            </DialogContent>

            <DialogActions>
                <Button onClick={() => unitActions.cancel()}>{unitState.button1Text}</Button>
                <Button onClick={() => unitActions.validate()} color="primary" autoFocus>
                    {unitState.button2Text}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
//color="primary"
function GeneratedFields(props: { fields: inputBoxModel["fields"]; unitActions: Partial<inputBoxModel> }) {
    const { fields, unitActions } = props
    return Object.keys(fields).map((fieldName, mapIndex) => {
        const field = fields[fieldName]

        switch (field.type) {
            case "text":
                return <TextField autoFocus margin="dense" value={fields[fieldName].value} onChange={(e) => unitActions.handleChange_Text({ controlId: e.target.id, value: e.target.value })} id={fieldName} label={field.label} type={field.options?.type} fullWidth key={mapIndex} />

            //case 'checkBox': return()
        }
    })
}
