import type { PhoneNumber } from "core/services/rasmik"
import { standardizePhoneNumber } from "core/utils"

import { formatPhoneNumber } from "../tools"

export class WPhoneNumber {
    constructor(PhoneNumber: PhoneNumber) {
        this.PhoneNumber = PhoneNumber
    }
    PhoneNumber: PhoneNumber

    static format = formatPhoneNumber
    format() {
        return WPhoneNumber.format(this.PhoneNumber.number)
    }

    static standardize = standardizePhoneNumber
    standardize() {
        return WPhoneNumber.standardize(this.PhoneNumber.number)
    }

    static verify(phoneNumber: string): [isOk: boolean, msg?: string] {
        const cleaned = phoneNumber.replace(/ /g, "")
        if (!cleaned) return [false, "Requis"]

        const regex = /^\s*\+?\s*([0-9][\s-]*){9,}$/
        if (!regex.test(cleaned)) return [false, "Incorrect"]

        const standardized = standardizePhoneNumber(phoneNumber)

        if (!standardized) return [false, "Requis"]
        if (!cleaned.includes("+") && cleaned.length !== 10) return [false, "Incorrect"] //num français pas a 10 digit
        if (standardized && standardized.length > 1) return [true]
        else return [false, "Incorrect"]
    }
}
