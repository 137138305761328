import { AddBox as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material"
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material"
import { DayDate } from "core/utils/tools"
import { WDateTime } from "core/utils/wrappers"
import { DateTime } from "luxon"

interface props {
    ranges: Array<{ startOn: string; endOn: string }>
    onEditClick: (row: { id?: number; startOn: string; endOn: string }) => void
    onDeleteClick: (row: { id?: number; startOn: string; endOn: string }) => void
    onAddClick: () => void
}
export const DateRangesList = (props: props) => {
    const { ranges = [], onAddClick, onDeleteClick, onEditClick } = props

    //TODO: retrouver quel est le formatde date reçu par DateRangesList

    return (
        <List>
            {" "}
            {ranges.map((row, index) => {
                //row.index = index; //this is mutating state

                return (
                    <ListItem key={index}>
                        <ListItemText>{`Du ${DayDate.fromISOString(row.startOn).toFormat("dd MMM yyyy")} au ${DayDate.fromISOString(row.endOn).toFormat("dd MMM yyyy")}`}</ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="comments" onClick={() => onEditClick(row)} size="large">
                                <EditIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="comments" onClick={() => onDeleteClick(row)} size="large">
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            })}
            <ListItem key={-1}>
                <ListItemText></ListItemText>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" size="large"></IconButton>
                    <IconButton edge="end" aria-label="comments" onClick={() => onAddClick()} size="large">
                        <AddIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    )
}
