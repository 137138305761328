import { rasmik } from "core/services/rasmik"
import { AppSetting, AppSettingKey } from "core/services/rasmik-client/entities"
import { useAuthContext } from "core/technic/auth"
import { useQuery, UseQueryOptions } from "react-query"

export function useAppSettings(options?: UseQueryOptions) {
    const auth = useAuthContext()
    const query = useQuery(
        ["app-settings"],
        async () => {
            await auth.ensureValidToken()
            return await rasmik
                .readMany(AppSetting)
                .where({ key: { $nin: ["keyyoManagerRefreshToken"] } })
                .run() //as Record<AppSettingKey,string>
        },
        options as any
    )

    return {
        ...query,
        get(key: AppSettingKey): string | null {
            return query?.data?.find((row) => row.key === key)?.value ?? null
        },
    }
}
