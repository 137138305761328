import { Slider } from "@mui/material"
import { createLocalHooks } from "core/store"
import { Duration } from "luxon"

import type { commonTimeRangeSliderModel } from "./timeRangeSlider.model"

export function TimeRangeSlider$(props: { mapper: (s: any) => any }) {
    const { mapper: µ, ...rest } = props
    const { useLocalState, useLocalActions } = createLocalHooks<commonTimeRangeSliderModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    return (
        <Slider
            sx={(theme) => ({ marginTop: theme.spacing(7) })}
            value={[unitState.startTime || unitState.minTime, unitState.endTime || unitState.maxTime]}
            onChange={(e, newValues: [number, number]) => unitActions.handleChange(newValues)}
            step={unitState.stepSize}
            marks={unitState.getMarks}
            min={unitState.minTime}
            max={unitState.maxTime}
            valueLabelDisplay="on"
            valueLabelFormat={(minutes) => Duration.fromObject({ minutes: minutes }).toFormat("h'h'mm")}
        />
    )
}
