// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormControl, FormHelperText } from "@mui/material"
import { DatePicker, DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { DayDate } from "core/utils/tools"
import type { DateTime } from "luxon"
import { useState } from "react"

import { RawTextField, RawTextFieldProps } from "./RawTextField.com"

export type RawDateFieldProps = Omit<DatePickerProps<DateTime>, "value" | "onChange" | "renderInput"> & {
    value: string
    onChange: (dayDateString: string | null) => void
    /** default to 'EEE d MMM yyyy' */
    displayFormat?: string
    clearable?: boolean
    clearText?: string
    cancelText?: string
} & Pick<RawTextFieldProps, "necessary" | "missing" | "size" | "fullWidth" | "helperText" | "placeholder" | "variant" | "error" | "powerAction" | "disabled">

export function RawDateField(props: RawDateFieldProps) {
    const { size, fullWidth, helperText, placeholder = "01/01/2001", variant, error, necessary, missing, powerAction, disabled, ...datePickerProps } = props
    const textFieldProps = { size, fullWidth, helperText, placeholder, variant, error, necessary, missing, powerAction, disabled }
    Object.keys(textFieldProps).forEach((key) => (textFieldProps[key] === undefined ? delete textFieldProps[key] : {}))

    class LocalizedUtils extends AdapterLuxon {
        locale = "fr"
        getDatePickerHeaderText(dt: DateTime) {
            return dt.setLocale("fr").toFormat(props.displayFormat || "EEE d MMM yyyy")
        }
    }

    const [internalHelperText, setInternalHelperText] = useState("")

    return (
        <FormControl error={!!internalHelperText || props.error}>
            <LocalizationProvider dateAdapter={LocalizedUtils}>
                <DatePicker
                    {...datePickerProps}
                    value={DayDate.fromISOString(props.value)?.toLuxonDateTime()}
                    // error={!!internalHelperText || props.error}
                    slotProps={{
                        field: {
                            clearable: datePickerProps.clearable ?? true,
                        },
                        textField: {
                            ...textFieldProps,
                            //@ts-ignore
                            error: internalHelperText || props.error,
                            helperText: null,
                            transformOutput: (v) => (v === null ? "" : v),
                        },
                    }}
                    slots={{
                        //@ts-ignore
                        textField: RawTextField, //(props) => {
                    }}
                    onChange={(dt: DateTime, inputText) => {
                        setInternalHelperText(dt?.invalidReason ? "Invalide" : "")
                        props.onChange(dt === null || !dt?.isValid ? null : DayDate.fromLuxonDateTime(dt).toISOString())
                    }}
                    format={datePickerProps.format ?? "dd/MM/yyyy"}
                    // clearText={datePickerProps.clearText ?? "r-à-zéro"}
                    // cancelText={datePickerProps.cancelText ?? "annuler"}
                />
            </LocalizationProvider>
            {(internalHelperText || props.helperText) && <FormHelperText>{internalHelperText || props.helperText}</FormHelperText>}
        </FormControl>
    )
}

//the helper text is set internally if input is not a valid date. If we don't provide an helper text though props, we let it uncontrolled
