import { isNil } from "lodash"
import { useCallback, useRef, useState } from "react"

export function useDialog<N extends string = "Dialog">(dialogName?: N): { [K in `open${N}`]: () => void } & { [K in `close${N}`]: () => void } & { [K in `is${N}Open`]: boolean } {
    dialogName = dialogName ?? ("Dialog" as any)
    const [isOpen, setOpened] = useState(false)
    const open = useCallback(() => {
        setOpened(true)
    }, [])
    const close = useCallback(() => {
        setOpened(false)
    }, [])
    return {
        [`is${dialogName}Open`]: isOpen,
        [`open${dialogName}`]: open,
        [`close${dialogName}`]: close,
    } as any
}

export interface UseDialogTarget {
    close: () => any
    isOpen?: boolean
}

export function useDialog2<Payload = void>() {
    const [isOpen, setOpened] = useState(false)
    const [payload, setPayload] = useState<Payload | null>(null)
    const open = useCallback((payload: Payload) => {
        setOpened(true)
        payload && setPayload(payload)
    }, [])
    const hide = useCallback(() => {
        setOpened(false)
        setPayload(null)
    }, [])
    return {
        isOpen,
        isClosed: !isOpen,
        open,
        hide,
        payload,
    }
}

export type DialogLogic2<Payload = void> = {
    isOpen: boolean
    isClosed: boolean
    open: (payload: Payload) => void
    hide: () => void
    payload: Payload | null
}

export function useDialogId<Payload = void>() {
    const [id, setId] = useState<number | null>(null)
    const [payload, setPayload] = useState<Payload | null>(null)
    const open = useCallback((id: number, payload?: Payload) => {
        setId(id)
        payload && setPayload(payload)
    }, [])
    const hide = useCallback(() => {
        setId(null)
        setPayload(null)
    }, [])
    return {
        isOpen: !isNil(id),
        id,
        open,
        hide,
        payload,
    }
}

export type DialogIdLogic<Payload = void> = {
    isOpen: boolean
    id: number
    open: (id: number, payload?: Payload) => void
    hide: () => void
    payload: Payload
}

export function useDialogIndex<N extends string = "Dialog">(dialogName?: N): { [K in `open${N}`]: (idx: number) => void } & { [K in `close${N}`]: () => void } & { [K in `is${N}Open`]: boolean } & { [K in `indexOf${N}`]: number | null } {
    dialogName = dialogName ?? ("Dialog" as any)
    const [index, setIndex] = useState<number | null>(null)
    const open = useCallback((idx: number) => {
        setIndex(idx)
    }, [])
    const close = useCallback(() => {
        setIndex(null)
    }, [])
    return {
        [`is${dialogName}Open`]: !isNil(index),
        [`indexOf${dialogName}`]: index,
        [`open${dialogName}`]: open,
        [`close${dialogName}`]: close,
    } as any
}

export function useDialogIndexCU<N extends string = "Dialog">(
    dialogName?: N
): { [K in `openEdit${N}`]: (idx: number) => void } & { [K in `openCreate${N}`]: () => void } & { [K in `close${N}`]: () => void } & { [K in `is${N}Open`]: boolean } & { [K in `indexOf${N}`]: number | null } & { [K in `modeOf${N}`]: "create" | "edit" } {
    dialogName = dialogName ?? ("Dialog" as any)
    const [index, setIndex] = useState<number | null>(null)
    const openEdit = useCallback((idx: number) => {
        setIndex(idx)
    }, [])
    const openCreate = useCallback(() => {
        setIndex(-1)
    }, [])
    const close = useCallback(() => {
        setIndex(null)
    }, [])
    return {
        [`is${dialogName}Open`]: !isNil(index),
        [`indexOf${dialogName}`]: index,
        [`openEdit${dialogName}`]: openEdit,
        [`openCreate${dialogName}`]: openCreate,
        [`close${dialogName}`]: close,
        [`modeOf${dialogName}`]: index === -1 ? "create" : "edit",
    } as any
}

export function useDialogAwait<Payload, Outcome = unknown>() {
    type Ref = { isOpen: boolean; promise: Promise<Outcome> | null; resolve: ((...args: any[]) => void) | null }
    const ref = useRef<Ref>({ isOpen: false, promise: null, resolve: null })
    const [isOpen, setOpened] = useState(false)
    const [payload, setPayload] = useState<Payload | null>(null)
    const open = useCallback((payload: Payload) => {
        if (ref.current.isOpen) {
            //if already open, return current promise.
            setPayload(payload)
            return ref.current.promise!
        } else {
            //open and create a promise that will resolve when dialog closes
            ref.current.isOpen = true
            setOpened(true)
            setPayload(payload)
            const promise = new Promise<Outcome>((_resolve, _reject) => {
                ref.current.resolve = _resolve
            })
            ref.current.promise = promise
            return promise!
        }
    }, [])
    const close = useCallback((outcome: Outcome) => {
        ref.current.isOpen = false
        ref.current.resolve!(outcome)
        setOpened(false)
    }, [])

    return {
        isOpen,
        open,
        close,
        payload,
    }
}

export type DialogAwaitLogic<Payload, Outcome = unknown> = {
    isOpen: boolean
    open: (payload: Payload) => Promise<Outcome>
    close: (outcome: Outcome) => void
    payload: Payload
}
