import { Tab as TabUnstyled } from "@mui/base"
import { styled } from "@mui/material"
import { Link } from "react-router-dom"

export const SubTab = styled(Link)({
    wordWrap: "normal",
    border: "1px solid transparent",
    borderBottomWidth: "0px",
    borderTopLeftRadius: "0.375rem",
    borderTopRightRadius: "0.375rem",
    padding: "0.5rem 1rem",
    position: "relative",
    ":hover": {
        borderColor: "#e9ecef #e9ecef transparent",
    },
    boxSizing: "border-box",
    "&.Mui-selected": {
        color: "#495057",
        backgroundColor: "inherit",
        borderColor: "#c6c9cc #c6c9cc",
        "::after": {
            borderBottom: "2px solid #fcfcfc",
        },
    },
    "&.Mui-disabled": {},
    "::after": {
        boxSizing: "border-box",
        position: "absolute",
        bottom: "-1px",
        left: "0px",
        right: "0px",
        content: '""',
    },
    textDecoration: "none",
    color: "inherit",
    whiteSpace: "nowrap",
})
