import { DateTime, Duration } from "luxon"

import * as constants from "../constants"

export function timeStringToMinutes(isoTimeString: string) {
    return Duration.fromISOTime(isoTimeString).as("minutes")
}

export const dateToExerciseId = (date: Date) => {
    const dt = DateTime.fromJSDate(date)

    const exercises = constants.exercises

    //ex.start and ex.end are in sql date format
    const exerciseId = exercises.find((ex) => dt.toMillis() >= DateTime.fromSQL(ex.start).toMillis() && dt.toMillis() <= DateTime.fromSQL(ex.end).toMillis())?.id
    return exerciseId
}

export function percentageToColor(percentage, maxHue = 120, minHue = 0) {
    const hue = percentage * (maxHue - minHue) + minHue
    return `hsl(${hue}, 100%, 50%)`
}

export function isString(val): val is string {
    return typeof val === "string" || val instanceof String
}

export function booleanToString(val) {
    if (val === true) return "true"
    if (val === false) return "false"
    if (val === null) return "null"
}

export function stringToBoolean(val) {
    if (val === "true") return true
    if (val === "false") return false
    if (val === "null") return null
}
