import type { ReactNode } from "react"

export function s(nb: number) {
    return nb === 1 ? "" : "s"
}

export function plural(singularForm: string, pluralForm: string, nb: number) {
    return nb === 1 ? singularForm : pluralForm
}

export function genderal(masculineForm: string, feminineForm: string, gender: "M" | "F" | null | undefined) {
    return txtmap(gender, { M: masculineForm, F: feminineForm })
}

export function conditional(str: string | ReactNode, condition: boolean) {
    return condition ? str : ""
}

export function de_(word: string) {
    return ["a", "e", "i", "o", "u", "h"].some((letter) => letter.localeCompare(word[0], "fr", { sensitivity: "base" }) === 0) ? `d'${word}` : `de ${word}`
}

export function txtmap<T extends string>(key: T | null | undefined, map: Record<T, string>) {
    if (key === null || key === undefined) return ""
    return map[key] ?? ""
}
