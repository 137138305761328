import { commonDialogModel,commonTimeRangeSliderModel, commonWeekDayToggleGroupModel } from "core/controls"
import { timeStringToMinutes } from "core/utils/tools"
import { Action, Child, Model, Property, Thunk } from "easy-peasy-classes"
import { Duration } from "luxon"

type range = { startTime: string; endTime: string; dayId: number }

@Model
export class weekTimeRangeModel extends commonDialogModel<range> {
    @Property
    dialog = {
        title: "Plage horaire",
        button1Text: "Annuler",
        button2Text: "Valider",
        isOpen: false,
    }

    @Child
    weekDayToggleGroup$: commonWeekDayToggleGroupModel = commonWeekDayToggleGroupModel.obj

    @Child
    timeRangeSlider$: commonTimeRangeSliderModel = commonTimeRangeSliderModel.obj

    @Thunk
    //peut échouer dans quel cas on affiche pas
    async init({ title, range }: { title: string; range?: range }) {
        this.resetState()
        range && this.setRange(range)
        this.show({ title })

        return this.promise()
    }

    @Thunk
    async validate() {
        const dialogReturn = {
            startTime: Duration.fromObject({ minutes: this.timeRangeSlider$.startTime }).toFormat("hh:mm:ss"),
            endTime: Duration.fromObject({ minutes: this.timeRangeSlider$.endTime }).toFormat("hh:mm:ss"),
            dayId: this.weekDayToggleGroup$.value,
        }

        this.endDialog(["success", dialogReturn])
    }

    @Action
    setRange(range: { startTime: string; endTime: string; dayId: number }) {
        this.timeRangeSlider$.startTime = timeStringToMinutes(range.startTime)
        this.timeRangeSlider$.endTime = timeStringToMinutes(range.endTime)
        this.weekDayToggleGroup$.value = range.dayId
    }
}
