import { EzModel } from "core/store/base"
import { Action, Model, Property } from "easy-peasy-classes"

@Model
export class commonWeekDayToggleGroupModel extends EzModel {
    @Property
    value: number = 1

    @Action
    handleChange(newValue: number) {
        if (newValue !== null) {
            this.value = newValue
        }
    }
}
