import { Tooltip, TooltipProps } from "@mui/material"
import { isNil } from "lodash"
import type { HTMLProps } from "react"

export interface ConditionnalTooltipProps extends TooltipProps {
    enabled?: boolean
    containerProps?: HTMLProps<HTMLDivElement>
}
export function ConditionnalTooltip(props: ConditionnalTooltipProps) {
    let { enabled, containerProps, ...other } = props

    enabled = enabled ?? Boolean(other.title)

    if (enabled)
        return (
            <Tooltip {...other}>
                <div {...containerProps} style={{ width: "fit-content", height: "fit-content", ...containerProps?.style }} /*style={{ width: "100%", height: "100%", ...containerProps?.style }}*/>
                    {props.children}
                </div>
            </Tooltip>
        )
    else
        return (
            <div {...containerProps} style={{ width: "fit-content", height: "fit-content", ...containerProps?.style }} /*style={{ width: "100%", height: "100%", ...containerProps?.style }}*/>
                {props.children}
            </div>
        )
}
