import { styled } from "@mui/material"
import Box from "@mui/material/Box"
import CircularProgress, { circularProgressClasses, CircularProgressProps } from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import { createLocalHooks } from "core/store"
import { humanizeDuration } from "core/utils/tools"

import type { commonStopWatchModel } from "./stopWatch.model"

//#f44336

interface StopWatch$Props {
    mapper: (s: any) => any
    warning1Min: number
    warning2Min: number
}

export function StopWatch$(props: StopWatch$Props) {
    const { mapper: µ, ...rest } = props
    const { useLocalState, useLocalActions } = createLocalHooks<commonStopWatchModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)

    if (!unitState) return null
    //const [progress, setProgress] = React.useState(10);

    /*
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  */
    return <CircularProgressWithLabel value={unitState.value} size={220} thickness={1.5} {...rest} />
}

interface CircularProgressWithLabelProps extends CircularProgressProps {
    /** milliseconds */
    value: number
    warning1Min: number
    warning2Min: number
}

const ColorizedCircularProgress = styled(CircularProgress, { shouldForwardProp: (prop) => prop !== "circleColor" })<{ circleColor: string }>(({ theme, color }) => ({
    [`& .${circularProgressClasses.circle}`]: {
        color: color === "inherit" ? "inherit" : theme.palette[color]?.main,
    },
}))

function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
    const { value, warning1Min, warning2Min, ...rest } = props
    //const classes = useStyles({ color: (warning1Min && value / 1000 / 60 < warning1Min ? 'primary' : (warning2Min && value / 1000 / 60 < warning2Min ? 'warning' : 'failure')) });

    const circleColor = warning1Min && value / 1000 / 60 < warning1Min ? "primary" : warning2Min && value / 1000 / 60 < warning2Min ? "warning" : "failure"

    return (
        <Box position="relative" display="inline-flex">
            <ColorizedCircularProgress circleColor={circleColor} variant="determinate" value={((value / 1000 / 60 / 60) * 100) % 100} {...rest} />
            <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography component="div" color="textSecondary" variant="h5">
                    {humanizeDuration(props.value, 1)}
                </Typography>
            </Box>
        </Box>
    )
}
