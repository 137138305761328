import React from "react"
export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children)

export function jsxJoin(array = [], str) {
    return array.length > 0
        ? array.reduce((result, item) => (
              <>
                  {result}
                  {str}
                  {item}
              </>
          ))
        : null
}
