import AddBoxIcon from "@mui/icons-material/AddBoxSharp"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grow, TextField } from "@mui/material"
import type { TransitionProps } from "@mui/material/transitions"
import React, { useState } from "react"

import type { AddItemProps } from "./props"

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
    return <Grow ref={ref} {...props} children={props.children} />
})

export default function AddItem({ multiple, value, onChange, AddButtonProps, AddDialogProps, disabled = false }: AddItemProps) {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)

    const [toAdd, setToAdd] = useState("")
    const handleAdd = () => {
        if (multiple) onChange({} as any, [...((Array.isArray(value) ? value : []) as any), { value: toAdd, label: toAdd }] as any, "createOption")
        else onChange({} as any, { value: toAdd, label: toAdd } as any, "createOption")

        setToAdd("")
        setTimeout(() => setOpen(false))
    }

    return (
        <>
            <Button
                startIcon={multiple ? <AddBoxIcon /> : <AddCircleIcon />}
                // color="default"
                sx={{
                    "& .MuiButton-root": { margin: (theme) => theme.spacing(0.75) },

                    "& .MuiButton-startIcon": {
                        marginLeft: -1,
                        marginRight: (theme) => theme.spacing(2),
                    },
                    "& .MuiButton-iconSizeMedium": {
                        "& > *:first-of-type": { fontSize: 24 },
                    },
                }}
                onClick={() => setOpen(true)}
                disabled={disabled}
                {...AddButtonProps}
            >
                {"\u200b"}
                {AddButtonProps?.children || "Add New"}
            </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition} fullWidth maxWidth="xs">
                <DialogTitle id="form-dialog-title">{AddDialogProps?.title || "Add Item to List"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        label={AddDialogProps?.textFieldLabel || "New Item"}
                        variant="filled"
                        fullWidth
                        value={toAdd}
                        onChange={(e) => setToAdd(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") handleAdd()
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {AddDialogProps?.cancelButtonLabel || "Cancel"}
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        {AddDialogProps?.addButtonLabel || "Add"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
