import type { LiteralUnion } from "core/utils/types"
import { DateTime } from "luxon"

type predefinedDateFormat = "dd LLL yyyy" | "dd/LL/yyyy" | "yyyy-LL-dd" | "yyyy-LL-dd HH:mm:ss" | "dd LLL yyyy 'à' HH'h'mm"

export class WDate {
    constructor(date: Date = new Date()) {
        this.date = date
    }
    date: Date

    format(format: LiteralUnion<predefinedDateFormat>) {
        return WDate.format(this.date, format)
    }
    static format = function (date: Date, format: LiteralUnion<predefinedDateFormat>) {
        return DateTime.fromJSDate(date).toFormat(format)
    }
}
