import { AUTH_STORAGE_KEY } from "core/technic/auth/auth-constants"
import { useAuthContext } from "core/technic/auth/auth-context-init"

// export async function login(email, password) {

//     try {

//         const user = await openapi.users.login({ email, password })

//         if (user) {
//         console.log('user recieved : ' + JSON.stringify(user))
//         // store user details and basic auth credentials in local storage
//         // to keep user logged in between page refreshes

//         window.localStorage.setItem('user-token', JSON.stringify(user));
//         }

//         store.getState().dialogs.loginPage.isOpen && store.getActions().core.dialogs.loginPage.endDialog()

//         return user;

//     }catch (err) {

//         if (err.name && ['INVALID_PASSWORD', 'UNKNOWN_USER'].includes(err.name)){

//             const msg = {
//                  'INVALID_PASSWORD':'Mot de passe incorrect',
//                  'UNKNOWN_USER':'Email inconnu',
//             }[err.name]

//             notistack.legacy({override: err.snackbarId,msg,delay:2500,severity:'warning'})
//         } else {
//             console.error(err)
//         }
//     }
// }

//async (username,password) =>
// export async function login(email: string, password: string) {
//     const token = await openapi.agents.signin({ email, password })

//     window.localStorage.setItem("user-token", token)
//     store.getActions().core.dialogs.loginPage.close()
// }

// export function logout() {
//     // remove user from local storage to log user out
//     localStorage.removeItem("user-token")
// }

// interface user {
//     id: number
//     firstName?: string
//     lastName?: string
//     type: "admin" | "manager" | "salesperson"
// }
// //always return a user
// export async function getCurrentUser(): Promise<user> {
//     await ensureValidToken()
//     const decodedToken = jwtDecode(localStorage.getItem("user-token")) as any
//     return decodedToken.user
// }
// export function getCurrentUserSync(): user {
//     ensureValidToken()
//     const decodedToken = jwtDecode(localStorage.getItem("user-token")) as any
//     return decodedToken.user
// }

export function useCurrentUser() {
    const auth = useAuthContext()

    return {
        async getCurrentUserSafe() {
            await auth.ensureValidToken()
            return auth.tokenPayload.user
        },
        async getCurrentUserSync() {
            return auth.tokenPayload?.user
        },
    }
}

export function getUserToken() {
    const json = localStorage.getItem(AUTH_STORAGE_KEY)
    if (!json) return
    return JSON.parse(json).token
}

export function authHeader(): { Authorization?: string } {
    // return authorization header with basic auth credentials
    const token = getUserToken()
    if (token) {
        return { Authorization: "Bearer " + token }
    } else {
        return {}
    }
}

/*
function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}
*/

/*
async function handleResponse(response) {

    console.log('ok ? : ' && response.ok);
    return

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
*/
