import React, { useCallback, useEffect, useMemo,useState } from "react"
import { usePrevious } from "react-use"

export function useSpinner(initialValue = false) {
    const [state, setState] = useState(initialValue)

    const previousInitial = usePrevious(initialValue)
    useEffect(() => {
        if (previousInitial !== initialValue) setState(initialValue)
    }, [previousInitial, initialValue])

    const start = useCallback(() => {
        setState(true)
    }, [])

    const stop = useCallback((delay: number = 0) => {
        setTimeout(() => setState(false), delay)
    }, [])

    const toggle = useCallback(() => {
        setState((st) => !st)
    }, [])

    return useMemo(() => ({ loading: state, start, stop, toggle, set: setState }), [state])
}
