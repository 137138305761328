import type { ValueOf } from "core/utils"

type Ctor<T> = { prototype: T }

export class FmkUtils {
    static DefField(target: any, propName: string) {
        target.fields = target.fields ?? {}
        target.fields[propName] = undefined
    }

    static getFields<T>(fielsClass: Ctor<T>): { [K in keyof T]: K }
    static getFields<T>(fieldsArray: T): {
        [K in ValueOf<T> as K extends string ? K : never]: K
    }
    static getFields(xxx: any) {
        const obj = {} as any

        if (Array.isArray(xxx)) {
            xxx.forEach((key) => {
                obj[key] = key
            })
        } else if (xxx.prototype?.fields) {
            Object.keys(xxx.prototype?.fields).forEach((key) => {
                obj[key] = key
            })
        } else {
            throw new Error("defineFormikFields expext a class constructor or an array")
        }

        return obj
    }

    // static useValidationSchema<T, SF extends (yup: typeof Yup) => { [K in keyof T]: Yup.AnySchema }>(fielsClass: Ctor<T>, schemaFactory: SF, deps: DependencyList): Yup.ObjectSchema<ReturnType<SF>>{
    //   return useMemo(()=>{
    //     return Yup.object().shape(schemaFactory(Yup))
    //   },deps) as any
    // }

    //   export function useValidationSchema<SF extends (yup:typeof Yup)=>Yup.AnySchema>(schemaFactory:SF,deps:DependencyList):ReturnType<SF>{
    //     return useMemo(()=>schemaFactory(Yup),deps) as any
    // }
}
