import { styled } from "@mui/material"
import type { CSSProperties, HTMLProps, ReactNode } from "react"

export const CardTable = styled("table")({
    borderCollapse: "collapse",
})

const TR = styled("tr")({})

type CardTableRowProps = HTMLProps<HTMLTableRowElement> & {
    cardShadow?: CSSProperties["boxShadow"]
    paddingY?: CSSProperties["padding"]
    borderRadius?: CSSProperties["borderRadius"]
    cardPaddingX?: CSSProperties["padding"]
    cardPaddingY?: CSSProperties["padding"]
    /** No padding and rounded corners on bottom */
    joinBottom?: boolean
    /** No padding and rounded corners on top */
    joinTop?: boolean
}

/**
 * Pour un tableau avec des cards a la place des lignes.
 *
 * Fontionnement : Une div (container) et une 2e div (card part) est placée dans chaque td. Seules celles des extremités ont des coins arrondis.
 * Pour avoir une ombre homogène, on  positionne une div asolute par rapport a la tr
 *
 * La card est en display: flex donc on se sert de justifyContent et alignItem pour gérer le positionnement dans les cellules
 */

export function CardTableRow(props: CardTableRowProps) {
    const { borderRadius = "8px", paddingY = "12px", cardShadow = "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px", cardPaddingX = "15px", cardPaddingY = "10px", joinBottom, joinTop, ...rest } = props

    return (
        <TR
            // {...rest}
            sx={{
                // borderCollapse: "collapse",
                position: "relative",
                zIndex: 0,
                "& > #shadow-overlay": {
                    position: "absolute",
                    zIndex: -1,
                    left: 0,
                    right: 0,
                    top: joinTop ? 0 : paddingY,
                    bottom: joinBottom ? 0 : paddingY,
                    borderRadius: borderRadius,
                    boxShadow: cardShadow,
                },
                "& > td": {
                    height: "0px",
                    padding: 0,
                },
                "& > td > div": {
                    // paddingY: paddingY,
                    paddingTop: joinTop ? 0 : paddingY, //padding between the row and the card
                    paddingBottom: joinBottom ? 0 : paddingY,
                    height: "100%",
                },
                "& > td > div > div": {
                    backgroundColor: "white",
                    paddingTop: joinTop ? 0 : cardPaddingY,
                    paddingBottom: joinBottom ? 0 : cardPaddingY,
                    paddingX: cardPaddingX, //padding inside the card
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                },
                "& > td:first-of-type > div > div": {
                    borderTopLeftRadius: joinTop ? 0 : borderRadius,
                    borderBottomLeftRadius: joinBottom ? 0 : borderRadius,
                },
                "& > td:last-of-type > div > div": {
                    borderTopRightRadius: joinTop ? 0 : borderRadius,
                    borderBottomRightRadius: joinBottom ? 0 : borderRadius,
                },
            }}
        >
            {props.children}
            <div id="shadow-overlay" />
        </TR>
    )
}

export function CardTableCell(props: { children: ReactNode | ReactNode[]; alignContent?: CSSProperties["alignContent"]; justifyContent?: CSSProperties["justifyContent"]; _cell?: HTMLProps<HTMLDivElement>; _td?: HTMLProps<HTMLTableCellElement> }) {
    return (
        <td {...props._td}>
            <div>
                <div {...props._cell} style={{ ...props._cell?.style, alignContent: props.alignContent, justifyContent: props.justifyContent }}>
                    {props.children}
                </div>
            </div>
        </td>
    )
}
