import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore"
import { Button, ButtonProps } from "@mui/material"
import { Grid } from "@mui/material"
import { useField, useFormikContext } from "formik"

export interface FmkResetButtonProps extends Omit<ButtonProps, "onClick"> {
    grid?: boolean
    xs?: number | true
}

export function FmkResetButton(props: FmkResetButtonProps) {
    const { grid, xs, ...otherProps } = props
    const { handleReset, dirty, isInitialValid, isValid } = useFormikContext()

    const defaultProps: ButtonProps = {
        color: "primary",
        variant: "contained",
        startIcon: <SettingsBackupRestoreIcon />,
        children: "Reset",
    }

    const config = {
        onClick: handleReset,
        ...defaultProps,
        ...otherProps,
    }
    // alert(config.disabled)
    config.disabled = config.disabled ?? !dirty

    if (grid)
        return (
            <Grid xs={xs} item>
                <Button {...config} />{" "}
            </Grid>
        )
    else return <Button {...config} />
}
