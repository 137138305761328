import React, { useContext } from "react"

import type { AuthCtx } from "./auth-context"

/* IMPORTANT : Vite HMR breaks context when not defined in a dedicated file */
export const authContext = React.createContext<AuthCtx>({} as any)

export function useAuthContext() {
    return useContext(authContext)
}
