import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn"
import SaveIcon from "@mui/icons-material/Save"
import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { Grid } from "@mui/material"
import { useField, useFormikContext } from "formik"

export interface FmkSubmitButtonProps extends Omit<LoadingButtonProps, "onClick"> {
    grid?: boolean
    xs?: number | true
    text?: string
}

export function FmkSubmitButton(props: FmkSubmitButtonProps) {
    const { grid, xs, text = "Enregistrer", ...otherProps } = props
    const { submitForm, isSubmitting, dirty, isInitialValid, isValid } = useFormikContext()

    const handleSubmit = () => {
        submitForm()
    }

    const defaultProps: LoadingButtonProps = {
        color: "primary",
        variant: "contained",
        loadingPosition: "start",
        startIcon: <SaveIcon />,
        children: isValid ? <span>{text}</span> : <span style={{ color: "rosybrown" }}>{text}</span>,
    }

    const config = {
        onClick: handleSubmit,
        ...defaultProps,
        ...otherProps,
    }

    config.loading = config.loading ?? isSubmitting
    config.disabled = config.disabled ?? (!dirty || !isValid)
    config.startIcon = isValid ? config.startIcon : <DoNotDisturbOnIcon sx={{ color: "rosybrown" }} />

    if (grid)
        return (
            <Grid xs={xs} item>
                <LoadingButton {...config} />{" "}
            </Grid>
        )
    else return <LoadingButton {...config} />
}
