import { styled } from "@mui/material"
import { Modal } from "@mui/material/"

const Overlay = styled("div")(({ theme }) => ({
    position: "fixed",
    //width: 400,
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    overflowY: "scroll",
}))

export function FullPageDialogBase({ open, children }: { open: boolean; children: any }) {
    //const {open,children,onCLose} = props

    return (
        <div>
            <Modal
                open={open}
                //onClose={()=>unitActions.endDialog(['cancel'])}
            >
                <Overlay>{children}</Overlay>
            </Modal>
        </div>
    )
}
