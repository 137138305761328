import { EzModel } from "core/store/base"
import { Action, Model, Property } from "easy-peasy-classes"

@Model
export class commonSwitchModel extends EzModel {
    @Property
    value: boolean = false

    @Property
    isDisabled: boolean = false

    @Property
    isError: boolean = false

    @Property
    label: string = ""

    @Property
    verifyFn: (value: boolean) => boolean = (value) => true

    @Property
    helperText: string = ""

    @Action
    handleChange(newValue: boolean) {
        this.value = newValue
    }

    @Action
    setValue(newValue: boolean) {
        this.value = newValue
    }

    @Action
    setError(error: string) {
        if (!error) {
            this.isError = false
            this.helperText = ""
        } else {
            this.isError = true
            this.helperText = error
        }
    }
}
