export class DialogManager {
    private map = new Map<symbol, ManagedDialog>()

    create() {
        const dialog = new ManagedDialog()
        this.map.set(dialog.symbol, dialog)
        return dialog
    }

    finish(sym: symbol, payload: any) {
        const dialog = this.map.get(sym)
        dialog.finish(payload)
    }
}

export class ManagedDialog<T = any> {
    symbol = Symbol()
    private resolve: any

    wait(): Promise<T> {
        const promise = new Promise((resolve) => {
            this.resolve = resolve
        })
        return promise as Promise<T>
    }

    finish(payload: any) {
        this.resolve(payload)
    }
}

export const dialogManager = new DialogManager()
