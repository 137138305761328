import { alertTitleClasses, Grid, TextField, TextFieldProps } from "@mui/material"
import { styled } from "@mui/material"
import { withGrid } from "core/misc"
import { RawTextField, RawTextFieldProps } from "core/rawFields"
import { useField, useFormikContext } from "formik"
import { NumericDictionary, size } from "lodash"
// import MaskedInput from 'react-text-mask'
import InputMask from "react-input-mask"

interface FmkTextNumberFieldProps<T extends string | number> extends Omit<RawTextFieldProps<T>, "value" | "error" | "onChange" | "onBlur"> {
    name: string
    necessary?: boolean
    validateImmediately?: boolean
    mask?: string
    maskOutputMode?: "full" | "no-spaces" | "unmasked"
    maskChar?: string
}

//sx={{...(necessary && {borderColor:'yellow'})}
const FmkTextNumberField = (props: FmkTextNumberFieldProps<string | number>) => {
    const { name, mask, maskChar, maskOutputMode, validateImmediately, ...otherProps } = props
    const [field, meta, { setValue, setTouched }] = useField<string | number | null>(name)
    const { isSubmitting } = useFormikContext()

    const defaultProps: RawTextFieldProps<string | number> = {
        fullWidth: true,
        variant: "outlined",
        size: "small",
    }

    const config = {
        ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
        ...defaultProps,
        ...otherProps,
    }

    if (meta && (meta.touched || validateImmediately) && meta.error) {
        config.error = true
        config.helperText = meta.error
    }

    config.disabled = config.disabled ?? isSubmitting

    if (mask) {
        const { onChange, ...propsWithoutOnChange } = config
        return (
            <InputMask
                {...(propsWithoutOnChange as any)}
                onChange={(e) => {
                    const unmaskedValue = getMaskOutput(e.target.value, mask, maskOutputMode)
                    // const mockedEvent = { target: { value: unmaskedValue } }
                    e.stopPropagation?.()
                    setValue(unmaskedValue, true)
                    // config.onChange(e)
                }}
                mask={mask}
                maskChar={maskChar || " "}
            >
                {(inputProps: any) => {
                    console.log(field.value)
                    const cleanedValue = getMaskOutput(field.value as string, mask, "unmasked")?.replaceAll(maskChar || " ", "")
                    const isMissing = cleanedValue === undefined || cleanedValue === null || cleanedValue === "" || cleanedValue === undefined
                    return <RawTextField {...inputProps} missing={isMissing} />
                }}
            </InputMask>
        )
    } else {
        //solve bug grid -> boolean is not assignable to false. Must pass grid separatly as any
        const { grid, ...propsWithoutGrid } = config

        return (
            <RawTextField
                {...propsWithoutGrid}
                grid={grid as any}
                onChange={(e) => {
                    setTouched(true)
                    setValue(e.target.value, true)
                }}
            />
        )
    }
}

export interface FmkTextFieldProps extends FmkTextNumberFieldProps<string> {}
export interface FmkNumberFieldProps extends FmkTextNumberFieldProps<number> {
    type: "number"
}
export const FmkTextField = FmkTextNumberField as React.ComponentType<FmkTextFieldProps>
export const FmkNumberField = FmkTextNumberField as React.ComponentType<FmkNumberFieldProps>

function getMaskOutput(maskedValue: string, mask: string, maskOutputMode: "full" | "no-spaces" | "unmasked" = "unmasked") {
    if (!maskedValue) return maskedValue

    const tokens = []
    const result = []
    let escaped = false
    for (const char of mask) {
        if (!escaped && char === "\\") {
            escaped = true
            continue
        }

        tokens.push(escaped ? "\\" + char : char)
        escaped = false
    }

    let index = 0
    for (const token of tokens) {
        if (["9", "a", "*"].includes(token)) {
            result.push(maskedValue[index])
        } else if ((maskOutputMode === "full" || maskOutputMode === "no-spaces") && token.startsWith("\\")) {
            result.push(token[1])
        } else if (maskOutputMode === "full" && token === " ") {
            result.push(" ")
        }
        index++
    }

    return result.join("").trim()
}
