const isoDateFormat = /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/
export const isIsoDateString = (val: any) => isoDateFormat.test(val)

export function parseWithDate(jsonString: string): any {
    var resultObject = JSON.parse(jsonString, (key: any, value: any) => {
        if (typeof value == "string" && isoDateFormat.exec(value)) {
            return new Date(value)
        }
        return value
    })
    return resultObject
}
