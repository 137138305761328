import { ProviderContext, SnackbarKey, useSnackbar } from "notistack"
import type React from "react"

interface OpenLegacySnackbar {
    (options: LegacySnackbarOptions): SnackbarKey
}
const notistack = {} as ProviderContext & { legacy: OpenLegacySnackbar }
export const SnackbarUtilsConfigurator: React.FC = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    notistack.enqueueSnackbar = enqueueSnackbar
    notistack.closeSnackbar = closeSnackbar

    notistack.legacy = (options) => {
        return notistack.enqueueSnackbar(options.msg ?? options.msgArr?.join("\n"), { variant: options.severity, autoHideDuration: options.delay, persist: !options.delay, style: { whiteSpace: "pre-line" } })
    }
    return null
}
export { notistack }

export type LegacySnackbarSeverity = "error" | "warning" | "info" | "success"
export interface LegacySnackbarOptions {
    msg?: string
    msgArr?: string[]
    severity?: LegacySnackbarSeverity
    delay?: number
}
