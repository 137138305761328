import { alertTitleClasses, Grid, TextField, TextFieldProps } from "@mui/material"
import { withGrid } from "core/misc"
import { RawDateField, RawDateFieldProps } from "core/rawFields/RawDateField.com"
import { FastField, FastFieldProps } from "formik"

export interface FmkDateFastFieldProps extends Omit<RawDateFieldProps, "value" | "onChange" | "error"> {
    name: string
    validateImmediately?: boolean
}

export const FmkDateFastField = withGrid((props: FmkDateFastFieldProps) => {
    const { name, validateImmediately, ...otherProps } = props

    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps<any>) => {
                const defaultProps: Partial<RawDateFieldProps> = {
                    fullWidth: true,
                    variant: "outlined",
                    size: "small",
                }

                const config = {
                    ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
                    ...defaultProps,
                    ...otherProps,
                }

                if (meta && (meta.touched || validateImmediately) && meta.error) {
                    config.error = true
                    config.helperText = meta.error
                }

                config.disabled = config.disabled ?? form.isSubmitting

                return <RawDateField {...config} onChange={(dateString) => form.setFieldValue(name, dateString)} />
            }}
        </FastField>
    )
})
