// import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import { FormControl, FormHelperText } from "@mui/material"
import { TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { createLocalHooks } from "core/store"
import { DayDate } from "core/utils/tools"
import type { DateTime } from "luxon"
import React, { useState } from "react"

import type { commonDateFieldModel } from "./dateField.model"

export interface DateField$Props {
    id?: string
    mapper: (s: any) => any
    displayFormat?: string
    InputAdornmentProps?: any
    variant?: "standard" | "outlined" | "filled"
    label?: string
    fullWidth?: boolean
    disabled?: boolean
    helperText?: string
}
export function DateField$(props: DateField$Props) {
    const { mapper: µ } = props
    const { useLocalState, useLocalActions } = createLocalHooks<commonDateFieldModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    //the helper text is set internally if input is not a valid date. If we don't provide an helper text though props, we let it uncontrolled

    class LocalizedUtils extends AdapterLuxon {
        locale = "fr"
        getDatePickerHeaderText(dt: DateTime) {
            return dt.setLocale("fr").toFormat(props.displayFormat || "EEE d MMM yyyy")
        }
    }

    const [internalHelperText, setInternalHelperText] = useState("")

    if (!unitState) return null

    return (
        <FormControl error={!!internalHelperText || unitState.isError}>
            <LocalizationProvider dateAdapter={LocalizedUtils}>
                <DatePicker
                    value={DayDate.fromISOString(unitState.value)?.toLuxonDateTime()}
                    label={props.label || unitState.label}
                    //helperText={unitState.helperText}
                    //error = {unitState.isError}
                    //{...(unitState.isError ? { error: true } : {})}
                    {...(unitState.helperText !== undefined && unitState.helperText !== null ? { helperText: unitState.helperText } : {})}
                    disabled={unitState.isDisabled || props.disabled}
                    // clearable
                    //  placeholder="01/01/1999"

                    onChange={(dt) => {
                        setInternalHelperText(dt?.invalidReason ? "Invalide" : "") //TODO:  and fix
                        unitActions.handleChange(dt === null || !dt?.isValid ? null : DayDate.fromLuxonDateTime(dt).toISOString())
                    }}
                    slotProps={{
                        textField: { error: !!internalHelperText || unitState.isError, placeholder: "01/01/2001", variant: props.variant },
                        inputAdornment: props.InputAdornmentProps,
                    }}
                    //override so internal-picker-lib error never shows up
                    // error={}

                    format="dd/MM/yyyy"
                    // clearText="r-à-zéro"
                    // cancelText="annuler"
                    // vali="Invalide"

                    // inputVariant={props.variant}
                />
            </LocalizationProvider>
            {(props.helperText || internalHelperText || unitState.helperText) && <FormHelperText>{props.helperText || unitState.helperText || internalHelperText}</FormHelperText>}
        </FormControl>
    )
}

// export function RawDateField(props:RawDateFieldProps){

//   class LocalizedUtils extends LuxonUtils {
//     adapterLocale="fr"
//     getDatePickerHeaderText(date:DateTime) {
//       return dt.setLocale('fr').toFormat(props.displayFormat  || "EEE d MMM yyyy")
//     }
//   }

//   const [internalHelperText, setInternalHelperText] = useState('')

//   return (
// <FormControl error={!!internalHelperText || props.error}>

//     <MuiPickersUtilsProvider utils={LocalizedUtils}>
//     <KeyboardDatePicker

//     value={props.value}
//     label={props.label}
//     disabled = {props.disabled}

//     fullWidth={props.fullWidth}

//     //override so internal error never shows up
//     error={!!internalHelperText || props.error}
//     helperText={null}
//     //{...(unitState.isError ? { error: true } : {})}

//      clearable
//      placeholder="01/01/1999"
//      onChange={(date :DateTime | null, inputValue)=> {
//        console.log(date, inputValue)
//      setInternalHelperText(date?.invalidReason ? 'Invalide' : '')
//        props.onChange((date === null) ? null : date.toJSDate())}
//       }
//      format="dd/MM/yyyy"
//      clearLabel="r-à-zéro"
//      cancelLabel="annuler"

//      inputVariant = {props.variant}
//    />

//    </MuiPickersUtilsProvider>
//    {(internalHelperText || props.helperText || unitState) && <FormHelperText>{props.helperText || internalHelperText}</FormHelperText>}
//    </FormControl>
//   );
// }
