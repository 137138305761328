import { DateTime } from "luxon"

import { roundEuro } from "../tools"
export const EuropeParis = "Europe/Paris"

export function getCurrentPlanningMonthDt() {
    const now = DateTime.now()
    const offset = now.day === 1 ? -1 : 0
    const month = now.set({ day: 1, month: now.month + offset })
    return month
}

export function getCurrentPlanningMonthStr() {
    return getCurrentPlanningMonthDt().toISODate()
}

// export function isDispoNextYear(dispoNextYearAt: Date | null | undefined) {
//     const now = DateTime.now()
//     const startOfCurrentSchoolYear = now.month >= 8 ? now.set({ month: 8, day: 1 }) : now.set({ month: 8, day: 1 }).minus({ year: 1 })
//     return !!dispoNextYearAt && startOfCurrentSchoolYear.toJSDate() <= dispoNextYearAt
// }

/** Si pas rafraichi pour l'année scolaire (commence au 1er aout) */
export function isStaleData(lastUpdatedAt: Date | null | undefined) {
    const now = DateTime.now()
    const startOfCurrentSchoolYear = now.month >= 8 ? now.set({ month: 8, day: 1 }) : now.set({ month: 8, day: 1 }).minus({ year: 1 })
    return !(!!lastUpdatedAt && startOfCurrentSchoolYear.toJSDate() <= lastUpdatedAt)
}

export function tvaFromTTC(ttcPrice: number, tauxTVA: number) {
    return roundEuro(tvaFromTTCRaw(ttcPrice, tauxTVA))
}

export function tvaFromTTCRaw(ttcPrice: number, tauxTVA: number) {
    return ttcPrice * (tauxTVA / (1 + tauxTVA))
}

export function htFromTTC(ttcPrice: number, tauxTVA: number) {
    return roundEuro(ttcPrice / (1 + tauxTVA))
}

export function ttcFromHT(htPrice: number, tauxTVA: number) {
    return roundEuro(htPrice * (1 + tauxTVA))
}

export function tvaFromHT(htPrice: number, tauxTVA: number) {
    return roundEuro(htPrice * tauxTVA)
}

export function sortCreditsByRef<T extends { reference: string; aiciMode?: "AICI" | "CIDiff" | null }>(credits: T[]): T[] {
    return credits
        .sort((a, b) => {
            //tri CIDIff prioritaire sur AICI
            const av = { CIDiff: 1, AICI: 2 }[a.aiciMode!]
            const bv = { CIDiff: 1, AICI: 2 }[b.aiciMode!]
            return av - bv
        })
        .sort((a, b) => {
            const matchResA = a.reference.match(/([A-Z]+)(\d+)/)
            const matchResB = b.reference.match(/([A-Z]+)(\d+)/)

            if (!matchResA || !matchResB) return 0
            const lettersA = matchResA[1]!
            const digitsA = parseInt(matchResA![2]!)
            const lettersB = matchResB[1]!
            const digitsB = parseInt(matchResB![2]!)

            if (lettersA !== lettersB) return lettersA!.localeCompare(lettersB)
            else return digitsA - digitsB
        })
}
