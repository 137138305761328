/* eslint-disable pedagome/ensure-valid-token-typescript */
/* eslint-disable simple-import-sort/imports */
import { AcquisitionChannel, Agent, ConciseLevel, LearnerTrait, Level, MainCity, Mobility, rasmik, ReadData, StudyLevel, TeacherAbility, TeacherScolarityComplement, TeacherScolarityMain, Teaching, Urssaf } from "core/services/rasmik"
import { Child, Model, Property, Thunk } from "easy-peasy-classes"
import { EzModel } from "../store/base"

const requests = {
    mainCities: () => rasmik.readMany(MainCity).run(),
    acquisitionChannels: () => rasmik.readMany(AcquisitionChannel).run(),
    agents: () => rasmik.readMany(Agent).run(),
    learnerTraits: () =>
        rasmik
            .readMany(LearnerTrait)
            .options({ children: { TeacherAbilities: true } })
            .run(),
    teacherAbilities: () => rasmik.readMany(TeacherAbility).run(),
    conciseLevels: () => rasmik.readMany(ConciseLevel).run(),
    levels: () => rasmik.readMany(Level).run(),
    teachings: () => rasmik.readMany(Teaching).run(),
    teacherScolaritiesMain: () => rasmik.readMany(TeacherScolarityMain).run(),
    teacherScolaritiesComplement: () => rasmik.readMany(TeacherScolarityComplement).run(),
    urssafs: () => rasmik.readMany(Urssaf).run(),
    studyLevels: () => rasmik.readMany(StudyLevel).run(),
    mobilities: () => rasmik.readMany(Mobility).run(),
}

async function fetchAll() {
    const datas = await Promise.all(Object.values(requests).map((request) => request() as any))
    return datas
}

@Model
export class dbModel extends EzModel {
    @Property
    started: boolean = false

    @Property
    loaded: boolean = false

    @Property
    mainCities: ReadData<MainCity>[] = []

    @Property
    acquisitionChannels: ReadData<AcquisitionChannel>[] = []

    @Property
    agents: ReadData<Agent>[] = []

    @Property
    learnerTraits: ReadData<LearnerTrait, { children: { TeacherAbilities: true } }>[] = []

    @Property
    teacherAbilities: ReadData<TeacherAbility>[] = []

    @Property
    conciseLevels: ReadData<ConciseLevel>[] = []

    @Property
    levels: ReadData<Level>[] = []

    @Property
    teachings: ReadData<Teaching>[] = []

    @Property
    teacherScolaritiesMain: ReadData<TeacherScolarityMain>[] = []

    @Property
    teacherScolaritiesComplement: ReadData<TeacherScolarityComplement>[] = []

    @Property
    studyLevels: ReadData<StudyLevel>[] = []

    @Property
    urssafs: ReadData<Urssaf>[] = []

    @Property
    mobilities: ReadData<Mobility>[] = []

    @Thunk
    initDb(delay?: number) {
        this.setStateFn((s) => {
            s.started = true
        })
        setTimeout(async () => {
            await this.getStoreState().auth.ensureValidToken()

            const datas = await fetchAll()

            this.setStateFn((s) => {
                datas.forEach((data, index) => {
                    //@ts-ignore
                    s[Object.keys(requests)[index]] = data
                })
            })

            this.setStateFn((s) => {
                s.loaded = true
            })
        }, delay)
    }

    @Thunk
    async refresh(table: keyof typeof requests) {
        await this.getStoreState().auth.ensureValidToken()
        const data = await requests[table]()
        this.setStateFn((s) => {
            s[table] = data as any
        })
    }
}
