import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material"
import { createLocalHooks } from "core/store"

import type { commonCheckBoxListModel } from "./checkBoxList.model"

export function GridCheckBoxList$({ mapper }: { mapper: (s: any) => any }) {
    const µ = mapper
    const { useLocalState, useLocalActions } = createLocalHooks<commonCheckBoxListModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    //console.log(unitState.rows)
    if (!unitState) return null
    return (
        <List>
            <Grid container>
                {unitState.rows.map((row, index) => {
                    //const labelId = `checkbox-list-label-${value}`;

                    return (
                        <Grid item key={index}>
                            <ListItem key={index} role={undefined} dense button onClick={() => unitActions.handleToggle(index)}>
                                <ListItemIcon>
                                    <Checkbox edge="start" checked={row.isChecked || false} tabIndex={-1} disableRipple />
                                </ListItemIcon>
                                <ListItemText primary={row.primary} secondary={row.secondary} />
                                <ListItemSecondaryAction />
                            </ListItem>
                        </Grid>
                    )
                })}
            </Grid>
        </List>
    )
}
