/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, ReactNode, useMemo, useState } from "react"

export class stateContext {
    static Provider<T>(props: { Context: React.Context<stateContext.Value<T>>; children: ReactNode | ReactNode[]; initialStateValue?: T }) {
        const [state, setState] = useState<T>(props.initialStateValue)
        const contextValue = useMemo(() => ({ state, setState }), [state, setState])

        return <props.Context.Provider value={contextValue}>{props.children}</props.Context.Provider>
    }

    static create<T>(defaultValue?: T) {
        return createContext<stateContext.Value<T>>({ state: defaultValue, setState: () => {} })
    }
}

export namespace stateContext {
    export type Value<T> = { state: T; setState: React.Dispatch<React.SetStateAction<T>> }
}
