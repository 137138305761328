import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material/"
import { useEzActions, useEzState } from "core/store"
import { isString } from "core/utils"

export function MsgBox() {
    const unitState = useEzState((s) => s.core.dialogs.msgBox)
    const unitActions = useEzActions((s) => s.core.dialogs.msgBox)

    return (
        <Dialog open={unitState.isOpen}>
            <DialogTitle>{unitState.title}</DialogTitle>

            <DialogContent>
                <DialogContentText style={{ whiteSpace: "pre-line" }}>{unitState.msg}</DialogContentText>
            </DialogContent>

            <DialogActions>
                <GetButtons />
            </DialogActions>
        </Dialog>
    )
}

function GetButtons() {
    const unitState = useEzState((s) => s.core.dialogs.msgBox)
    const unitActions = useEzActions((s) => s.core.dialogs.msgBox)

    return (
        <>
            {unitState.buttons &&
                Object.keys(unitState.buttons).map((key, index) => {
                    const itemConf = unitState.buttons[key]

                    if (isString(itemConf)) {
                        const buttonLabel = itemConf
                        return (
                            <Button key={index} onClick={() => unitActions.handleClick(key)} color="primary" autoFocus>
                                {buttonLabel}
                            </Button>
                        )
                    } else {
                        return (
                            <Button key={index} onClick={() => unitActions.handleClick(key)} color="primary" autoFocus {...itemConf.buttonProps}>
                                {itemConf.label}
                            </Button>
                        )
                    }
                })}
        </>
    )
}
