import { Typography } from "@mui/material"
import { FastField, FastFieldProps } from "formik"

export function FmkErrorMessage({ name, awaitTouched = false, render: Render }: { name: string; awaitTouched?: boolean; render?: ({ errorText }: { errorText: string }) => React.ReactElement }) {
    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps<any>) => {
                let error = meta.error
                //nested errors override the string error and cause a "objects are not allowed as render" error
                if (typeof error === "object") error = null
                const touch = meta.touched
                const errorText = (touch || !awaitTouched) && error && typeof error === "string" ? error : null
                if (!errorText) return null

                if (Render) {
                    return <Render errorText={errorText} />
                } else {
                    return (
                        <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
                            {errorText}
                        </Typography>
                    )
                }
            }}
        </FastField>
    )
}
