/* eslint-disable simple-import-sort/imports */
import { Child, Model } from "easy-peasy-classes"
import { appModel } from "core/app/app.model"
import { dialogsModel } from "core/dialogs"
import { EzModel } from "./base"
import { playgroundModel } from "other/playground/playground.model"
import { dbModel } from "core/db/db.model"

@Model
export class coreModel extends EzModel {
    @Child
    app: appModel = appModel.obj

    @Child
    db: dbModel = dbModel.obj

    @Child
    dialogs: dialogsModel = dialogsModel.obj

    @Child
    playground: playgroundModel = playgroundModel.obj
}
