import { DayDate } from "."

function dateFilterFn(mmtRange: [Date, Date], mmt: Date): boolean
function dateFilterFn(dayDateRange: [string, string], mmt: Date): boolean
function dateFilterFn(range, mmt) {
    //if range is string dates -> convert mmt to string date
    if (range[0] instanceof Date) {
        mmt = DayDate.fromISOString(mmt).toISOString()
    }

    const [startOn, endOn] = range
    if (!startOn && !endOn) return true
    if (!startOn && endOn) return mmt <= endOn
    if (startOn && !endOn) return mmt >= startOn
    if (startOn && endOn) return mmt >= startOn && mmt <= endOn
    return false
}

export { dateFilterFn }

export const copyToClipboard = (text) => navigator.clipboard.writeText(text)

export type ValueOf<T> = T extends any[] ? T[number] : T[keyof T]

export function defineFormikFields<T>(fieldsArray: T): {
    [K in ValueOf<T> as K extends string ? K : never]: K
} {
    if (!Array.isArray(fieldsArray)) throw new Error("fieldsArray is not an array")
    const obj = {} as any

    fieldsArray.forEach((key) => {
        obj[key] = key
    })

    return obj
}

export function hasSameMonthYear(isoDateString1: string, isoDateString2: string) {
    if (!isoDateString1 || !isoDateString2) return false
    return isoDateString1.substr(0, 7) === isoDateString2.substr(0, 7)
}

export function sleep(ms: number) {
    const promise = new Promise((resolve, reject) => setTimeout(() => resolve(true), ms))
    return promise
}
