import { arrayQueryHelpers, defineQuery } from "core/queries"
import { rasmik, StandardInvoice, StandardInvoiceStatusEntries } from "core/services/rasmik"

const readOptions = rasmik.define.readOptions(StandardInvoice).val({
    include: ["*", "remainingAmountFormula"] as const,
    children: { CreditInvoice: true, Family: { children: { MainContact: true } } },
})

export const presta_invoices_query = defineQuery("prestaInvoicesQuery", () => ({
    key: ["presta_invoices_query"],
    queryFn: async (qryCtx, auth) => {
        await auth!.ensureValidToken()

        return await rasmik.readMany(StandardInvoice).where({ status: StandardInvoiceStatusEntries.INVOICE, CreditInvoice: null, noDoppleDebit: false }).options(readOptions).run()
    },
    defaultOptions: { initialData: [] as any },
})).withHelpers(arrayQueryHelpers)

export type PrestaInvoicesQueryData = (typeof presta_invoices_query)["dataShape"]
