import { commonDialogModel } from "core/controls"
import { DayDate } from "core/utils/tools"
import { Action, Child, Computed, Model, Thunk } from "easy-peasy-classes"
import type { RangeKeyDict } from "react-date-range"

type dateRange = { startOn: string; endOn: string }
@Model
export class dateRangesModel extends commonDialogModel<dateRange[]> {
    @Child
    picker: RangeKeyDict = {
        defaultSelection: {
            //startOn: undefined, //new Date(),
            //endOn: undefined,//new Date(),
            startDate: DayDate.today().toJsDate(),
            endDate: DayDate.today().toJsDate(),
            key: "defaultSelection",
        },
    }

    @Computed
    get getRanges() {
        return Object.keys(this.picker).map((key) => this.picker[key])
    }

    @Thunk //peut échouer dans quel cas on affiche pas
    async init({ ranges = [], title }: { ranges?: dateRange[]; title: string }) {
        this.resetState()
        ranges.length > 0 && this.setRanges(ranges)
        this.show({ title })

        return this.promise()
    }

    @Thunk
    validate() {
        const picker = this.picker
        const dialogReturn = Object.keys(picker).map((key) => ({
            startOn: DayDate.fromJSDate(picker[key].startDate).toISOString(),
            endOn: DayDate.fromJSDate(picker[key].endDate).toISOString(),
        }))
        this.endDialog(["success", dialogReturn])
    }

    @Action
    handlePickerChange(rangesByKey: RangeKeyDict) {
        this.picker = { ...this.picker, ...rangesByKey }
    }

    @Action
    setRanges(ranges: dateRange[]) {
        this.picker = ranges.reduce<RangeKeyDict>((acc, range, index) => {
            acc = {
                ...acc,
                [index.toString()]: {
                    startDate: DayDate.fromISOString(range.startOn).toJsDate(),
                    endDate: DayDate.fromISOString(range.endOn).toJsDate(),
                    key: index.toString(),
                },
            }
            return acc
        }, {})
    }
}
