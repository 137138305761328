import { createLocalHooksFactory, createTypedHooks, getMapperFactory } from "easy-peasy-classes"

import type { topModel } from "./store.model"

//Create typed hooks
const typedHooks = createTypedHooks<topModel>()
export const useEzActions = typedHooks.useStoreActions //Loose;
export const useEzDispatch = typedHooks.useStoreDispatch
export const useEzState = typedHooks.useStoreState //Loose;

//Create a typed createLocalHooks function
export const createLocalHooks = createLocalHooksFactory<topModel>(typedHooks)

//Create typed useMapper (and variants)
export const { useMapper } = getMapperFactory<topModel>()
