import MultiSelect, { MultiSelectProps } from "core/misc/multiselect"
import { useState } from "react"

type DropDownPickerProps<T = string> = Omit<MultiSelectProps<T>, "onClose" | "onChange"> & {
    onClose: (selectedValues: Array<T>) => any
}

export function DropDownPicker<T = string>({ onClose, options, value }: DropDownPickerProps<T>) {
    const [values, setValues] = useState([])

    return (
        <MultiSelect
            value={values} // From useState
            onChange={setValues} // From useState
            options={options}
            label="Matiéres"
            labelPlural="Matiéres"
            onClose={() => onClose(values)}
        />
    )
}
