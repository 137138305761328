import { Grid, styled } from "@mui/material"
import { DpLogoAnimated } from "other/images/DpLogoAnimated"
import type React from "react"

import { LoginCard } from "./LoginCard.com"

const AppContainer = styled("footer")(({ theme }) => ({
    textAlign: "center",
    margin: "0 !important",
    padding: "0 !important",
}))

const AppHeader = styled("footer")(({ theme }) => ({
    backgroundColor: "#fafafa",
    minHeight: "100vh",
    //display: "flex",
    //flexDirection: "column",
    //alignItems: "center",
    //justifyContent: "start",
    fontSize: "calc(10px + 2vmin)",
    color: "#212121",
    //overflow:"scroll"
}))

const AppFooter = styled("footer")(({ theme }) => ({
    position: "fixed",
    bottom: "0px",
    width: "100vw",
    backgroundColor: "#212121",
    "& p": { fontSize: "medium", color: "white", marginBlockStart: "5px", marginBlockEnd: "5px" },
}))

export const LoginPage: React.FC = () => {
    return (
        <AppContainer>
            <AppHeader>
                <Grid container>
                    <Grid item xs={12} container justifyContent="center" /*alignContent="center"*/>
                        <Grid item>
                            <DpLogoAnimated /*ClassName={cls['.App-logo-animated']}*/ size="35vmin" />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container justifyContent="center" /*alignContent="center"*/>
                        <Grid item>
                            <LoginCard />
                        </Grid>
                    </Grid>
                </Grid>
            </AppHeader>

            <AppFooter>
                <p>© pedagome {new Date().getFullYear()}</p>
            </AppFooter>
        </AppContainer>
    )
}

export default LoginPage
