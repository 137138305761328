import { styled } from "@mui/material"
import clsx from "clsx"
import { useRedirect } from "core/hooks"
import type { ComponentProps } from "react"

//TODO: test
const HoverPointerDiv = styled("div")({
    "&:hover": {
        cursor: "pointer",
    },
})

//TODO: test
const HoverPointerSpan = styled("span")({
    "&:hover": {
        cursor: "pointer",
    },
})

export const SpanLink = ({ to, children }: { to: string; children: any } & ComponentProps<typeof HoverPointerSpan>) => {
    const redirect = useRedirect()

    return <HoverPointerSpan onClick={() => redirect(to)}>{children}</HoverPointerSpan>
}

export const LinkWrapper = ({ to, children }: { to: string; children: any } & ComponentProps<typeof HoverPointerDiv>) => {
    const redirect = useRedirect()

    return <HoverPointerDiv onClick={() => redirect(to)}>{children}</HoverPointerDiv>
}
