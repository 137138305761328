import { Dialogs } from "core/dialogs"

//A place for common dialogs
export const DialogPool = () => {
    return (
        <>
            <Dialogs.LevelsOfTeachingCheckBoxList />
            <Dialogs.MsgBox />
            <Dialogs.InputBox />
            <Dialogs.NewEmail />
            <Dialogs.EditTemplate />
            <Dialogs.DateRanges />
            <Dialogs.WeekTimeRange />
        </>
    )
}

// <Dialogs.InputBox />
