//import { CopyToClipboard } from 'react-copy-to-clipboard';
//import { Unlayer } from 'core/shared/misc';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, styled, SxProps, Theme } from "@mui/material/"
import * as colors from "@mui/material/colors"
import { useCatchToSnackbar } from "core/hooks"
import { Spacer } from "core/misc"
import { openapi } from "core/services"
import { useEzActions, useEzState } from "core/store"
import { useAuthContext } from "core/technic/auth"
import { useSnackbar } from "notistack"
import React, { useEffect } from "react"

export function EditTemplate() {
    const unitActions = useEzActions((s) => s.core.dialogs.editTemplate)
    const templateState = useEzState((s) => s.core.dialogs.editTemplate.template)
    const { enqueueSnackbar } = useSnackbar()
    const isOpen = useEzState((s) => s.core.dialogs.editTemplate.dialog.isOpen)
    const unlayerState = useEzState((s) => s.core.dialogs.editTemplate.unlayer)
    const auth = useAuthContext()
    //const emailEditorRef = useRef(null);

    const exportDesign = () => {
        window.unlayer.exportHtml((data: { design: any; html: any }) => {
            const { design, html } = data
            unitActions.validate({ design, html })
        })
    }

    const prepareTest = useCatchToSnackbar(async () => {
        window.unlayer.exportHtml(async (data: { design: any; html: any }) => {
            const { design, html } = data

            let recipient = ""
            while (true) {
                recipient = prompt("A qui voulez-vous envoyer ?", auth.tokenPayload.user.email)
                if (!recipient) break
                if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(recipient)) {
                    alert("Email invalide")
                } else {
                    break
                }
            }
            if (!recipient) return

            await auth.ensureValidToken()
            await openapi.emails.send({
                recipient,
                sender: auth.tokenPayload.user.email,
                subject: "Test template : " + templateState.subject,
                html,
            })

            enqueueSnackbar("Test envoyé à " + auth.tokenPayload.user.email, { variant: "success" })
        })
    })

    const design = unlayerState.design

    return (
        <Dialog open={isOpen} sx={{ backgroundColor: colors.grey[50], minHeight: "70vh" }} fullWidth maxWidth="xl">
            <DialogTitle>Envoi de mail</DialogTitle>

            <DialogContent sx={{ display: "flex", flexDirection: "column", flexGrow: 1, minHeight: "70vh" }}>
                <Grid container alignContent="stretch" flexGrow={1} /* sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, }} */>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                        <EmailEditor design={design} />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => unitActions.cancel()}>Annuler</Button>
                <Spacer />
                <Button onClick={() => prepareTest()} color="secondary">
                    Tester
                </Button>
                <Button onClick={() => exportDesign()} color="primary" autoFocus>
                    Enregister
                </Button>
            </DialogActions>
        </Dialog>
    )
}

/*
<Grid item xs={12} container spacing={3} justifyContent="flex-start">
            <Grid item><ComboBox$ id='lstSender$' mapper={µ} label="Expéditeur" freeSolo /></Grid>
            <Grid item><ComboBox$ id='lstRecipient$' mapper={µ} label="Destinataire" freeSolo /></Grid>
          </Grid>*/
// <Button onClick={copyTemplateToClipBoard} >Tester</Button>

//<Unlayer template={unlayerState.template} mapper={µ} id='unlayer'/>

const UnlayerDiv = styled("div")({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "& iframe": {
        flex: 1,
        display: "flex",
    },
})

function EmailEditor(props: { design: any }) {
    const { design } = props
    const { unlayer } = window

    const initializeUnlayer = () => {
        unlayer.init({
            id: "unlayer",
        })
        unlayer.loadDesign(design)
    }

    useEffect(() => {
        initializeUnlayer()
    }, [design])

    return <UnlayerDiv id="unlayer" />
}
