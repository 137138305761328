import { commonCheckBoxListModel, commonDialogModel } from "core/controls"
import { Level, rasmik, ReadData, Teaching } from "core/services/rasmik"
import { Child, Model, Property, Thunk } from "easy-peasy-classes"

type TeachingType = ReadData<Teaching, { children: { TeachingLevels: true } }>
type TeachingLevelType = ReadData<Level, true>

@Model
export class levelsOfTeachingModel extends commonDialogModel<void> {
    @Property
    teaching: TeachingType = {} as any

    @Child
    checkBoxList: commonCheckBoxListModel<TeachingLevelType> = commonCheckBoxListModel.obj

    @Thunk
    async init({ teachingId }: { teachingId: number }) {
        await this.getStoreState().auth.ensureValidToken()
        const teaching = await rasmik
            .readOne(Teaching)
            .where(teachingId)
            .options({ children: { TeachingLevels: true } })
            .run()
        const allLevels = (await rasmik.readMany(Level).run()).sort((a, b) => a.orderNb - b.orderNb)

        this.setStateFn((s) => {
            s.teaching = teaching
        })

        this.checkBoxList.setRows({ data: allLevels, primaryField: "nameLong" })
        this.checkBoxList.setChecked((row) => teaching.TeachingLevels.some((tl) => tl.Level === row.id))

        this.show({ title: teaching.name })
        return this.promise()
    }

    @Thunk
    async validate() {
        const pushDef = rasmik.define.pushDef(Teaching).val({
            allow: "ref", //TODO: Test 2 ref ET tester insert/update avec les ids
            children: {
                TeachingLevels: {
                    collectionMode: "set",
                    //deleteOrphans:true,
                    allow: "upsert",
                    children: {
                        Level: { allow: "pk" },
                        Teaching: { allow: "pk" },
                    },
                },
            },
        })
        await this.getStoreState().auth.ensureValidToken()
        await rasmik
            .pushOne(Teaching)
            .pushDef(pushDef)
            .data({
                id: this.teaching.id,
                TeachingLevels: this.checkBoxList.checkedRows.map((row) => ({ Teaching: this.teaching.id, Level: row.id })),
            })
            .run()

        this.endDialog(["success"])
    }
}
