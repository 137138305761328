import { withGrid, WithGridProps } from "core/misc"
import { RawSwitch, RawSwitchProps } from "core/rawFields"
import { useField, useFormikContext } from "formik"

export interface FmkRawSwitchProps<OptionData extends object> extends Omit<RawSwitchProps, "value" | "onChange" | "error"> {
    name: string
    validateImmediately?: boolean
}

export const FmkSwitch = withGrid(<OptionData extends object>(props: FmkRawSwitchProps<OptionData>) => {
    const { name, validateImmediately, ...otherProps } = props
    const [field, meta, { setValue, setTouched }] = useField<string>(name)
    const { isSubmitting } = useFormikContext()

    const handleChange = (newValue: any, optionData: any) => {
        setTouched(true, false)
        setValue(newValue, true)
    }

    const defaultProps: Partial<RawSwitchProps> = {
        onChange: handleChange,
    }

    const config = {
        ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
        ...defaultProps,
        ...otherProps,
    }
    if (meta && (meta.touched || validateImmediately) && meta.error) {
        config.error = true
        config.helperText = meta.error
    }

    config.disabled = config.disabled ?? isSubmitting

    return <RawSwitch {...config} />
})
