import { Dialog } from "@mui/material"
import { useAuthContext } from "core/technic/auth"
import { LoginPage } from "pages/login-page/LoginPage.com"

export function LoginPageDialog() {
    const auth = useAuthContext()
    return (
        <Dialog open={auth.isLoginDialogOpen} fullScreen sx={{ zIndex: 9999 }}>
            <LoginPage />
        </Dialog>
    )
}
