import * as constants from "../constants"

export function genCode(length: number) {
    return [...Array(length)]
        .map((i) => (~~(Math.random() * 36)).toString(36))
        .join("")
        .toUpperCase()
}

export const getYsViewLink = (ysProc: { ysKey?: string }) => {
    return constants.YOUSIGN_URL + "/" + ysProc?.ysKey
}

export const getYsSignLink = (ysProc: { ysLink?: string }) => {
    return ysProc?.ysLink
}

export const templateTag = (tagName: string) => {
    return new RegExp("\\|" + tagName + "\\|", "g")
}

export enum ReductionsFormule {
    f10 = 1,
    f20 = 2,
    f50 = 3.5,
    f80 = 4.5,
}

export function isHalfable(val: number) {
    if (!val || isNaN(val)) return true

    return Math.round(val * 100) % 2 === 0
}
