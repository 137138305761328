import { EzModel } from "core/store/base"
import { Action, Computed, Model, Property } from "easy-peasy-classes"

interface row {
    primary: string
    secondary: string
    isChecked: boolean
}

interface setRowsProps<T> {
    data: T[]
    primaryField: keyof T
    secondaryField?: keyof T
}

interface addItemProps<T> {
    data: T
    primaryField: keyof T
    secondaryField?: keyof T
    isChecked: boolean
}

@Model
export class commonCheckBoxListModel<RowData extends object = {}> extends EzModel {
    @Property
    rows: (RowData & row)[] = []

    @Property
    isLocked: boolean = false

    @Property
    isUnique: boolean = false

    // @Property
    // label:string = ''

    @Action
    applySettings({ settings }: { settings: Partial<commonCheckBoxListModel> }) {
        Object.assign(this, settings)
    }

    @Action
    handleToggle(index: number) {
        if (this.isLocked) return
        if (this.isUnique) this.rows.forEach((row) => (row.isChecked = false))
        const row = this.rows[index]
        row.isChecked = !row.isChecked
    }

    @Action
    selectAll() {
        this.rows.forEach((row) => (row.isChecked = true))
    }

    @Action
    unSelectAll() {
        this.rows.forEach((row) => (row.isChecked = false))
    }

    @Action
    setChecked(testFn: (row: row & RowData) => boolean) {
        this.rows.forEach((row) => (row.isChecked = testFn(row)))
    }

    @Action
    setRows({ data = [], primaryField, secondaryField = undefined }: setRowsProps<RowData & Partial<row>>) {
        const rows = data.map((row) => ({
            ...row,
            primary: row[primaryField],
            secondary: row[secondaryField],
            isChecked: (row as any).isChecked || false,
        }))
        //console.log(rows)
        this.rows = rows as any
    }

    @Action
    addItem({ data: row, primaryField, secondaryField, isChecked }: addItemProps<RowData>) {
        this.rows.push({
            ...row,
            primary: row[primaryField],
            secondary: row[secondaryField],
            isChecked: isChecked || false,
        } as any)
    }

    @Computed
    get checkedRows() {
        return this.rows.filter((row) => row.isChecked === true)
    }

    // @Computed
    // get checkedIds(){
    //     return this.rows.filter(row => row.isChecked===true).map(row=>row.id)
    // }

    // @Computed
    // get checkedIdsObjects(){
    //     return this.rows.filter(row => row.isChecked===true).map(row=>({id: row.id}))
    // }
}

export const commonCheckBoxList = () => commonCheckBoxListModel.obj
