import { Badge, ButtonProps, IconButton, Tooltip, TooltipProps,Typography, TypographyProps } from "@mui/material"
import { styled } from "@mui/material/styles"
import type { LiteralUnion } from "core/utils/types"
import type { CSSProperties, ReactNode } from "react"

import { ConditionnalTooltip } from "./ConditionnalTooltip.com"
import { Pie } from "./Dot.com"

const stylePropsKeys = ["center", "size", "innerLabelColor", "innerLabelFontSize", "marginInlineStart", "marginInlineEnd", "secondaryColor", "innerLabelWeight"] as const
type styleProps = Pick<StatusBadgeProps, (typeof stylePropsKeys)[number]>

const PieStyled = styled(Pie, { shouldForwardProp: (propName) => !(stylePropsKeys as readonly PropertyKey[]).includes(propName) })<styleProps>(({ theme, ...props }) => ({
    height: props.size || "32px",
    width: props.size || "32px",

    color: props.innerLabelColor || "white",
    fontSize: props.innerLabelFontSize || "19px",
    fontWeight: props.innerLabelWeight || 500,

    // borderWidth:'4px',
    // borderStyle:'solid',
    // borderColor:  props.secondaryColor || props.mainColor,
    // backgroundColor:props.mainColor,
    borderRadius: "50%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginInlineStart: props.marginInlineStart || "5px",
    marginInlineEnd: props.marginInlineEnd || "5px",
    verticalAlign: "middle",
    textAlign: props.center || "center",
}))

export interface StatusBadgeProps {
    /**css color prop */
    color: LiteralUnion<StatusBadgeColorsName> | Array<LiteralUnion<StatusBadgeColorsName>>
    secondaryColor?: LiteralUnion<StatusBadgeColorsName>

    innerLabel?: string
    innerLabelColor?: string
    innerLabelFontSize?: string
    innerLabelWeight?: number

    label?: string
    labelPosition?: "left" | "right"

    /** ex: '11px' */
    size?: CSSProperties["height"]

    marginInlineStart?: string
    marginInlineEnd?: string

    typoProps?: TypographyProps

    /**css textAlign prop */
    center?: CSSProperties["textAlign"]

    tooltip?: ReactNode
    tootltipPlacement?: TooltipProps["placement"]

    count?: number

    button?: boolean
    onClick?: ButtonProps["onClick"]

    rotate?: string
    warn?: boolean
}

// export type CrmStatues =  "DRAFT"|"READY"|"PENDING"|"OK"|"URGENT"|"DISABLED"|"DEAD"

// export interface CrmStatusBadgeProps extends Partial<StatusBadgeProps> {
//     status: CrmStatues | [CrmStatues,CrmStatues]
//     type:"program"|"match"| "proposiion"
//     tooltip?:string
//     warn?:boolean
//     warnTooltip?:string

// }

const StatusBadgeColors = {
    "need-action": {
        color: "red",
        tooltip: "Action requise",
    },
    ready: {
        color: "light-blue",
        tooltip: "Action requise",
    },
    "waiting-ext": {
        color: "orange",
        tooltip: "En attente d'une action exterieure",
    },
    ok: {
        color: "green",
        tooltip: "ok",
    },
    missing: {
        color: "black",
        tooltip: "Manquant",
        innerLabelColor: "red",
    },
    "info-ok": {
        color: "dodgerblue",
        tooltip: "infos ok",
    },
} as const
export type StatusBadgeColorsName = keyof typeof StatusBadgeColors

//multiple
export function StatusBadge(props: StatusBadgeProps) {
    //@ts-ignore
    const tooltip = props.tooltip || StatusBadgeColors[props.color]?.tooltip
    //@ts-ignore
    // const innerLabelColor = props.innerLabelColor || StatusBadgeColors[props.color]?.innerLabelColor

    const colors = (Array.isArray(props.color) ? props.color : [props.color]).map((color) => StatusBadgeColors[color]?.color || color)

    const { center, size, innerLabelColor, innerLabelFontSize, marginInlineStart, marginInlineEnd, secondaryColor, innerLabelWeight, rotate } = props
    const styledProps = { center, size, innerLabelColor, innerLabelFontSize, marginInlineStart, marginInlineEnd, secondaryColor, innerLabelWeight, rotate }

    const { label = "", labelPosition = "right", typoProps, innerLabel = "", button, onClick } = props

    const DotElement = (
        <PieStyled colors={colors} {...styledProps} style={{ position: "relative" }}>
            {props.warn && <div style={{ position: "absolute", top: "-8px", right: "-8px", fontSize: "14px" }}>⚠️</div>}
            {innerLabel}
        </PieStyled>
    )

    return (
        <Typography whiteSpace="nowrap" {...typoProps}>
            {labelPosition === "left" ? label : ""}
            <ConditionnalTooltip enabled={Boolean(tooltip)} placement={props.tootltipPlacement} title={tooltip} arrow containerProps={{ style: { display: "inline" } }}>
                <Badge badgeContent={props.count} invisible={!(props.count > 1)} color="primary">
                    {button ? (
                        <IconButton onClick={onClick} sx={{ padding: "0px" }}>
                            {DotElement}
                        </IconButton>
                    ) : (
                        DotElement
                    )}
                </Badge>
            </ConditionnalTooltip>
            {labelPosition === "right" ? label : ""}
        </Typography>
    )
}

// export function StatusBadge(props:StatusBadgeProps){

//     const colObjArr:Array<{color:string, tooltip?:string}> = []

//     const colorPropNames = Array.isArray(props.colors) ?   props.colors :  [props.colors]

//     for (const colorPropName of colorPropNames){

//         if(StatusBadgeColors[colorPropName])  colObjArr.push(StatusBadgeColors[colorPropName])
//         else colObjArr.push({color: colorPropName})
//     }

//     const tooltip = props.tooltip || colObjArr[0].tooltip || undefined

//     const classes = useStyles(props);

//     const {label = "", labelPosition = "right",typoProps,innerLabel=''} = props

//     const count = colObjArr.length

//     return(

//         <div>
//             <Typography {...typoProps}>
//                 {labelPosition === "left" ? label : ''}
//                 <Tooltip title={tooltip} arrow>

// <div className={classes.dotGroup}>
//                     {colObjArr.map((colObj, index) => (
//                         <span className={classes.dot} style={{ backgroundColor: colObj.color }}>{innerLabel}</span>
//                     ))}

// </div>
//                 </Tooltip>
//                 {labelPosition === "right" ? label : ''}
//             </Typography>
//         </div>
//     )
// }
