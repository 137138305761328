import { MaterialTableCustom, MaterialTableCustomColumn, MaterialTableCustomProps } from "core/misc"
import { createLocalHooks } from "core/store"
import { useAuthContext } from "core/technic/auth"
import { tableIcons } from "core/utils/importIcons"
import { merge } from "lodash"
import React, { useEffect } from "react"

import type { commonTableCrudModel } from "./tableCrud.model"

export interface TableCrudProps<RowData extends object = Record<string, any>> extends Omit<MaterialTableCustomProps<RowData>, "data"> {
    id?: string
    mapper: (s: any) => any
    disablePagination?: boolean
    disableAutoLoad?: boolean
    disableAdd?: boolean
    disableEdit?: boolean
    disableDelete?: boolean
    data?: Array<RowData>
}

export function TableCrud<RowData extends object = Record<string, any>>(props: TableCrudProps<RowData>) {
    const { id, mapper: µ, columns = [], disablePagination, disableAutoLoad, disableAdd, disableEdit, disableDelete, options, ...rest } = props

    const auth = useAuthContext()
    const { useLocalState, useLocalActions } = createLocalHooks<commonTableCrudModel<any>>((s) => µ(s))

    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    mergeAdditionnalColumnDef(columns, unitState.columnDefsDict)

    const { isLoaded } = unitState
    useEffect(() => {
        !disableAutoLoad && unitActions.handleRefresh({ auth })
    }, [isLoaded, auth])

    //console.log(columns)
    return (
        <MaterialTableCustom
            {...rest}
            icons={tableIcons}
            columns={columns} //, ...columns
            data={unitState.data}
            editable={{
                onRowAdd: disableAdd ? undefined : (newRow) => unitActions.handleRowAdd(newRow) as any as Promise<void>,
                onRowUpdate: disableEdit ? undefined : (newRow) => unitActions.handleRowEdit(newRow) as any as Promise<void>,
                onRowDelete: disableDelete ? undefined : (row: Record<string, any>) => unitActions.handleRowDelete(row.id) as any as Promise<void>,
            }}
            actions={[
                ...unitState.rowActions,
                ...unitState.tableActions,
                {
                    icon: tableIcons.Refresh,
                    tooltip: "Rafraichir les données",
                    isFreeAction: true,
                    onClick: () => unitActions.handleRefresh({ auth }),
                },
            ]}
            options={{
                grouping: unitState.grouping,
                pageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 300],
                //paginationType: 'stepped',
                paging: !disablePagination,
                ...options,
            }}
            isLoading={unitState.isLoading}
            /*components={{
        Action: props => (
          <Button
            onClick={(event) => props.action.onClick(event, props.data)}
            color="primary"
            variant="contained"
            style={{textTransform: 'none'}}
            size="small"
          >
            My Button
          </Button>
        ),
      }}*/
        />
    )
}

function mergeAdditionnalColumnDef(propsColumns: Array<MaterialTableCustomColumn<any>>, stateColumsDict: Record<string, any> = {}) {
    for (const colIdentifier in stateColumsDict) {
        const col = propsColumns.find((col) => col.field === colIdentifier || col.id === colIdentifier)
        if (!col) throw new Error(`Cannot add additional columnDef from state into column '${colIdentifier}' becaused it has not been found in the TableCrud columns prop`)

        merge(col, stateColumsDict[colIdentifier])
    }
}
