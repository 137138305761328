export { cloneDeep } from "lodash"

/**
 * Takes an array of objects and return one object with a selected field as the key and the object itself as value
 */
export const convertArrayToObject = <T extends Record<string, any>>(array: T[], key: string): Record<string, T> => {
    const initialValue = {}
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        }
    }, initialValue)
}

/**
 * Takes an array of objects and return one object with {key: field} where
 */
export const convertArrayToDictionnary = <Row extends Record<string, any>>(array: Array<Row> = [], key: string | ((obj: Row) => string), field: string | ((row: Row) => any)): Record<string, any> => {
    const initialValue = {}
    return array.reduce((obj, row) => {
        const fieldVal = typeof field === "string" ? row[field] : field(row)
        const keyVal = typeof key === "string" ? row[key] : key(row)

        return {
            ...obj,
            [keyVal]: fieldVal,
        }
    }, initialValue)
}

/**
 * Takes only one property of the objects make an array with it
 */
export const convertRowectsToIdArray = <T extends Record<string, any>, Key extends string>(arrayOfObjects: T[], key: Key): Array<T[Key]> => {
    const initialValue = []
    return arrayOfObjects.reduce((arr, item) => {
        return [...arr, item[key]]
    }, initialValue)
}

/**
 * Same as Object.assign but do not add extra properties to the target object.
 */
export function assignShape<Objs extends Record<string, any>[]>(...objects: Objs): Objs[0] {
    if (objects.length === 0) return undefined

    const left = objects[0]
    if (objects.length === 1) return left

    for (let index = 0; index < objects.length - 1; index++) {
        const right = objects[index + 1]
        Object.keys(left).forEach((key) => {
            if (right && right[key]) {
                left[key] = right[key]
            }
        })
    }
    return left
}

export const upsert = (input) => {
    const handler = {
        get: (obj, prop) => {
            obj[prop] = obj[prop] || {}
            return upsert(obj[prop])
        },
    }
    return new Proxy(input, handler)
}

export function createMuiTableLookup<Obj extends object>(payload: { dict?: Record<string, any>; list?: string[]; objects?: Obj[]; objectKey?: keyof Obj | ((obj: Obj) => string); objectField?: keyof Obj | ((obj: Obj) => string) }) {
    const { dict, list, objects, objectKey = "id", objectField } = payload

    let finalDict: any
    if (dict) finalDict = dict
    else if (list)
        finalDict = list.reduce((acc, key) => {
            acc[key] = key
            return acc
        }, {})
    else if (objects) finalDict = convertArrayToDictionnary(objects, objectKey as any, objectField as any)
    else finalDict = {}

    return finalDict
}
