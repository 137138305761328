import { DayDate, testIban } from "core/utils"
import * as Yup from "yup"

export const requiredString = (message: string) => Yup.string().nullable().required(message)

export const baseMemberSchemaStrict = Yup.object().shape({
    role: requiredString("Rôle requis"),
})

export const contactSchemaStrict = Yup.object().shape({
    role: requiredString("Rôle requis"),
    gender: requiredString("Genre requis"),
    firstName: requiredString("Prénom requis"),
    lastName: requiredString("Nom requis"),
    PhoneNumbers: Yup.array()
        .of(
            Yup.object().shape({
                number: requiredString("Numéro de téléphone invalide"),
            })
        )
        .min(1, "Au moins 1 tel pour le contact"),
    email: Yup.string().nullable().required("Email requis").email("Email invalide"),
})

export const chiefSchemaStrict = Yup.object().shape({
    role: requiredString("Rôle requis"),
    gender: requiredString("Genre requis"),
    firstName: requiredString("Prénom requis"),
    lastName: requiredString("Nom requis"),

    birthName: Yup.string().nullable().notRequired(),
    birthDate: Yup.string().nullable().notRequired(), //requiredString('Date de naissance requise'),
})

export const learnerSchemaStrict = Yup.object().shape({
    role: requiredString("Rôle requis"),
    gender: requiredString("Genre requis"),
    firstName: requiredString("Prénom requis"),
    lastName: requiredString("Nom requis"),
    birthDate: requiredString("Date de naissance requise"),
    scolarityClassLabel: Yup.string().nullable().required("Libellé scolarité élève requis"),
    scolaritySchool: Yup.string().nullable().notRequired(),
    scolarityExpireOn: Yup.string()
        .nullable()
        .required("Date d'expiration de la scolarité requise")
        .test("is-future", "Scolarité élève à actualiser", (val) => val > DayDate.today().toISOString()),
    scolarityConciseLevel: Yup.number().nullable().required("Niveau scolarité élève requis"),

    WeekDispos: Yup.array().of(Yup.object().shape({})).min(1, "Au moins 1 dispo pour l'élève"),

    LearnerTraits: Yup.array().of(Yup.object().shape({})).min(1, "Au moins 1 critère de matchmaking pour l'élève"),

    LessonAddresses: Yup.array().of(Yup.object().shape({})).min(1, "Au moins 1 adresse pour les cours de l'élève"),
})

export const broadcastableSchema = Yup.object({
    learner: Yup.object().shape({
        WeekDispos: Yup.array().of(Yup.object().shape({})).min(1, "Au moins 1 dispo pour l'élève"),
        LearnerTraits: Yup.array().of(Yup.object().shape({})).min(1, "Au moins 1 critère de matchmaking pour l'élève"),
        LessonAddresses: Yup.array().of(Yup.object().shape({})).min(1, "Au moins 1 adresse pour les cours de l'élève"),
    }),
    match: Yup.object().shape({
        label: Yup.string().nullable().required("Libellé match requis"),
    }),
    program: Yup.object().shape({
        // Teaching: Yup.number().nullable().required("Matière requise (programme)"),
        // defaultTeacherInstructions: Yup.string().nullable().notRequired(),
        // goals: Yup.string().nullable().notRequired(),
        // initialMean: Yup.number().nullable().notRequired(),
        // teachingLabel: Yup.string().nullable().required("Libellé de la matière (programme)"),
        wishedDurationMin: Yup.number().nullable().required("Durée souhaitée requise (programme)"),
        wishedFrequency: Yup.number().nullable().required("Fréquence souhaitée requise (programme)"),
    }),
})

export const facturationAddressSchemaStrict = Yup.number().nullable().required("Aucune adresse choisie pour la facturation")

export const programSchemaStrict = Yup.object().shape({
    Teaching: Yup.number().nullable().required("Matière requise (programme)"),
    defaultTeacherInstructions: Yup.string().nullable().notRequired(),
    goals: Yup.string().nullable().notRequired(),
    initialMean: Yup.number().nullable().notRequired(),
    teachingLabel: Yup.string().nullable().required("Libellé de la matière (programme)"),
    wishedDurationMin: Yup.number().nullable().required("Durée souhaitée requise (programme)"),
    wishedFrequency: Yup.number().nullable().required("Fréquence souhaitée requise (programme)"),
    // wishedFormula: null,
})

export const matchSchemaStrict = Yup.object().shape({
    label: Yup.string().nullable().required("Libellé match requis"),
    ConciseLevel: Yup.number().nullable().required("Niveau (simplifié) requis"),
    Level: Yup.number().nullable().required("Niveau (stats) requis"),
    basePrice: Yup.number().nullable().required("Tarif de référence requis").min(10, "Au moins 12€/h"),
})

export const matchSchemaTeacherProp = Yup.object().shape({
    label: Yup.string().nullable().required("Libellé match requis"),
    ConciseLevel: Yup.number().nullable().required("Niveau (simplifié) requis"),
    Level: Yup.number().nullable().required("Niveau (stats) requis"),
})

export const teacherPropositionSchemaStrict = Yup.object().shape({
    Teacher: Yup.object().shape({ id: Yup.number().required() }).nullable().required("Tuteur non choisi"),
    // firstLessonPlannedAt: Yup.date().nullable().min(new Date(), 'Date 1er cours dépassée').notRequired(),
    // firstLessonPlannedDurationMin: Yup.number().nullable().notRequired(),
    teacherInstructions: Yup.string().nullable().notRequired(),
    netH: Yup.number().nullable().min(10, "Au moins 10€/h").required("Salaire net requis"),
    pedagoH: Yup.number().nullable(),
    transpH: Yup.number().nullable(),
})

//TODO: Quand plus access, permettre envoi avec seulement le client
export const customerPropositionSchemaStrict = Yup.object().shape({
    message: requiredString("Message client requis"),
    checkedCreationBlockIds: Yup.array().of(Yup.number()).min(1, "Pas de lot de crédit sélectionné"),
    checkedTeacherPropostionIds: Yup.array().of(Yup.number()).min(1, "Pas de proposition tuteur sélectionnée"),
})

export const affiliationCISchema = Yup.object().shape({
    MainContact: Yup.object()
        .nullable()
        .required("Membre de contact a choisir")
        .shape({
            email: Yup.string().nullable().required("Email requis").email("Email invalide"),
            MainPhoneNumber: Yup.object()
                .nullable()
                .shape({
                    number: requiredString("Numéro de téléphone requis"),
                }),
        }),
    FamilyChief: Yup.object()
        .nullable()
        .required("Membre de facturation a choisir")
        .shape({
            gender: requiredString("Genre requis"),
            firstName: requiredString("Prénom requis"),
            lastName: requiredString("Nom requis"),
            birthDate: requiredString("Date de naissance requise"),
            birthCountryCode: Yup.string().nullable().required("Code pays de naissance requis").matches(/\d{5}/, "Code pays de naissance invalide"),
            birthCity: Yup.string()
                .nullable()
                .when(["countryCode"], {
                    is: "99100",
                    then: (shema) => shema.required("Ville naissance requise"),
                }),
            birthCityCode: Yup.string()
                .nullable()
                .when(["countryCode"], {
                    is: "99100",
                    then: (shema) => shema.required("Code département requis").matches(/^([02][1-9]|2[AB]|[1345678][0-9]|9[012345])\d{3}$/, "Code ville invalide"),
                }),
            birthDpt: Yup.string()
                .nullable()
                .when(["countryCode"], {
                    is: "99100",
                    then: (shema) => shema.required("Code département requis").matches(/^([02][1-9]|2[AB]|[1345678][0-9]|9[012345]|97[12346])$/, "Code département invalide"),
                }),
        }),
    bic: requiredString("BIC requis").matches(/([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/, "BIC invalide"),
    iban: requiredString("IBAN requis").test({ test: (val) => testIban(val), message: "IBAN invalide" }),
})
