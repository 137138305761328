/* eslint-disable simple-import-sort/imports */
import { EzModel } from "core/store/base"
import { Child, Model } from "easy-peasy-classes"

import { dateRangesModel } from "./dateRanges.model"
import { editTemplateModel } from "./editTemplate.model"
import { inputBoxModel } from "./inputBox.model"
import { levelsOfTeachingModel } from "./levelsOfTeaching.model"
import { msgBoxModel } from "./msgBox.model"
import { newEmailModel } from "./newEmail.model"
import { weekTimeRangeModel } from "./weekTimeRange.model"

@Model
export class dialogsModel extends EzModel {
    @Child
    levelsOfTeaching: levelsOfTeachingModel = levelsOfTeachingModel.obj

    @Child
    msgBox: msgBoxModel = msgBoxModel.obj

    @Child
    inputBox: inputBoxModel = inputBoxModel.obj

    @Child
    newEmail: newEmailModel = newEmailModel.obj

    @Child
    editTemplate: editTemplateModel = editTemplateModel.obj

    @Child
    dateRanges: dateRangesModel = dateRangesModel.obj

    @Child
    weekTimeRange: weekTimeRangeModel = weekTimeRangeModel.obj
}
