import { useFormikContext } from "formik"
import { useEffect } from "react"
import type { AnySchema } from "yup"

interface FmkValidationTriggerProps {
    dynamicValidationSchema?: AnySchema
    dynamicInitialData?: any
}

/**
 * Triggers validation of the parent Form on init
 * Provide dynamicInitialData and/or dynamicValidationSchema if validation must be triggered every time the initalData or validationSchema changes
 */
export function FmkValidationTrigger(props: FmkValidationTriggerProps) {
    const { validateForm } = useFormikContext()

    useEffect(() => {
        validateForm()
    }, [props.dynamicInitialData, props.dynamicValidationSchema])

    return <></>
}
