/* eslint-disable simple-import-sort/imports */
import "./wdyr"
import React from "react"
import ReactDOM from "react-dom/client"
//import './index.css';
import "core/services/rasmik"
import { App } from "./core/app/App.com"

//import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import * as themes from "./other/themes"

import { StoreProvider } from "easy-peasy"
import { BrowserRouter } from "react-router-dom"

import { ConditionalWrapper } from "core/utils/tools/react"

import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { persistQueryClient } from "react-query/persistQueryClient-experimental"
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental"
import { DefaultLocalizationProvider } from "./core/misc"

import "other/css/index"
import { store } from "core/store/store"
import { Settings } from "luxon"
import { parseWithDate } from "core/utils"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { SnackbarProviderCustom } from "core/technic/snackbars/snackbar-provider"
import { AuthContextProvider } from "core/technic/auth"
import { SnackbarUtilsConfigurator } from "core/utils/notistack-global"

const isStrictMode = false // || true;

Settings.defaultLocale = "fr"

/* React query settings */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 15, // 15 minutes
        },
    },
})

const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage, deserialize: parseWithDate })

persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
})

ReactDOM.createRoot(document.getElementById("root")).render(
    <ThemeProvider theme={themes.custom1}>
        <CssBaseline />

        <ConditionalWrapper condition={isStrictMode} wrapper={(children: any) => <React.StrictMode>{children}</React.StrictMode>}>
            <QueryClientProvider client={queryClient}>
                <DefaultLocalizationProvider>
                    <SnackbarProviderCustom>
                        <SnackbarUtilsConfigurator />
                        <BrowserRouter>
                            {/**@ts-ignore */}
                            <StoreProvider store={store}>
                                <AuthContextProvider>
                                    <HelmetProvider>
                                        <Helmet>
                                            <title>Dopple</title>
                                        </Helmet>
                                        <App />
                                    </HelmetProvider>
                                </AuthContextProvider>
                            </StoreProvider>
                        </BrowserRouter>
                    </SnackbarProviderCustom>
                    <ReactQueryDevtools initialIsOpen={false} />
                </DefaultLocalizationProvider>
            </QueryClientProvider>
        </ConditionalWrapper>
    </ThemeProvider>
)

declare global {
    namespace NodeJS {
        interface ProcessEnv /*extends NodeJS.ProcessEnv*/ {
            // NODE_ENV: 'development' | 'production' | 'staging';
            REACT_APP_TARGET_ENV: "development" | "staging" | "production"
            PORT?: string
            REACT_APP_DOPPLE_API_URL: string
            REACT_APP_YOUSIGN_URL: string
            SKIP_PREFLIGHT_CHECK: string
            REACT_EDITOR: string
            REACT_APP_WDYR: string
        }
    }
}
