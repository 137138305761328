import { Button, Grid, Typography } from "@mui/material"

import { FOOTER_HEIGHT } from "./constants/layout"
import type { PopupFooterProps } from "./props"

export default function PopupFooter({ multiple, selectAll, clearable, onSelectAll, onClear, onClose, countText, value, options, max }: PopupFooterProps) {
    const clearButton = (
        <Button onClick={onClear} sx={{ display: "flex" }} color="primary" disabled={!value || value?.length === 0}>
            Clear
        </Button>
    )

    if (multiple)
        return (
            <Grid
                container
                spacing={2}
                sx={{
                    width: "100%",
                    height: FOOTER_HEIGHT,
                    margin: 0,

                    "& > div": { padding: (theme) => theme.spacing(0, 1) + " !important" },
                }}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Typography
                        variant="button"
                        sx={{
                            display: "block",
                            marginLeft: (theme) => theme.spacing(1),

                            fontFeatureSettings: '"tnum"',
                            color: (theme) => theme.palette.text.disabled,
                            userSelect: "none",
                        }}
                    >
                        {countText ?? `${value.length} of ${options.length}${max ? ", max " + max : ""}`}
                    </Typography>
                </Grid>
                {selectAll ? (
                    <Grid item xs>
                        {value.length > 0 && clearable !== false ? (
                            clearButton
                        ) : (
                            <Button onClick={onSelectAll} sx={{ display: "flex" }} color="primary" disabled={value.length > 0}>
                                Select All
                            </Button>
                        )}
                    </Grid>
                ) : (
                    clearable !== false && (
                        <Grid item xs>
                            {clearButton}
                        </Grid>
                    )
                )}

                <Grid item>
                    <Button onClick={onClose} sx={{ display: "flex" }} color="primary">
                        Done
                    </Button>
                </Grid>
            </Grid>
        )

    if (clearable)
        return (
            <Grid
                container
                spacing={2}
                sx={{
                    width: "100%",
                    height: FOOTER_HEIGHT,
                    margin: 0,

                    "& > div": { padding: (theme) => theme.spacing(0, 1) + " !important" },
                }}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>{clearButton}</Grid>
            </Grid>
        )

    return null
}
