import { styled } from "@mui/material"
import { Link } from "react-router-dom"
export const StyledLink = styled(Link)({
    color: "inherit",
    ":hover": { color: "black" },
    ":visited": { color: "#985f13" },
})

export function TeacherHubLink(props: { teacher: { id: number; firstName: string; lastName: string } }) {
    if (!props.teacher) return <span>Non attribué</span>
    return (
        <StyledLink to={`/tuteurs/${props.teacher.id}`}>
            {props.teacher?.lastName?.toUpperCase() ?? "[nom]"} {props.teacher?.firstName ?? "[prénom]"}
        </StyledLink>
    )
}

export function FamilyHubLink(props: { member: { firstName: string; lastName: string }; familyId: number; pathend?: string }) {
    return (
        <StyledLink to={`/familles/${props.familyId}${props.pathend ? "/" + props.pathend : ""}`}>
            {props.member?.firstName ?? "[prénom]"} {props.member?.lastName.toUpperCase() ?? "[nom]"}
        </StyledLink>
    )
}
