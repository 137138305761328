import type { ApiErrors } from "core/services/swaggerClient"
import type { SnackbarKey } from "notistack"

export type ApiError<N extends keyof ApiErrors> = {
    name: N
    message: string
    error: true
    status: number
    stack: string
    cause?: any
} & (ApiErrors[N] extends null ? { data?: undefined } : { data: ApiErrors[N] })

export function isApiError<N extends keyof ApiErrors>(type: N, err: any): err is ApiError<N> {
    return err.name && err.name === type
}

export function logError(err: any) {
    if (isApiErrorLegacy(err)) {
        logApiError(err)
        return
    }

    const removedKeys = ["toJSON", "[[Prototype]]", "stack"]
    const message = err.message
    const stack: string = err.stack

    const messageStr = "[ERROR]   " + message + "\n\n"

    const infoObj = Object.keys(err).reduce((acc, key) => {
        //@ts-ignore
        if (!removedKeys.includes(String(key)) && err[key]) acc[key] = err[key]
        return acc
    }, {})

    const stackStr = "\n\n  STACK :\n" + stack.split("\n").slice(1).join("\n")

    console.error(messageStr, infoObj, stackStr)
}

function isApiErrorLegacy(err: any): boolean {
    if (!err) return false
    if (err.type && err.title && err.status) return true
}

function objectKeys<T extends object>(obj: T): Array<keyof T> {
    return Object.keys(obj) as any
}

function logApiError(err: ApiError<any>) {
    const messageStr = `[API ERROR] ${err.status} ${err.name} :\n${err.message}`

    const removedKeys = ["toJSON", "[[Prototype]]", "stack", "type", "name", "detail", "status", "title"]

    const infoObj = Object.keys(err).reduce((acc, key) => {
        //@ts-ignore
        if (!removedKeys.includes(key) && err[key]) acc[key] = err[key]
        return acc
    }, {})

    const stackStr = "\n\n  STACK :\n" + err.stack.split("\n").slice(1).join("\n")

    console.error(messageStr, infoObj, stackStr)
}

export class ClientError extends Error {
    constructor({ message, data, previous, type }: { message: string; type?: string; previous?: Error; data?: any }) {
        super()
        this.message = message
        this.type = type
        this.previous = previous
        this.data = data
    }

    type?: string
    previous?: Error & Record<string, any>
    data?: Record<string, any> | Array<any>

    snackbarId?: SnackbarKey

    toJSON() {
        if (this.previous && this.previous.stack && !Array.isArray(this.previous.stack)) this.previous.stackArr = this.previous.stack.split("\n").slice(1)
        return {
            error: true,

            type: this.getLast("type"),
            message: this.getLast("message"),

            data: this.getLast("data"),
            stack: this.getLast("stack"),
            previous: this.previous,
        }
    }

    private getLast(key: "stack" | "message" | "type" | "data") {
        const val = this[key] || this.previous?.[key] || (this.previous?.getLast && this.previous.getLast(key))
        return val
    }
}
