import React, { ElementType } from "react"

//type divPropsAllowedKeys = "id" | "className" | "key" | "onClick"
type divProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
type divPropsNoCollision = Omit<divProps, "translate" | "color" | "content"> //Partial<Pick<divProps, divPropsAllowedKeys>>

export interface BxProps extends React.CSSProperties, divPropsNoCollision {
    children?: React.ReactNode
    component?: ElementType
}

export const Bx = React.forwardRef(function (props: BxProps, ref: any) {
    const El = props.component ?? "div"
    const style = {},
        rest = {}
    for (const key in props) {
        //@ts-ignore
        if (CSSkeys.includes(key)) {
            //@ts-ignore
            style[key] = props[key]
        } else {
            //@ts-ignore
            rest[key] = props[key]
        }
    }

    return <El ref={ref} style={style} {...rest} />
})

export const CSSkeys = [
    "accentColor",
    "alignContent",
    "alignItems",
    "alignSelf",
    "alignmentBaseline",
    "all",
    "animation",
    "animationDelay",
    "animationDirection",
    "animationDuration",
    "animationFillMode",
    "animationIterationCount",
    "animationName",
    "animationPlayState",
    "animationTimingFunction",
    "appearance",
    "aspectRatio",
    "azimuth",
    "backfaceVisibility",
    "background",
    "backgroundAttachment",
    "backgroundBlendMode",
    "backgroundClip",
    "backgroundColor",
    "backgroundImage",
    "backgroundOrigin",
    "backgroundPosition",
    "backgroundRepeat",
    "backgroundSize",
    "baselineShift",
    "baselineSource",
    "blockEllipsis",
    "blockSize",
    "blockStep",
    "blockStepAlign",
    "blockStepInsert",
    "blockStepRound",
    "blockStepSize",
    "bookmarkLabel",
    "bookmarkLevel",
    "bookmarkState",
    "border",
    "borderBlock",
    "borderBlockColor",
    "borderBlockEnd",
    "borderBlockEndColor",
    "borderBlockEndStyle",
    "borderBlockEndWidth",
    "borderBlockStart",
    "borderBlockStartColor",
    "borderBlockStartStyle",
    "borderBlockStartWidth",
    "borderBlockStyle",
    "borderBlockWidth",
    "borderBottom",
    "borderBottomColor",
    "borderBottomLeftRadius",
    "borderBottomRightRadius",
    "borderBottomStyle",
    "borderBottomWidth",
    "borderBoundary",
    "borderCollapse",
    "borderColor",
    "borderEndEndRadius",
    "borderEndStartRadius",
    "borderImage",
    "borderImageOutset",
    "borderImageRepeat",
    "borderImageSlice",
    "borderImageSource",
    "borderImageWidth",
    "borderInline",
    "borderInlineColor",
    "borderInlineEnd",
    "borderInlineEndColor",
    "borderInlineEndStyle",
    "borderInlineEndWidth",
    "borderInlineStart",
    "borderInlineStartColor",
    "borderInlineStartStyle",
    "borderInlineStartWidth",
    "borderInlineStyle",
    "borderInlineWidth",
    "borderLeft",
    "borderLeftColor",
    "borderLeftStyle",
    "borderLeftWidth",
    "borderRadius",
    "borderRight",
    "borderRightColor",
    "borderRightStyle",
    "borderRightWidth",
    "borderSpacing",
    "borderStartEndRadius",
    "borderStartStartRadius",
    "borderStyle",
    "borderTop",
    "borderTopColor",
    "borderTopLeftRadius",
    "borderTopRightRadius",
    "borderTopStyle",
    "borderTopWidth",
    "borderWidth",
    "bottom",
    "boxDecorationBreak",
    "boxShadow",
    "boxSizing",
    "boxSnap",
    "breakAfter",
    "breakBefore",
    "breakInside",
    "captionSide",
    "caret",
    "caretColor",
    "caretShape",
    "chains",
    //"className",
    "clear",
    "clip",
    "clipPath",
    "clipRule",
    "color",
    "colorAdjust",
    "colorInterpolationFilters",
    "colorScheme",
    "columnCount",
    "columnFill",
    "columnGap",
    "columnRule",
    "columnRuleColor",
    "columnRuleStyle",
    "columnRuleWidth",
    "columnSpan",
    "columnWidth",
    "columns",
    "contain",
    "containIntrinsicBlockSize",
    "containIntrinsicHeight",
    "containIntrinsicInlineSize",
    "containIntrinsicSize",
    "containIntrinsicWidth",
    "content",
    "contentVisibility",
    "continue",
    "counterIncrement",
    "counterReset",
    "counterSet",
    "cue",
    "cueAfter",
    "cueBefore",
    "cursor",
    "direction",
    "display",
    "dominantBaseline",
    "elevation",
    "emptyCells",
    "fill",
    "fillBreak",
    "fillColor",
    "fillImage",
    "fillOpacity",
    "fillOrigin",
    "fillPosition",
    "fillRepeat",
    "fillRule",
    "fillSize",
    "filter",
    "flex",
    "flexBasis",
    "flexDirection",
    "flexFlow",
    "flexGrow",
    "flexShrink",
    "flexWrap",
    "float",
    "floatDefer",
    "floatOffset",
    "floatReference",
    "floodColor",
    "floodOpacity",
    "flow",
    "flowFrom",
    "flowInto",
    "font",
    "fontFamily",
    "fontFeatureSettings",
    "fontKerning",
    "fontLanguageOverride",
    "fontOpticalSizing",
    "fontPalette",
    "fontSize",
    "fontSizeAdjust",
    "fontStretch",
    "fontStyle",
    "fontSynthesis",
    "fontSynthesisSmallCaps",
    "fontSynthesisStyle",
    "fontSynthesisWeight",
    "fontVariant",
    "fontVariantAlternates",
    "fontVariantCaps",
    "fontVariantEastAsian",
    "fontVariantEmoji",
    "fontVariantLigatures",
    "fontVariantNumeric",
    "fontVariantPosition",
    "fontVariationSettings",
    "fontWeight",
    "footnoteDisplay",
    "footnotePolicy",
    "forcedColorAdjust",
    "gap",
    "glyphOrientationVertical",
    "grid",
    "gridArea",
    "gridAutoColumns",
    "gridAutoFlow",
    "gridAutoRows",
    "gridColumn",
    "gridColumnEnd",
    "gridColumnStart",
    "gridRow",
    "gridRowEnd",
    "gridRowStart",
    "gridTemplate",
    "gridTemplateAreas",
    "gridTemplateColumns",
    "gridTemplateRows",
    "hangingPunctuation",
    "height",
    "hyphenateCharacter",
    "hyphenateLimitChars",
    "hyphenateLimitLast",
    "hyphenateLimitLines",
    "hyphenateLimitZone",
    "hyphens",
    "imageOrientation",
    "imageRendering",
    "imageResolution",
    "initialLetter",
    "initialLetterAlign",
    "initialLetterWrap",
    "inlineSize",
    "inlineSizing",
    "inset",
    "insetBlock",
    "insetBlockEnd",
    "insetBlockStart",
    "insetInline",
    "insetInlineEnd",
    "insetInlineStart",
    "isolation",
    "justifyContent",
    "justifyItems",
    "justifySelf",
    "leadingTrim",
    "left",
    "letterSpacing",
    "lightingColor",
    "lineBreak",
    "lineClamp",
    "lineGrid",
    "lineHeight",
    "lineHeightStep",
    "linePadding",
    "lineSnap",
    "listStyle",
    "listStyleImage",
    "listStylePosition",
    "listStyleType",
    "margin",
    "marginBlock",
    "marginBlockEnd",
    "marginBlockStart",
    "marginBottom",
    "marginBreak",
    "marginInline",
    "marginInlineEnd",
    "marginInlineStart",
    "marginLeft",
    "marginRight",
    "marginTop",
    "marginTrim",
    "marker",
    "markerEnd",
    "markerKnockoutLeft",
    "markerKnockoutRight",
    "markerMid",
    "markerPattern",
    "markerSegment",
    "markerSide",
    "markerStart",
    "mask",
    "maskBorder",
    "maskBorderMode",
    "maskBorderOutset",
    "maskBorderRepeat",
    "maskBorderSlice",
    "maskBorderSource",
    "maskBorderWidth",
    "maskClip",
    "maskComposite",
    "maskImage",
    "maskMode",
    "maskOrigin",
    "maskPosition",
    "maskRepeat",
    "maskSize",
    "maskType",
    "maxBlockSize",
    "maxHeight",
    "maxInlineSize",
    "maxLines",
    "maxWidth",
    "minBlockSize",
    "minHeight",
    "minInlineSize",
    "minIntrinsicSizing",
    "minWidth",
    "mixBlendMode",
    "navDown",
    "navLeft",
    "navRight",
    "navUp",
    "objectFit",
    "objectPosition",
    "offset",
    "offsetAnchor",
    "offsetDistance",
    "offsetPath",
    "offsetPosition",
    "offsetRotate",
    "opacity",
    "order",
    "orphans",
    "outline",
    "outlineColor",
    "outlineOffset",
    "outlineStyle",
    "outlineWidth",
    "overflow",
    "overflowAnchor",
    "overflowBlock",
    "overflowClipMargin",
    "overflowInline",
    "overflowWrap",
    "overflowX",
    "overflowY",
    "overscrollBehavior",
    "overscrollBehaviorBlock",
    "overscrollBehaviorInline",
    "overscrollBehaviorX",
    "overscrollBehaviorY",
    "padding",
    "paddingBlock",
    "paddingBlockEnd",
    "paddingBlockStart",
    "paddingBottom",
    "paddingInline",
    "paddingInlineEnd",
    "paddingInlineStart",
    "paddingLeft",
    "paddingRight",
    "paddingTop",
    "page",
    "pageBreakAfter",
    "pageBreakBefore",
    "pageBreakInside",
    "pause",
    "pauseAfter",
    "pauseBefore",
    "perspective",
    "perspectiveOrigin",
    "pitch",
    "pitchRange",
    "placeContent",
    "placeItems",
    "placeSelf",
    "playDuring",
    "position",
    "printColorAdjust",
    "quotes",
    "regionFragment",
    "resize",
    "rest",
    "restAfter",
    "restBefore",
    "richness",
    "right",
    "rotate",
    "rowGap",
    "rubyAlign",
    "rubyMerge",
    "rubyOverhang",
    "rubyPosition",
    "running",
    "scale",
    "scrollBehavior",
    "scrollMargin",
    "scrollMarginBlock",
    "scrollMarginBlockEnd",
    "scrollMarginBlockStart",
    "scrollMarginBottom",
    "scrollMarginInline",
    "scrollMarginInlineEnd",
    "scrollMarginInlineStart",
    "scrollMarginLeft",
    "scrollMarginRight",
    "scrollMarginTop",
    "scrollPadding",
    "scrollPaddingBlock",
    "scrollPaddingBlockEnd",
    "scrollPaddingBlockStart",
    "scrollPaddingBottom",
    "scrollPaddingInline",
    "scrollPaddingInlineEnd",
    "scrollPaddingInlineStart",
    "scrollPaddingLeft",
    "scrollPaddingRight",
    "scrollPaddingTop",
    "scrollSnapAlign",
    "scrollSnapStop",
    "scrollSnapType",
    "scrollbarColor",
    "scrollbarGutter",
    "scrollbarWidth",
    "shapeImageThreshold",
    "shapeInside",
    "shapeMargin",
    "shapeOutside",
    "spatialNavigationAction",
    "spatialNavigationContain",
    "spatialNavigationFunction",
    "speak",
    "speakAs",
    "speakHeader",
    "speakNumeral",
    "speakPunctuation",
    "speechRate",
    "stress",
    "stringSet",
    "stroke",
    "strokeAlign",
    "strokeAlignment",
    "strokeBreak",
    "strokeColor",
    "strokeDashCorner",
    "strokeDashJustify",
    "strokeDashadjust",
    "strokeDasharray",
    "strokeDashcorner",
    "strokeDashoffset",
    "strokeImage",
    "strokeLinecap",
    "strokeLinejoin",
    "strokeMiterlimit",
    "strokeOpacity",
    "strokeOrigin",
    "strokePosition",
    "strokeRepeat",
    "strokeSize",
    "strokeWidth",
    "tabSize",
    "tableLayout",
    "textAlign",
    "textAlignAll",
    "textAlignLast",
    "textCombineUpright",
    "textDecoration",
    "textDecorationColor",
    "textDecorationLine",
    "textDecorationSkip",
    "textDecorationSkipBox",
    "textDecorationSkipInk",
    "textDecorationSkipInset",
    "textDecorationSkipSelf",
    "textDecorationSkipSpaces",
    "textDecorationStyle",
    "textDecorationThickness",
    "textEdge",
    "textEmphasis",
    "textEmphasisColor",
    "textEmphasisPosition",
    "textEmphasisSkip",
    "textEmphasisStyle",
    "textGroupAlign",
    "textIndent",
    "textJustify",
    "textOrientation",
    "textOverflow",
    "textShadow",
    "textSpaceCollapse",
    "textSpaceTrim",
    "textSpacing",
    "textTransform",
    "textUnderlineOffset",
    "textUnderlinePosition",
    "textWrap",
    "top",
    "transform",
    "transformBox",
    "transformOrigin",
    "transformStyle",
    "transition",
    "transitionDelay",
    "transitionDuration",
    "transitionProperty",
    "transitionTimingFunction",
    "translate",
    "unicodeBidi",
    "userSelect",
    "verticalAlign",
    "visibility",
    "voiceBalance",
    "voiceDuration",
    "voiceFamily",
    "voicePitch",
    "voiceRange",
    "voiceRate",
    "voiceStress",
    "voiceVolume",
    "volume",
    "whiteSpace",
    "widows",
    "width",
    "willChange",
    "wordBoundaryDetection",
    "wordBoundaryExpansion",
    "wordBreak",
    "wordSpacing",
    "wordWrap",
    "wrapAfter",
    "wrapBefore",
    "wrapFlow",
    "wrapInside",
    "wrapThrough",
    "writingMode",
    "zIndex",
] as const
