import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import { Box, SvgIconTypeMap } from "@mui/material"

type IconProps = SvgIconTypeMap<{}, "svg">["props"]

export function NotDoneIcon(props: IconProps) {
    return <CancelIcon color="error" {...props} />
}

export function DoneIcon(props: IconProps) {
    return <CheckCircleRoundedIcon color="success" {...props} />
}

export function TodoIcon({ done, warn, ...other }: { done: boolean; warn?: boolean } & IconProps) {
    return (
        <Box position="relative" display="flex" alignItems="center">
            {warn && (
                <Box position="absolute" top="-9px" right="-9px">
                    ⚠️
                </Box>
            )}{" "}
            {done ? <DoneIcon {...other} /> : <NotDoneIcon {...other} />}
        </Box>
    )
}
