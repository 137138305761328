import { Slider, SliderProps, Typography } from "@mui/material"
import { withGrid, WithGridProps } from "core/misc"
import { isNil } from "lodash"

export type RawSliderProps = WithGridProps<
    Omit<SliderProps, "onChange"> & {
        necessary?: boolean
        helperText?: string
        error?: boolean
        missing?: boolean
        onChange?: (value: number | number[], activeThumb: number) => any
    }
>

export const RawSlider = withGrid((props: RawSliderProps) => {
    let { necessary, helperText, error, missing, onChange, ...otherProps } = props

    const isMissing = missing ?? (necessary && !error && isNil(props.value))
    if (isMissing) helperText = helperText ? `${helperText} - ⚠️ Nécessaire` : "⚠️ Nécessaire"

    return (
        <>
            <Slider
                {...otherProps}
                onChange={(event, value, activeThumb) => {
                    onChange(value, activeThumb)
                }}
            />
            {helperText && <Typography sx={{ ...(isMissing && { color: (theme) => theme.palette.warning.main }), ...(error && { color: (theme) => theme.palette.error.main }) }}>{helperText}</Typography>}
        </>
    )
})
