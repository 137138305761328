import type { MaterialTableCustomColumn } from "core/misc"
import { EzModel } from "core/store/base"
import type { AuthCtx } from "core/technic/auth/auth-context"
import { convertArrayToDictionnary } from "core/utils"
import { Action, Model, Property, Thunk } from "easy-peasy-classes"

interface rowAction<RowData> {
    icon: JSX.Element
    onClick: (event: Event, row: RowData) => void
    tooltip: string
}

@Model
export class commonTableCrudModel<RowData extends object> extends EzModel {
    @Property
    isLoaded: boolean = false

    @Property
    isLoading: boolean = false

    @Property
    columnDefsDict: Record<string, MaterialTableCustomColumn> = {}

    @Property
    data: RowData[] = []

    @Property
    rowActions: rowAction<RowData>[] = []

    @Property
    tableActions: any[] = []

    @Property
    grouping: boolean = false

    @Property
    isLocked: boolean = false

    @Action
    startSpinner() {
        this.isLoading = true
    }

    @Action
    stopSpinner() {
        this.isLoading = false
    }

    @Thunk
    setLookup<Obj extends object>(payload: { col: string; dict?: Record<string, any>; list?: string[]; objects?: Obj[]; objectKey?: keyof Obj | ((obj: Obj) => string); objectField?: keyof Obj | ((obj: Obj) => string) }) {
        const { col, dict, list, objects, objectKey = "id", objectField } = payload

        let finalDict: any
        if (dict) finalDict = dict
        else if (list)
            finalDict = list.reduce((acc, key) => {
                acc[key] = key
                return acc
            }, {})
        else if (objects) finalDict = convertArrayToDictionnary(objects, objectKey as any, objectField as any)
        else finalDict = {}

        this.mergeColumn({ col: col, def: { lookup: finalDict } })
    }

    @Action
    private mergeColumn(payload: { col: string; def: Partial<MaterialTableCustomColumn> }) {
        const { col, def } = payload
        if (!this.columnDefsDict[col]) this.columnDefsDict[col] = {} as MaterialTableCustomColumn
        Object.assign(this.columnDefsDict[col], def)
    }

    @Thunk
    /** columnIdentifier : column.field or id  */
    setCustomLookup(payload: { columnIdentifier: string; lookupData: any[] }) {
        const { columnIdentifier, lookupData } = payload

        this.setStateFn((s) => {
            if (!s.columnDefsDict[columnIdentifier]) s.columnDefsDict[columnIdentifier] = {} as any
            if (!s.columnDefsDict[columnIdentifier].lookup) s.columnDefsDict[columnIdentifier].lookup = {} as any

            s.columnDefsDict[columnIdentifier].customLookup.lookupData = lookupData
        })
    }

    @Action
    setData(data: RowData[]) {
        this.data = data
    }

    @Action
    setIsLoaded() {
        if (!this.isLoaded) this.isLoaded = true
    }

    @Action
    replaceItemState({ item, lookupKey = "id" as keyof RowData }: { item: RowData; lookupKey?: keyof RowData }) {
        this.data.splice(
            this.data.findIndex((i) => i[lookupKey] === item[lookupKey]),
            1,
            item
        )
    }

    @Thunk
    handleRefresh({ auth }: { auth: AuthCtx }) {
        alert("no handleRefresh action has been defined in the holder model")
    }

    @Thunk
    handleRowAdd(newRow: any) {
        alert("no handleRowAdd action has been defined in the holder model")
    }

    @Thunk
    handleRowEdit(newRow: any) {
        alert("no handleRowEdit action has been defined in the holder model")
    }

    @Thunk
    handleRowDelete(row: any) {
        alert("no handleRowDelete action has been defined in the holder model")
    }

    @Action
    fireEvent({ eventName, payload }: { eventName: string; payload: any }) {
        //@ts-ignore
        this[eventName] !== undefined && this[eventName](payload)
    }
}
