import { withGrid, WithGridProps } from "core/misc"
import { RawRadioGroup, RawRadioGroupProps } from "core/rawFields"
import { FastField, FastFieldProps, useField, useFormikContext } from "formik"

export interface FmkFastRadioGroupProps<OptionData extends object, M extends boolean> extends Omit<RawRadioGroupProps<OptionData, M>, "value" | "onChange" | "error"> {
    name: string
    validateImmediately?: boolean
}

const FmkFastRadioGroupUntyped = withGrid(<OptionData extends object, M extends boolean>(props: FmkFastRadioGroupProps<OptionData, M>) => {
    const { name, validateImmediately, ...otherProps } = props

    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps<string>) => {
                const handleChange = (newValue: any, optionData: any) => {
                    form.setFieldTouched(name, true, false)
                    form.setFieldValue(name, newValue, true)
                }

                const defaultProps: Partial<RawRadioGroupProps<OptionData, M>> = {
                    size: "small",
                    onChange: handleChange as any,
                }

                const config = {
                    ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
                    ...defaultProps,
                    ...otherProps,
                }
                if (meta && (meta.touched || validateImmediately) && meta.error) {
                    config.error = true
                    config.helperText = meta.error
                }

                config.disabled = config.disabled ?? form.isSubmitting

                return <RawRadioGroup {...config} />
            }}
        </FastField>
    )
})

export function FmkFastRadioGroup<OptionData extends object, M extends boolean>(props: FmkFastRadioGroupProps<OptionData, M> & WithGridProps<{}>) {
    return (<FmkFastRadioGroupUntyped {...props} />) as any
}

// import { TextFieldProps, TextField, Grid, alertTitleClasses } from '@mui/material'
// import { withGrid } from 'core/misc'
// import { useField, useFormikContext } from 'formik'
// import React from 'react'

// export interface FmkTextFieldProps extends Omit<TextFieldProps, 'value' | 'error'> { name: string}

// export const FmkTextField = withGrid((props: FmkTextFieldProps) => {

//     const { name,...otherProps } = props
//     const [field, meta] = useField<string>(name)
//     const { isSubmitting } = useFormikContext()

//     const defaultProps: TextFieldProps = {
//         fullWidth: true,
//         variant: 'outlined',
//         size:'small'
//     }

//     const config = {
//         ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
//         ...defaultProps,
//         ...otherProps,
//     }

//     if (meta && meta.touched && meta.error) {
//         config.error = true
//         config.helperText = meta.error
//     }

//     config.disabled = config.disabled ?? isSubmitting

//     return (<TextField {...config} />)

// })
