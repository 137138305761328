import { withGrid, WithGridProps } from "core/misc"
import { RawComboBox, RawComboBoxProps } from "core/rawFields"
import { FastField, FastFieldProps } from "formik"

export interface FmkFastComboBoxProps<OptionData, Multiple extends boolean> extends Omit<RawComboBoxProps<OptionData, Multiple>, "value" | "onChange" | "onBlur" | "error"> {
    name: string
    validateImmediately?: boolean
    multiple?: Multiple
    options: Array<OptionData>
}

const FmkFastComboBoxUntyped = withGrid(<OptionData, Multiple extends boolean>(props: FmkFastComboBoxProps<OptionData, Multiple>) => {
    const { name, validateImmediately, ...otherProps } = props

    return (
        <FastField name={name}>
            {({ field, form, meta }: FastFieldProps<any>) => {
                const handleChange = (newValue: any | Array<any>, optionData: any) => {
                    form.setFieldTouched(name, true, false)
                    form.setFieldValue(name, newValue, true)
                }

                const defaultProps: Partial<RawComboBoxProps<OptionData, Multiple>> = {
                    size: "small",
                    onChange: handleChange as any,
                }

                const config = {
                    ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
                    ...defaultProps,
                    ...otherProps,
                }
                if (meta && (meta.touched || validateImmediately) && meta.error) {
                    config.error = true
                    config.helperText = meta.error
                }

                config.disabled = config.disabled ?? form.isSubmitting
                config.onBlur = () => {
                    form.setFieldTouched(name, true, true)
                }

                return <RawComboBox {...config} />
            }}
        </FastField>
    )
})

export function FmkFastComboBox<OptionData, Multiple extends boolean>(props: FmkFastComboBoxProps<OptionData, Multiple> & WithGridProps<{}>) {
    return (<FmkFastComboBoxUntyped {...props} />) as any
}
