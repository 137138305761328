import { useLocation, useNavigate } from "react-router-dom"

type RedirectFn = (to: string, options?: { payload?: any; from?: string; newTab?: boolean }) => void

export function useRedirect(): RedirectFn {
    const location = useLocation()
    const navigate = useNavigate()

    const redirect: RedirectFn = (to, { payload, from, newTab } = {}) => {
        if (newTab) {
            const win = window.open(to, "_blank")
            win.focus()
        } else navigate(to, { state: { from: from || location, payload } })
    }

    return redirect
}
