import MaterialTable, { Column as StdColumn, MaterialTableProps as StdMaterialTableProps, MTableCell, MTableEditField } from "@material-table/core"
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material"
import dottie from "dottie"
import React, { useEffect, useMemo } from "react"
import { useState } from "react"

type MTableFieldProps = {
    /** The raw value (may be an object or array) */
    value: any
    rowData: any
    columnDef: MaterialTableCustomColumn

    onChange: (selectedKey: any) => void
    onRowDataChange: (...params: any[]) => void

    error: boolean
    locale?: string
    helperText: string
}

export type CustomLookup<LookupRowData extends object> = {
    lookupData?: LookupRowData[]
    transformExport?: (selectedLookupRow: LookupRowData) => any
    getOptionLabel: (lookupRow: LookupRowData) => string
    getOptionKey: (lookupRow: LookupRowData) => string | number
    none?: boolean
    noneLabel?: string
}

export interface MaterialTableCustomColumn<RowData extends object = Record<string, any>> extends StdColumn<RowData> {
    customLookup?: CustomLookup<any>
    lookup?: Record<string, string | number>
    lookupNone?: boolean
    lookupNoneLabel?: string
}

export interface MaterialTableCustomProps<RowData extends object> extends Omit<StdMaterialTableProps<RowData>, "columns"> {
    columns: MaterialTableCustomColumn<RowData>[]
}

/**
 * @material-table/core exportFunc have only partial cols definition and data is only an array of rendered data, not the original data.
 * The purpose of this component is to pass all columns and data to the ExportClipboard function
 */

/**
 * Other purpose is to have customLookup column prop (to have control on what's rendered, to work on array of objects instead of dict) but it implies overriding the Cell, Editcell and Filter component and maybe others) - not finished
 */

export function MaterialTableCustom<RowData extends object = Record<string, any>>(props: MaterialTableCustomProps<RowData>) {
    // const [columns] = useState(props.columns)
    const columns = props.columns

    // useEffect(()=>setColumns(props.columns),[!!columns])
    if (props.options?.exportMenu)
        props.options.exportMenu = props.options?.exportMenu?.map((exprtDef) => ({
            label: exprtDef.label,
            exportFunc: () => exprtDef.exportFunc(props.columns, props.data as RowData[], undefined as any),
        }))

    return (
        <MaterialTable
            {...props}
            // columns={columns}

            components={{
                EditField: React.forwardRef((fieldProps: MTableFieldProps, ref) => {
                    fieldProps.columnDef.customLookup && setCustomLookupDefaults(fieldProps.columnDef.customLookup)

                    if (fieldProps.columnDef.lookup) {
                        return <MTableEditField_Lookup {...fieldProps} forwardedRef={ref} />
                    } else if (fieldProps.columnDef.customLookup) {
                        return <MTableEditField_CustomLookup {...fieldProps} forwardedRef={ref} />
                    } else {
                        return <MTableEditField {...fieldProps} ref={ref} /*{...{ ...fieldProps, value: fieldProps.value || '' }}*/ />
                    }
                }),

                Cell: React.forwardRef((fieldProps: MTableFieldProps, ref) => {
                    fieldProps.columnDef.customLookup && setCustomLookupDefaults(fieldProps.columnDef.customLookup)

                    if (fieldProps.columnDef.customLookup && fieldProps.rowData) {
                        return <MTableCell_CustomLookup {...fieldProps} />
                    } else {
                        return <MTableCell {...fieldProps} ref={ref} /*{...{ ...fieldProps, value: fieldProps.value || '' }}*/ />
                    }
                }),
            }}
        />
    )
}

function MTableEditField_Lookup({ forwardedRef, ...props }: MTableFieldProps & { forwardedRef: any }) {
    return (
        <FormControl ref={forwardedRef} error={Boolean(props.error)} fullWidth>
            <Select
                {...props}
                value={props.value === undefined ? "" : props.value}
                onChange={(event) => props.onChange(event.target.value)}
                style={{
                    fontSize: 12,
                }}
                SelectDisplayProps={{}}
            >
                {props.columnDef.lookupNone && (
                    <MenuItem value={null}>
                        <em>{props.columnDef.lookupNoneLabel || "Vide"}</em>
                    </MenuItem>
                )}
                {Object.keys(props.columnDef.lookup).map((key) => (
                    <MenuItem key={key} value={key}>
                        {props.columnDef.lookup[key]}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(props.helperText) && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
    )
}

function MTableEditField_CustomLookup(fieldProps: MTableFieldProps & { forwardedRef: any }) {
    const columnDef: MaterialTableCustomColumn = fieldProps.columnDef
    const lookup = columnDef.customLookup

    const fieldValue = fieldProps.value //object or string or number ...
    const selectedIndex = lookup.lookupData!.findIndex((option) => {
        return lookup.getOptionKey(option) === fieldValue
    })
    return (
        <FormControl error={Boolean(fieldProps.error)} ref={fieldProps.forwardedRef} fullWidth>
            <Select
                ref={fieldProps.forwardedRef}
                value={selectedIndex === -1 ? "" : String(selectedIndex)}
                onChange={(event) => {
                    const newIndex = event.target.value
                    const none = newIndex === ""

                    if (none && lookup.none) {
                        fieldProps.onChange(null)
                        return
                    }

                    const newOption = lookup.lookupData[Number(newIndex)]
                    fieldProps.onChange(lookup.transformExport(lookup.getOptionKey(newOption)))
                }}
                style={{
                    fontSize: 12,
                }}
                SelectDisplayProps={{ "aria-label": fieldProps.columnDef.title as string }}
            >
                {lookup.none && (
                    <MenuItem value={""}>
                        <em>{lookup.noneLabel || "Vide"}</em>
                    </MenuItem>
                )}
                {lookup.lookupData.map((option, index) => (
                    <MenuItem key={index} value={index}>
                        {lookup.getOptionLabel(option)}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(fieldProps.helperText) && <FormHelperText>{fieldProps.helperText}</FormHelperText>}
        </FormControl>
    )

    //   return <Autocomplete

    //   value={fieldValue}
    //   options={lookup.lookupData}
    //   getOptionLabel={lookupRow=>lookup.getOptionLabel(lookupRow)}
    //   getOptionSelected={lookup.getOptionSelected}

    //   onChange={(e,selectedKey)=>{fieldProps.onChange(()=>lookup.transformExport(selectedKey))}} //on devrait aussi provider la row
    //   renderInput={(params) => <TextField {...params} value={} />}
    //   />
}

function MTableCell_CustomLookup({ value, ...fieldProps }: MTableFieldProps) {
    const columnDef: MaterialTableCustomColumn = fieldProps.columnDef
    const lookup = columnDef.customLookup

    const fieldValue = dottie.get(fieldProps.rowData, columnDef.field as string) ?? null

    const optionRow = lookup.lookupData!.find((lookupRow) => lookup.getOptionKey(lookupRow) === fieldValue)

    let labelValue: string

    if (!optionRow && fieldValue && lookup.lookupData.length) {
        labelValue = "Non trouvé !"
        console.error("MaterialTableCustom : option for value ", fieldValue, "not found in column " + columnDef.field)
    } else {
        labelValue = optionRow ? lookup.getOptionLabel(optionRow) : ""
    }

    return <MTableCell {...fieldProps} value={labelValue} />
}

function setCustomLookupDefaults(customLookup: CustomLookup<any>) {
    if (!customLookup) return

    if (!customLookup.lookupData) customLookup.lookupData = []
    // if(!lookup.getOptionLabel)  lookup.getOptionLabel = (lookupRow)=>lookupRow.label
    if (!customLookup.getOptionKey) customLookup.getOptionKey = (lookupRow) => lookupRow.id
    if (!customLookup.transformExport) customLookup.transformExport = (selectedOptionRow) => selectedOptionRow
}
// return (
//     <MaterialTable
//         {...props}
//         columns={columns}

//         components={{
//             EditField: (fieldProps:MTableFieldProps) => {
//                 const columnDef: MaterialTableCustomColumn<RowData> = fieldProps.columnDef
//                 const lookup = columnDef.lookup
//                 alert(fieldProps)
//                 if (lookup) {

//                   const fieldValue = fieldProps.value //object or string or number ...
//                   const rowData =  fieldProps.rowData

//                   console.info(fieldProps)
//                   return <Autocomplete

//                   value={lookup.getValueKey(fieldValue,rowData, columnDef)}
//                   options={lookup.lookupData}
//                   getOptionLabel={lookupRow=>lookup.getOptionLabel(lookupRow)}
//                   getOptionSelected={(option,selectedKey)=>lookup.getOptionKey(option) === selectedKey}

//                   onChange={(e,selectedKey)=>{fieldProps.onChange(()=>lookup.transformExport(selectedKey))}} //on devrait aussi provider la row
//                   renderInput={(params) => <TextField {...params} />}
//                   />
//                 } else {
//                   return <MTableEditField {...fieldProps}  /*{...{ ...fieldProps, value: fieldProps.value || '' }}*/ />
//                 }
//               },
//         }}
//     />
// )

export function useMaterialTableColumns<TRowData extends object>(factory: () => MaterialTableCustomColumn<TRowData>[], deps: React.DependencyList): MaterialTableCustomColumn<TRowData>[] {
    return useMemo(factory, deps)
}

export function cleanMaterialTableRow<T extends object>(row: T) {
    const { tableData, ...cleaned } = row as any
    return cleaned as T
}
