import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { createLocalHooks } from "core/store"

import type { commonComboBoxModel } from "./comboBox.model"

interface ComboBox$Props extends Partial<AutocompleteProps<any, any, any, any>> {
    id?: string
    mapper: (s: any) => any
    label?: string
    variant?: "standard" | "filled" | "outlined" | undefined
}
export function ComboBox$(props: ComboBox$Props) {
    const { id, mapper: µ, freeSolo, fullWidth, size, ...rest } = props
    const { useLocalState, useLocalActions } = createLocalHooks<commonComboBoxModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    return (
        <Autocomplete
            {...{ freeSolo, fullWidth }}
            style={{ width: "100%" }}
            id={id}
            options={props.options || unitState.rows}
            getOptionLabel={(option: any) => option[unitState.labelKey] || ""}
            isOptionEqualToValue={(option: any, value) => option && value && option[unitState.lookupKey] === value[unitState.lookupKey]}
            //style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} {...(rest as any)} label={props.label || unitState.label} helperText={unitState.helperText} error={unitState.isError} />}
            autoHighlight
            value={unitState.value}
            onChange={(e, newValue) => unitActions.handleChange(newValue)}
            inputValue={unitState.inputValue}
            onInputChange={(e, newValue) => unitActions.handleInputChange(newValue)} //{(e, newInputValue) =>unitActions.handleInputChange(newInputValue)}
            size={"small" || size}
            disabled={unitState.isDisabled}
            freeSolo={freeSolo}
        />
    )
}
