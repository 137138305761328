import { styled } from "@mui/material/styles"
import type { ReactNode, SVGProps } from "react"
import { Link, LinkProps } from "react-router-dom"

const StyledIconLink = styled(Link)({
    color: "inherit",
    fontSize: 0,
    ":hover": { color: "inherit" },
    ":visited": { color: "inherit" },
})

export interface IconLinkProps extends LinkProps {
    children: ReactNode
}
export function IconLink(props: IconLinkProps) {
    const { children, ...linkProps } = props

    return <StyledIconLink {...linkProps}>{children}</StyledIconLink>
}
