import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { Avatar, Box, Button, Card, Container, Link, styled, TextField, Typography } from "@mui/material/"
import { useCatchToSnackbar, useErrorHandler, useRedirect } from "core/hooks"
import { openapi } from "core/services/openapi"
import { useAuthContext } from "core/technic/auth"
import { isApiError } from "core/utils"
import { useSnackbar } from "notistack"
import React, { FormEvent, useCallback, useContext, useState } from "react"
//import {store} from 'core/store';
import { useLocation, useSearchParams } from "react-router-dom"

const FormDiv = styled("div")(({ theme }) => ({
    width: "100%", // Fixes IE 11 issue.
    marginTop: theme.spacing(1),
}))

const FormPaper = styled("form")(({ theme }) => ({
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}))

function useRedirectToPreviousOrHome() {
    const redirect = useRedirect()
    const [searchParams] = useSearchParams()

    const redirectToPreviousOrHome = () => {
        const next: string | null = searchParams.get("next")
        if (next && next !== "/login") {
            redirect(next)
        } else {
            redirect("/")
        }
    }

    return { redirectToPreviousOrHome }
}

export function LoginCard() {
    type StateType = {
        email: string
        password: string
    }

    const [state, setState] = useState<StateType>({ email: "", password: "" })
    const auth = useAuthContext()
    const { redirectToPreviousOrHome } = useRedirectToPreviousOrHome()
    const location = useLocation()

    const { enqueueSnackbar } = useSnackbar()

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { id, value } = event.target
            setState((prevState) => {
                return { ...prevState, [id]: value }
            })
        },
        [setState]
    )

    const handleSubmit = useCatchToSnackbar(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (!state.email || !state.password) {
            enqueueSnackbar("Veuillez saisir tous les champs", { variant: "warning" })
            return
        }

        try {
            // eslint-disable-next-line pedagome/ensure-valid-token-typescript
            const token = await openapi.agents.signin({ email: state.email, password: state.password })
            auth.setToken(token)

            //if the login page is displayed as the page /login, we redirect to previous page (or home)
            //if it is ispayed as a modal before an api call, we don't redirect
            if (token && location.pathname === "/login") {
                redirectToPreviousOrHome()
            }
            //open as dialog
            else if (token && location.pathname !== "/login") {
                auth.hideLoginDialog()
            }
        } catch (err) {
            if (isApiError("UnknownUser", err)) {
                enqueueSnackbar(`Nom d'utilisateur ${state.email} inconnu`, { variant: "warning" })
            } else if (isApiError("InvalidPassword", err)) {
                enqueueSnackbar(`Mot de passe incorrect`, { variant: "warning" })
            } else {
                throw err
            }
        }
    })

    //style={{transform:"scale(calc("  + ((window.innerHeight < 1000) ? (window.innerHeight / 1000) : 1) + "))"
    return (
        <Card style={{ transform: "scale(0.7) translate(0,-80px)" /*,webkitTransform:"scale(calc(100% - 20%))"*/ }}>
            <Container component="main" maxWidth="xs">
                <FormPaper onSubmit={handleSubmit}>
                    <Avatar sx={{ margin: (theme) => theme.spacing(1), backgroundColor: "#280828" }}>
                        <LockOutlinedIcon />
                    </Avatar>

                    <Typography component="h1" variant="h5">
                        {auth.isLoginDialogOpen ? "Reconnectez-vous" : "Bienvenue"}
                    </Typography>
                    <FormDiv>
                        <TextField
                            //value={this.state.value}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Login"
                            //name="username"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            //value={this.state.value}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            //name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <Button type="submit" fullWidth variant="contained" color="primary" sx={{ margin: (theme) => theme.spacing(3, 0, 2) }}>
                            Connexion
                        </Button>
                    </FormDiv>
                </FormPaper>
                <Box mt={5}></Box>
            </Container>
        </Card>
    )
}
