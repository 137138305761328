import { withGrid, WithGridProps } from "core/misc"
import { RawComboBox, RawComboBoxProps } from "core/rawFields"
import { useField, useFormikContext } from "formik"

export interface FmkComboBoxProps<OptionData, Multiple extends boolean> extends Omit<RawComboBoxProps<OptionData, Multiple>, "value" | "onChange" | "onBlur" | "error"> {
    name: string
    validateImmediately?: boolean
    multiple?: Multiple
    options: Array<OptionData>
}

const FmkComboBoxUntyped = withGrid(<OptionData, Multiple extends boolean>(props: FmkComboBoxProps<OptionData, Multiple>) => {
    const { name, validateImmediately, ...otherProps } = props
    const [field, meta, { setValue, setTouched }] = useField<string>(name)
    const { isSubmitting } = useFormikContext()

    const handleChange = (newValue: any | Array<any>, optionData: any) => {
        setTouched(true, false)
        setValue(newValue, true)
    }

    const defaultProps: Partial<RawComboBoxProps<OptionData, Multiple>> = {
        size: "small",
        onChange: handleChange as any,
    }

    const config = {
        ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
        ...defaultProps,
        ...otherProps,
    }
    if (meta && (meta.touched || validateImmediately) && meta.error) {
        config.error = true
        config.helperText = meta.error
    }

    config.disabled = config.disabled ?? isSubmitting
    config.onBlur = () => {
        setTouched(true, true)
    }

    return <RawComboBox {...config} />
})

export function FmkComboBox<OptionData, Multiple extends boolean>(props: FmkComboBoxProps<OptionData, Multiple> & WithGridProps<{}>) {
    return (<FmkComboBoxUntyped {...props} />) as any
}
