import { EzModel } from "core/store/base"
import { Action, Model, Property } from "easy-peasy-classes"

type options = { label: string; value: any }

interface row {
    id: any
    primary: string
    secondary: string
    isChecked: boolean
}

interface setRowsProps {
    data: any[]
    primaryField: string
    secondaryField?: string
    idField?: string
}

@Model
export class commonRadioGroupModel extends EzModel {
    @Property
    value: any = null

    @Property
    options: options[] = []

    @Property
    isDisabled: boolean = false

    @Property
    isError: boolean = false

    @Property
    helperText: string = ""

    @Property
    label: string = ""

    // @Action
    // options({data, primaryField, secondaryField = undefined, idField = 'id'}:setRowsProps){
    //     const options = data.map(row=>({
    //         ...row,
    //         id: row[idField],
    //         primary: row[primaryField],
    //         secondary: row[secondaryField],
    //         isChecked: row.isChecked || false,
    //     }));

    //     this.options = options;
    // }

    @Action
    setValue(newValue: string) {
        this.value = newValue
    }

    @Action
    handleChange(newValue: string) {
        this.value = newValue
    }

    @Action
    setError(error: string) {
        if (!error) {
            this.isError = false
            this.helperText = ""
        } else {
            this.isError = true
            this.helperText = error
        }
    }
}
