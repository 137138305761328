import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import type { DateTime } from "luxon"
import type { ReactNode } from "react"

class LocalizedUtils extends AdapterLuxon {
    locale = "fr"
    getDatePickerHeaderText(dt: DateTime) {
        return dt.setLocale("fr").toFormat("EEE d MMM yyyy")
    }
}

export function DefaultLocalizationProvider(props: { children: ReactNode }) {
    const { children } = props

    return <LocalizationProvider dateAdapter={LocalizedUtils}>{children}</LocalizationProvider>
}
