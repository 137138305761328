/* eslint-disable simple-import-sort/imports */
import { commonTextFieldModel, commonSwitchModel, commonDialogModel } from "core/controls"
import { Action, Model, Property, Thunk } from "easy-peasy-classes"
import { store } from "core/store"
import { notistack } from "core/utils/notistack-global"
import { assignShape } from "core/utils"

@Model
export class inputBoxModel extends commonDialogModel<Record<string, { value: any; [key: string]: any }>> {
    @Property
    title: string = "Saisie"

    @Property
    msg: string = ""

    @Property
    button1Text: string = "Annuler"

    @Property
    button2Text: string = "Accepter"

    @Property
    verifyMsg: string = ""

    @Property
    fields: { [key: string]: field } = {}

    @Thunk
    init(payload: inputBoxParams) {
        this.mergeState({
            ...payload,
            fields: transformFields(payload.fields),
        })

        this.show()

        return this.promise()
    }

    @Action
    assignStateShape(payload: any) {
        assignShape(this, payload)
    }

    @Action
    mergeState(payload: { fields: { [key: string]: field }; title?: string; msg?: string; button1Text?: string; button2Text?: string }) {
        Object.assign(this, payload)
    }

    @Thunk
    async validate() {
        const verifyMsg = await this.verify()
        if (verifyMsg) return

        const dialogReturn = this.collect()

        this.endDialog(["success", dialogReturn])
    }

    @Thunk
    cancel() {
        this.endDialog(["cancel"])
    }

    @Thunk
    verify() {
        //verify
        const msgArr = []
        for (const fieldName in this.fields) {
            const field = this.fields[fieldName]
            if (!field.verifyFn(field)) msgArr.push(field.verifyMsg instanceof Function ? field.verifyMsg(field) : field.verifyMsg)
        }

        const verifyMsg = msgArr.length > 0 ? "Saisie incomplète : " + msgArr.join(", ") : ""

        if (verifyMsg.length > 0) {
            notistack.legacy({ msg: verifyMsg, severity: "warning" })
        }

        return verifyMsg
    }

    @Thunk
    collect() {
        //collect
        const dialogReturn = {}
        for (const fieldName in this.fields) {
            const field = this.fields[fieldName]
            switch (field.type) {
                //@ts-ignore
                case "text":
                    dialogReturn[fieldName] = { value: field.value }
            }
        }

        return dialogReturn
    }

    //common field actions//
    @Action
    handleChange_Text({ controlId, value }: { controlId: string; value: any }) {
        this.fields[controlId].value = value
    }
}

interface fieldParams {
    name: string
    type: "text" | "switch"
    label?: string
    verifyFn: (value?: any) => boolean
    verifyMsg?: string | ((field: field) => string)
    options?: any
    defaultValue?: any
}

interface field extends fieldParams {
    id: string
    value: any
}

interface inputBoxParams {
    title?: string
    msg?: string
    button1Text?: string
    button2Text?: string
    fields: fieldParams[]
}

function transformFields(fieldsArray: Array<fieldParams>): { [key: string]: field } {
    return fieldsArray.reduce((acc, field) => {
        acc = {
            ...acc,
            [field.name]: {
                ...(field.type === "text" && commonTextFieldModel.obj),
                //...(field.type === 'checkBox' && commonCheckBox()),
                ...(field.type === "switch" && commonSwitchModel.obj),
                id: field.name,
                type: field.type,
                label: field.label,
                verifyFn: field.verifyFn || (() => true),
                verifyMsg: field.verifyMsg || field.name + " manquant",
                options: field.options,
                ...(field.defaultValue && { value: field.defaultValue }),
            },
        }

        return acc
    }, {})
}

export const inputBox = async (payload: inputBoxParams) => {
    const promise = store.getActions().core.dialogs.inputBox.init(payload)
    return promise
}
