/* eslint-disable simple-import-sort/imports */
import { AxiosHeaders, AxiosResponse } from "axios"
import axios from "axios"
import { MainApi } from "core/services/swaggerClient"
import { DOPPLE_API_URL } from "core/utils/constants"
import { getUserToken } from "./userService"

const isoDateFormat = /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/
const isIsoDateString = (val: any) => typeof val === "string" && isoDateFormat.test(val)

const openapiAxios = axios.create({
    baseURL: process.env.REACT_APP_DOPPLE_API_URL,

    //revive dates
    transformResponse: (data) => {
        if (typeof data !== "string") return data
        try {
            return JSON.parse(data, (key, value) => {
                return isIsoDateString(value) ? new Date(value) : value
            })
        } catch (err) {
            return data
        }
    },
})

//set auth header from localeStorage
openapiAxios.interceptors.request.use((config) => {
    if (!config.headers) config.headers = new AxiosHeaders()
    if (!config.headers.has("Authorization")) config.headers.set("Authorization", getUserToken())
    return config
})

//return res.data instead of res
openapiAxios.interceptors.response.use(
    (res) => {
        if (res.data) return res.data
        else return res
    },
    (err) => {
        if (axios.isAxiosError(err) && err.response && err.response.data) {
            throw err.response.data
        } else {
            throw err
        }
    }
)

const isJsonMime = (mime: string) => mime.includes("json")
export const openapi = new MainApi({ isJsonMime }, DOPPLE_API_URL, openapiAxios) as unknown as RecursiveUnpackService<MainApi>

//as we use an interceptor to return res.data instead of res, we need to modify the type of the services.
type UnpackService<S extends (...args: any) => any> = (...args: Parameters<S>) => ReturnType<S> extends Promise<AxiosResponse<infer R>> ? Promise<R> : ReturnType<S>

type RecursiveUnpackService<API> = {
    [K in keyof API]: API[K] extends (...args: any) => any ? UnpackService<API[K]> : API[K] extends Record<string, any> ? RecursiveUnpackService<API[K]> : API[K]
}

//1. error is intercepted before throw in axios
//2. error is passed to apiErrHandler to run some side effects (snackbar, loginpage)
//3. error is thrown to stop the execution of the calling thunk
//(handle here only error types that are common to all app - eg INVALID-PASSWORD should be handled directly in the login thunk)

//Les erreurs sont toujours logguées
//Il y a toujours un message général qui s'affiche. Il peut être écrasé
// const axios = AxiosWithErrorHandling({
//     onBadRequest:(apiError)=>{
//         const snackbarId = notistack.legacy({msg: `Requète refusée : valeurs manquantes ou incorrectes.`,delay:3000,severity:'error'})
//         console.error(apiError)
//         apiError.snackbarId = snackbarId
//         throw apiError
//     },
//     onForbidden:(apiError)=>{
//         const snackbarId = notistack.legacy({msg: `Requète refusée : vous n'avez pas les privilèges requis pour effectuer cette opération.`,delay:3000,severity:'error'})
//         console.error(apiError)
//         apiError.snackbarId = snackbarId
//         throw apiError
//     },
//     onNotFound:(apiError,axiosError)=>{
//         const snackbarId = notistack.legacy({msg: `Requète impossible : l'url '${axiosError.request.url}' n'est pas reconnu par le serveur`,delay:0,severity:'error'})
//         console.error(apiError)
//         apiError.snackbarId = snackbarId
//         throw apiError
//     },
//     onapiError:(apiError)=>{
//         const snackbarId = notistack.legacy({msg: `Erreur survenue sur le serveur. Voir détails dans la console`,delay:0,severity:'error'})
//         console.error(apiError)
//         apiError.snackbarId = snackbarId
//         throw apiError
//     },
//     onUnauthorized:(apiError,axiosError)=>{
//         const type = apiError?.name

//         if (['UNSUPPLIED_TOKEN', 'INVALID_TOKEN', 'EXPIRED_TOKEN'].includes(type)){

//             const msg = {
//                  'UNSUPPLIED_TOKEN':'Token manquant : Veuillez vous reconnecter',
//                  'INVALID_TOKEN':'Token invalide : Veuillez vous reconnecter',
//                  'EXPIRED_TOKEN':'Token expiré : Veuillez vous reconnecter'
//             }[type]

//             const snackbarId = notistack.legacy({msg,delay:2500,severity:type === 'INVALID_TOKEN' ? 'error' : 'warning'})
//             store.getActions().app.clearToken();
//             apiError.snackbarId = snackbarId
//             throw apiError

//         }else if(type){
//             console.error(apiError)
//             const snackbarId = notistack.legacy({msg:"Erreur d'authentification : Veuillez vous reconnecter",delay:0,severity:'error'})
//             store.getActions().app.clearToken();
//             apiError.snackbarId = snackbarId
//             throw apiError
//         }

//         else {
//             console.error(axiosError)
//             const snackbarId = notistack.legacy({msg:"Erreur d'authentification : Veuillez vous reconnecter",delay:0,severity:'error'})
//             store.getActions().app.clearToken();
//             Object.assign(axiosError,{snackbarId})
//             throw axiosError
//         }
//     }
// })

// export const openapiAxiosLegacy = new AxiosWithErrorHandling()

//     //if missing or expired token, goto login popup and wait relogin
//     .setEnsureTokenFn(async (reqConfig) => {
//         if (reqConfig.url.endsWith("/agents/signin")) return
//         await ensureValidToken()
//     })

//     //merge default headers and token header
//     .setHeaderFn((configHeaders: Record<string, string>) => {
//         return { ...configHeaders, ...authHeader() }
//     })

//     .setDefaultHandler((axiosError) => {
//         const snackbarId = notistack.legacy({ msg: `Erreur survenue sur le serveur. Voir détails dans la console`, delay: 0, severity: "error" })
//         logError(axiosError)
//         axiosError.snackbarId = snackbarId
//         throw axiosError
//     })

//     .setPrimaryHandlers({
//         onNoResponse: (axiosError) => {
//             const snackbarId = notistack.legacy({ msg: `Requête échouée. Voir détails dans la console`, delay: 0, severity: "error" })
//             const rethrownError = new ClientError({ message: "Erreur inconnue axios, pas de réponse du backend", previous: axiosError })
//             logError(rethrownError)
//             rethrownError.snackbarId = snackbarId
//             throw rethrownError
//         },
//         onNoData: (axiosError) => {
//             const snackbarId = notistack.legacy({ msg: `Requête échouée. Voir détails dans la console`, delay: 0, severity: "error" })
//             const rethrownError = new ClientError({ message: "Erreur inconnue axios, pas d'objet data dans la réponse", previous: axiosError })
//             logError(rethrownError)
//             rethrownError.snackbarId = snackbarId
//             throw rethrownError
//         },
//         onNoName: (axiosError) => {
//             const snackbarId = notistack.legacy({ msg: `Requête échouée. Voir détails dans la console`, delay: 0, severity: "error" })
//             const rethrownError = new ClientError({ message: "Erreur inconnue axios, pas de type d'erreur dans la réponse", previous: axiosError })
//             logError(rethrownError)
//             rethrownError.snackbarId = snackbarId
//             throw rethrownError
//         },
//     })

//     .on("/unknown", (axiosErr, apiError) => {
//         const snackbarId = notistack.legacy({ msg: `Erreur inconnue survenue sur le serveur. Voir détails dans la console`, delay: 0, severity: "error" })
//         logError(apiError)
//         apiError.snackbarId = snackbarId
//         throw apiError
//     })

//     .on("/server", (axiosError, apiError) => {
//         const snackbarId = notistack.legacy({ msg: `Erreur survenue sur le serveur. Voir détails dans la console`, delay: 0, severity: "error" })
//         logError(apiError)
//         apiError.snackbarId = snackbarId
//         throw apiError
//     })

//     .on("/usage", (axiosError, apiError) => {
//         const snackbarId = notistack.legacy({ msg: `Requète refusée : valeurs manquantes ou incorrectes.`, delay: 3000, severity: "error" })
//         logError(apiError)
//         apiError.snackbarId = snackbarId
//         throw apiError
//     })

//     .on("/usage/not-found", (axiosError, apiError) => {
//         const snackbarId = notistack.legacy({ msg: `Requète impossible : l'url '${axiosError.config.url}' n'est pas reconnu par le serveur`, delay: 0, severity: "error" })
//         logError(apiError)
//         apiError.snackbarId = snackbarId
//         throw apiError
//     })

//     .on("/access", (axiosError, apiError) => {
//         let msg: string, severity: snackbarSeverity
//         if (apiError.name.includes("/access/unsupplied-token")) msg = "Token manquant : Veuillez vous reconnecter"
//         severity = "warning"
//         if (apiError.name.includes("/access/invalid-token")) msg = "Token manquant : Veuillez vous reconnecter"
//         severity = "error"
//         if (apiError.name.includes("/access/expired-token")) msg = "Token manquant : Veuillez vous reconnecter"
//         severity = "warning"
//         if (apiError.name.includes("/access/expired-token")) msg = "Token manquant : Veuillez vous reconnecter"
//         severity = "warning"
//         const snackbarId = notistack.legacy({ msg, delay: 2500, severity })
//         store.getActions().core.app.clearToken()
//         apiError.snackbarId = snackbarId
//         throw apiError
//     })

//     .on("/access/insufficient-permissions", (axiosError, apiError) => {
//         const snackbarId = notistack.legacy({ msg: `Requète refusée : vous n'avez pas les privilèges requis pour effectuer cette opération.`, delay: 3000, severity: "error" })
//         logError(apiError)
//         apiError.snackbarId = snackbarId
//         throw apiError
//     })

//     .createAxiosInstance({})

// openapiAxios.interceptors.response.use((res)=>{
//     const data = res.data ?? res
//     if(!data) return res
//     const revivedData = handleDates(data)
//     if(res.data !== undefined || res.data !== null) {
//         res.data = revivedData
//     }else{
//         res = data
//     }
//     return res
// })
