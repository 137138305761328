import { ComponentsProps, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { Bx, GoogleSearchLocationInput } from "core/misc"
import type { Coordinates } from "core/services/rasmik"
import type { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { GoogleMap as ReactGoogleMap, GoogleMapProps,InfoWindow, Marker, withGoogleMap, WithGoogleMapProps } from "react-google-maps"

const defaultPlace: Coordinates = { lat: 49.126798901612965, lng: 6.184347455417095 }

//& {onMarkerDragEnd: (event:any)=>any, position: Coordinates}
function SubComponent(props: WithGoogleMapProps & GoogleMapProps & { draggable?: boolean; defaultZoom?: number; centerLat?: number; centerLng?: number; children: ReactNode | ReactNode[]; onMapRef: (mapRef: ReactGoogleMap) => any }) {
    const { children, centerLat = defaultPlace.lat, centerLng = defaultPlace.lng, draggable, defaultZoom = 15, onMapRef, ...other } = props

    return (
        <ReactGoogleMap key="react-google-map" ref={(ref) => ref && onMapRef(ref)} defaultZoom={defaultZoom} defaultCenter={{ lat: centerLat, lng: centerLng }} {...other}>
            {children}
        </ReactGoogleMap>
    )
}

export const GoogleMapBase = withGoogleMap(SubComponent) as any as React.FunctionComponent<React.ComponentProps<typeof SubComponent>>
export type GoogleMapBaseProps = React.ComponentProps<typeof GoogleMapBase>
