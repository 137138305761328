import { styled } from "@mui/material"
import { propsToClassKey } from "@mui/styles"
import type { CSSProperties, HTMLProps, ReactNode } from "react"
import React from "react"

interface dotProps {
    /**css color prop */
    color: string

    /** ex: '11px' */
    size?: string

    marginInlineStart?: string
    marginInlineEnd?: string

    /**css textAlign prop */
    center?: CSSProperties["textAlign"]

    children?: ReactNode
    side?: "left" | "right"
}

const StyledDot = styled("span", { shouldForwardProp: (key) => false })<dotProps>(({ color, size, marginInlineStart, marginInlineEnd, center }) => ({
    height: size || "11px",
    width: size || "11px",
    backgroundColor: color,
    borderRadius: "50%",
    display: "inline-block",
    marginInlineStart: marginInlineStart || "10px",
    marginInlineEnd: marginInlineEnd || "10px",
    verticalAlign: "middle",
    textAlign: center || "center",
}))

export const Dot = React.forwardRef(function Dot(props: dotProps, ref) {
    const { children, side = "left", color, size, marginInlineEnd, marginInlineStart, center, ...otherProps } = props
    const dotProps = { color, size, marginInlineEnd, marginInlineStart, center }
    return (
        <span style={{ whiteSpace: "nowrap", display: "inline" }} ref={ref as any} {...otherProps}>
            {side === "right" && children}
            <StyledDot {...dotProps} />
            {side === "left" && children}
        </span>
    )
})

export function Pie({ colors, rotate, smooth, children, ...rest }: Omit<HTMLProps<HTMLSpanElement>, "size"> & { colors: Array<CSSProperties["color"]>; rotate?: string; smooth?: boolean }) {
    const quotien = (1 / colors.length) * 100 * 3.6

    const slices = colors.map((color, index) => `${color} ${quotien * (index + 0)}deg ${smooth ? "" : `${quotien * (index + 1)}deg`}`)
    if (smooth) slices.push(`${colors[0]} 360deg`)

    return (
        <span {...rest} style={{ zIndex: 1, position: "relative", backgroundColor: "rgba(0,0,0,0)", ...rest?.style }}>
            <div style={{ zIndex: -1, position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px", backgroundImage: `conic-gradient(${slices.join(", ")})`, backgroundOrigin: "border-box", borderRadius: "50%", ...(rotate && { transform: `rotate(${rotate})` }) }} />
            {children}
        </span>
    )
}
