
import { createTheme } from '@mui/material';
import tinycolor from "tinycolor2";

import type {} from '@mui/x-data-grid-pro/themeAugmentation';

const primary =  process.env.REACT_APP_TARGET_ENV === "staging" ? "#308329" : "#302c7b"; //"#3a07d5c7"; //"#6736d9"; 
const secondary = "#2c707b"//"#694e34" brown //"#FF5C93" pink; 
const warning = "#f0a01f" //"#FFC260";
const success = "#17A47A";
const failure = "#f44336"
const info = "#9013FE";

const lightenRate = 7.5;
const darkenRate = 25; //15;



declare module '@mui/material/styles' {
  // // interface Theme {
  // //   status: {
  // //     danger: React.CSSProperties['color'];
  // //   };
  // // }

  // // interface Palette {
  // //   neutral: Palette['primary'];
  // // }
  // // interface PaletteOptions {
  // //   neutral: PaletteOptions['primary'];
  // // }

  // // interface PaletteColor {
  // //   darker?: string;
  // // }
  // // interface SimplePaletteColorOptions {
  // //   darker?: string;
  // // }
  // // interface ThemeOptions {
  // //   status: {
  // //     danger: React.CSSProperties['color'];
  // //   };
  // // }

  interface Palette {
    neutral: Palette['primary'];
    failure: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    failure: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}

// declare module '@mui/material/TextField' {
//   interface BaseTextFieldProps {
//     necessary?:boolean
//   }
// }

export const custom1 = createTheme({
  zIndex:{
    snackbar:10000
  },
  typography:{
    fontFamily: [
      // '-apple-system',
      // 'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    failure: {
      main: failure,
      light: tinycolor(failure)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(failure)
        .darken(darkenRate)
        .toHexString(),
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      // hint: "#B9B9B9",
    },
    background: {
      default: '#fcfcfc',//"#F6F7FF",
      // light: "#FaFaFa",
    },
  },
  // customShadows: {
  //   widget:
  //     "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  //   widgetDark:
  //     "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  //   widgetWide:
  //     "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  // },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "#4A4A4A1A",
        },
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow:
            "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#B9B9B9",
        },
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&$selected": {
            backgroundColor: "#F3F5FF !important",
            "&:focus": {
              backgroundColor: "#F3F5FF",
            },
          },
        },
        button: {
          "&:hover, &:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      }
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: "white",
        },
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 56,
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          //borderBottom: "1px solid rgba(224, 224, 224, .5)",
        },
        head: {
          fontSize: "0.95rem",
        },
        body: {
          fontSize: "0.95rem",
        },
      }
    },
  },
})
