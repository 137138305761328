import EventEmitter from "events"
import { DependencyList, EffectCallback, useEffect } from "react"

export class UniqueScriptLoader extends EventEmitter {
    public url: string
    public script: HTMLScriptElement
    private _isLoaded: boolean = false

    constructor(url: string) {
        super()
        this.url = url
        this.setMaxListeners(32)
    }

    get isLoaded() {
        return this._isLoaded
    }

    createScript() {
        if (this.script) return
        this.script = document.querySelector(`script[src*='${this.url}']`)
        if (this.script) return

        this.script = document.createElement("script")
        this.script.setAttribute("type", "text/javascript")
        //this.script.setAttribute("id",id)

        this.script.setAttribute("src", this.url)
        //this.script.async = true

        this.script.addEventListener("load", () => {
            this._isLoaded = true
            this.emit("loaded")
        })

        document.getElementsByTagName("head")[0].appendChild(this.script)
    }

    onLoaded(listener: (...args: any[]) => void) {
        return this.addListener("loaded", listener)
    }

    clearScript() {
        if (!this.script) throw new Error("Can't clear script that don't exist")
        document.removeChild(this.script)
    }

    /**
     * Run callback immediately or on script'loaded' event. Load the script if needed
     * Pass an array of dependency to limit execution to spcific renders (=useEffect)
     * */
    useScript(callback: EffectCallback, deps?: DependencyList) {
        return useScript.call(this, callback, deps)
    }
}

export function useScript(this: UniqueScriptLoader, callback: EffectCallback, deps?: DependencyList) {
    return useEffect(() => {
        if (!this.isLoaded) {
            this.createScript()
            this.onLoaded(callback)
        } else {
            callback()
        }
    }, [])
}

const GoogleMapsAapiKey = "AIzaSyADaOHnmV9vzKoEhcz6DiyYoLc6o83d0xY"
export const googleMapsApiLoader = new UniqueScriptLoader(`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAapiKey}&libraries=places`)
