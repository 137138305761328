import { Close as CloseIcon } from "@mui/icons-material"
import { Button, IconButton } from "@mui/material"
import { SnackbarKey, SnackbarProvider, useSnackbar } from "notistack"
import { ReactNode, useRef } from "react"

export function SnackbarProviderCustom(props: { children: ReactNode }) {
    const notistackRef = useRef<SnackbarProvider | null>()
    return (
        <SnackbarProvider
            maxSnack={3}
            ref={(ref) => {
                notistackRef.current = ref
            }}
            action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        >
            {props.children}
        </SnackbarProvider>
    )
}

function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey }) {
    const { closeSnackbar } = useSnackbar()

    return (
        // <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        //     <CloseIcon />
        // </IconButton>
        <Button variant="text" color="inherit" onClick={() => closeSnackbar(snackbarKey)}>
            Vu
        </Button>
    )
}
