import { useEffect, useState } from "react"

export function useStateWithDeps<T>(initialValue: T, deps?: React.DependencyList) {
    deps = deps ?? [initialValue]

    const [state, setState] = useState<T>(initialValue)

    useEffect(() => {
        setState(initialValue)
    }, [deps])

    return [state, setState] as const
}

//Semble créer des bugs
