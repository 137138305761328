import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { IconButton, IconButtonProps } from "@mui/material"
import { styled } from "@mui/material/styles"

export interface RotatingArrowButtonProps extends IconButtonProps {
    up: boolean
}
export const RotatingArrowButton = styled((props: RotatingArrowButtonProps) => {
    const { up, ...other } = props
    return (
        <IconButton {...other}>
            <ExpandMoreIcon />
        </IconButton>
    )
})(({ theme, up }) => ({
    transform: !up ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.standard,
    }),
}))
