import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material"
import { createLocalHooks } from "core/store"

import type { commonRadioGroupModel } from "./radioGroup.model"

interface RadioGroup$Props {
    id?: string
    mapper: (s: any) => any
    row?: boolean
    options?: Array<{ label: string; value: any }>
    label?: string
}
export function RadioGroup$(props: RadioGroup$Props) {
    const { id, mapper: µ, ...rest } = props

    const { useLocalState, useLocalActions } = createLocalHooks<commonRadioGroupModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    const { row = false } = props

    //pick options from state or props
    const { options: propsOptions, label = "" } = rest
    const controlledOptions = unitState?.options?.length ? unitState.options : undefined
    const options = controlledOptions || propsOptions || []

    return (
        <FormControl component="fieldset" error={unitState.isError}>
            {(label || unitState.label) && <FormLabel component="legend">{label || unitState.label}</FormLabel>}

            <RadioGroup value={unitState.value} onChange={(e) => unitActions.handleChange(e.target.value)} row={row}>
                {options.map((option, index) => (
                    <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} />
                ))}
            </RadioGroup>
            {unitState.helperText && <FormHelperText>{unitState.helperText}</FormHelperText>}
        </FormControl>
    )
}
