import { Close as CloseIcon, Done as DoneIconMui, Edit as EditIcon } from "@mui/icons-material"
import TaskTodoIcon from "@mui/icons-material/AssignmentLate"
import TaskDoneIcon from "@mui/icons-material/AssignmentTurnedIn"
import HourglassBottomIcon from "@mui/icons-material/HourglassBottomTwoTone"
import HourglassIcon from "@mui/icons-material/HourglassTopTwoTone"
import { Chip, CircularProgress, IconButton, Popover, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/material"
import { usePopover } from "core/hooks"
import { RawDateField, RawTextField } from "core/rawFields"
import type { RichComment } from "core/services/rasmik-client"
import { DayDate } from "core/utils"
import { isEqual } from "lodash"
import { useCallback, useEffect, useState } from "react"

import { HFlex, Spacer, VFlex } from "./Flex.com"
import { DoneIcon, NotDoneIcon, TodoIcon } from "./TodoIcon.com"

export interface RichCommentEditorProps {
    comment: RichComment
    onSave: (newComment: RichComment) => any
    onCancel?: () => any
}
export function RichCommentEditor(props: RichCommentEditorProps) {
    const { comment, onCancel, onSave } = props

    const [draftComment, setDraftComment] = useState(comment)
    const [isLoading, setIsLoading] = useState(false)

    const setValue = useCallback(<K extends keyof RichComment>(field: K, value: RichComment[K]) => {
        setDraftComment((state) => ({ ...state, [field]: value }))
    }, [])

    const handleStatusChange = useCallback(
        (newStatus: RichComment["status"]) => {
            if (newStatus !== draftComment.status) {
                setValue("status", newStatus)
                if (newStatus !== "snoozed") {
                    setValue("snoozeUntil", null)
                }
            } else {
                setValue("status", "empty")
                setValue("snoozeUntil", null)
            }
        },
        [draftComment.status]
    )

    const handleMessageChange = useCallback(
        (newMessage: RichComment["message"]) => {
            if (newMessage !== draftComment.message) {
                setValue("message", newMessage)

                if (draftComment.status !== "todo" && draftComment.status !== "snoozed") {
                    setValue("status", "todo")
                    setValue("snoozeUntil", null)
                }
            }
        },
        [draftComment.message, draftComment.status]
    )

    //set iniial
    useEffect(() => {
        if (!isEqual(draftComment, props.comment)) {
            setDraftComment(props.comment)
        }
    }, [props.comment])

    const handleSave = useCallback(async () => {
        setIsLoading(true)

        try {
            draftComment.writtenAt = new Date()
            await onSave(draftComment)
        } catch (err) {
        } finally {
            setIsLoading(false)
        }
    }, [draftComment])

    const handleCancel = useCallback(() => {
        //reset
        setDraftComment(props.comment)

        onCancel && onCancel()
    }, [props.comment])

    const isTouched = !isEqual(draftComment, props.comment)
    const isSnoozeOverdue = !!(draftComment.status === "snoozed" && draftComment.snoozeUntil && draftComment.snoozeUntil < new Date())
    const isSnoozeMissing = draftComment.status === "snoozed" && !draftComment.snoozeUntil
    const isValid = !(isSnoozeOverdue || isSnoozeMissing)

    return (
        <VFlex width="100%" gap="10px">
            <HFlex justifyContent="space-between">
                <HFlex gap="10px">
                    <Chip
                        color={draftComment.status === "todo" ? "error" : "default"}
                        icon={<TaskTodoIcon />}
                        label="Todo"
                        onClick={() => {
                            handleStatusChange("todo")
                        }}
                    />
                    <Chip
                        color={draftComment.status === "snoozed" ? "warning" : "default"}
                        icon={<HourglassIcon />}
                        label="Temporisé"
                        onClick={() => {
                            handleStatusChange("snoozed")
                        }}
                    />
                    <Chip
                        color={draftComment.status === "done" ? "success" : "default"}
                        icon={<TaskDoneIcon />}
                        label="Traité"
                        onClick={() => {
                            handleStatusChange("done")
                        }}
                    />
                </HFlex>

                {draftComment.status === "snoozed" && (
                    <RawDateField
                        size="small"
                        label="jusqu'au"
                        value={draftComment.snoozeUntil ? DayDate.fromJSDate(draftComment.snoozeUntil).toISOString() : null}
                        onChange={(dateStr) => setValue("snoozeUntil", dateStr ? DayDate.fromISOString(dateStr).toJsDate() : null)}
                        minDate={DayDate.today().toLuxonDateTime()}
                        error={isSnoozeOverdue || isSnoozeMissing}
                        helperText={isSnoozeOverdue ? "Dépassé" : isSnoozeMissing ? "Requis" : null}
                    />
                )}

                {isLoading ? (
                    <CircularProgress size={20} />
                ) : (
                    <Box>
                        <IconButton onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                        <IconButton disabled={!isTouched || !isValid} onClick={handleSave}>
                            <DoneIconMui />
                        </IconButton>
                    </Box>
                )}
            </HFlex>

            <HFlex>
                <RawTextField
                    size="small"
                    fullWidth
                    value={draftComment.message}
                    onChange={(e) => {
                        handleMessageChange(e.target.value)
                    }}
                />
            </HFlex>
        </VFlex>
    )
}

export interface RichCommentColumnProps {
    comment: RichComment
    onSave?: (newComment: RichComment) => any
    noEdit?: boolean
}
export function RichCommentColumn(props: RichCommentColumnProps) {
    const { comment, onSave, noEdit = false } = props

    const popover = usePopover()
    const [isLoading, setIsLoading] = useState(false)

    const handleSave = useCallback(async (newComment: RichComment) => {
        setIsLoading(true)
        try {
            await onSave(newComment)
            popover.closePopover()
        } finally {
            setIsLoading(false)
        }
    }, [])

    const handleCancel = useCallback(async () => {
        popover.closePopover()
    }, [])

    const commentAndStatus = (
        <>
            <>
                {comment.status === "done" && (
                    <Tooltip title="Traité" arrow>
                        <TaskDoneIcon color="success" />
                    </Tooltip>
                )}
                {comment.status === "todo" && (
                    <Tooltip title="Todo" arrow>
                        <TaskTodoIcon color="error" />
                    </Tooltip>
                )}
                {comment.status === "snoozed" && (
                    <Tooltip title={`Temporisé jusqu'au ${comment.snoozeUntil?.toLocaleDateString()}`} arrow>
                        {comment.snoozeUntil >= new Date() ? <HourglassIcon sx={{ color: (theme) => theme.palette.warning.main }} /> : <HourglassBottomIcon sx={{ color: (theme) => theme.palette.error.main }} />}
                    </Tooltip>
                )}
            </>

            <Box height="100%" marginTop="2px" flex={1}>
                {comment.writtenAt && comment.message && (
                    <Typography fontSize={"0.675rem"} maxWidth="270px" whiteSpace="normal" lineHeight={1} sx={{ WebkitLineClamp: 3, WebkitBoxOrient: "vertical", display: "-webkit-box", overflow: "hidden" }}>
                        le {comment.writtenAt?.toLocaleDateString()} : {comment.message}
                    </Typography>
                )}
            </Box>
        </>
    )

    if (noEdit)
        return (
            <HFlex flex={1} gap="5px">
                {commentAndStatus}
            </HFlex>
        )

    return (
        <>
            <HFlex flex={1} gap="5px">
                {commentAndStatus}
                {isLoading ? (
                    <CircularProgress size={20} sx={{ margin: "8px" }} />
                ) : (
                    <IconButton
                        onClick={(event) => {
                            popover.openPopover(event.target as any)
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                )}
            </HFlex>

            {popover.isPopoverOpen && (
                <Popover
                    open={popover.isPopoverOpen}
                    anchorReference="anchorPosition"
                    anchorPosition={{ left: popover.anchorPositionPopover.left, top: popover.anchorPositionPopover.top + 30 }}
                    /* anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} */ transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <HFlex sx={{ padding: "10px", minWidth: "700px" }}>
                        <RichCommentEditor comment={comment} onSave={handleSave} onCancel={handleCancel} />
                    </HFlex>
                </Popover>
            )}
        </>
    )
}
