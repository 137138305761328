import axios from "axios"

export type ApiAdresseSearchResult = {
    cityName: string
    cityCode: string
    postalCode: string
    number: string
    letter: string
    streetName: string
    streetTypeLabel: string
    streetTypeCode: string
    region?: string
    dptName: string
    dptCode: string
    coordinates: {
        lng: number
        lat: number
    }
}

type PlaceType = "housenumber" | "street" | "locality" | "municipality"

export type ApiAdresseRawResultItem = {
    label: string
    score: number
    id: string
    name: string
    postcode: string
    citycode: string
    x: number
    y: number
    city: string
    district: string
    context: string
    type: PlaceType
    importance: number
    housenumber?: string
    street?: string
    coordinates: {
        lng: number
        lat: number
    }
}

export class ApiAdresse {
    static baseUrl = "https://api-adresse.data.gouv.fr"

    static searchUrl({ query, type, autocomplete = true, limit }: { query: string; autocomplete?: boolean; limit?: number; type: PlaceType }) {
        if (!query) return { url: "" }

        const qs = new URLSearchParams()

        qs.append("autocomplete", autocomplete ? "1" : "0")
        qs.append("type", type)
        limit && qs.append("limit", limit.toString())
        qs.append("q", query)

        const url = `${this.baseUrl}/search/?${qs.toString()}`

        return { url }
    }

    static async search({ query, type, autocomplete = true, limit }: { query: string; autocomplete?: boolean; limit?: number; type: PlaceType }): Promise<ApiAdresseRawResultItem[]> {
        const { url } = this.searchUrl({ query, type, autocomplete, limit })
        const res = await axios.get(url)

        if (res?.data?.features) {
            return res.data.features.map((feature) => {
                return {
                    ...feature.properties,
                    coordinates: {
                        lng: feature.geometry.coordinates[0],
                        lat: feature.geometry.coordinates[1],
                    },
                }
            })
        } else return []
    }

    static reverse(coordinates) {
        const lng = coordinates[0]
        const lat = coordinates[1]
        const url = `${this.baseUrl}/reverse/?lng=${lng}&lat=${lat}`

        return { url }
    }

    static csv(requestBody) {
        const url = `${this.baseUrl}/search/csv/`
        const options = { method: "POST", body: requestBody }

        return { url, options }
    }

    static cleanResult(rawResult: ApiAdresseRawResultItem): ApiAdresseSearchResult {
        const [dptCode, dptName, region = ""] = rawResult.context.split(",")

        const { letter, number } = this.parseHouseNumber(rawResult.housenumber)
        const { streetTypeCode, streetName, streetTypeLabel } = this.parseStreet(rawResult.type === "housenumber" ? rawResult.street : rawResult.name)

        return {
            coordinates: rawResult.coordinates,
            cityCode: rawResult.citycode,
            cityName: rawResult.city,
            dptCode: dptCode.trim(),
            dptName: dptName.trim(),
            region: region.trim(),
            letter: letter,
            number: number,
            postalCode: rawResult.postcode,
            streetName: streetName,
            streetTypeLabel: streetTypeLabel,
            streetTypeCode: streetTypeCode,
        }
    }

    static parseHouseNumber(houseNumber: string) {
        const number = houseNumber ? parseInt(houseNumber) : null
        const number$ = (number || "").toString()
        const numberLength = number$.length

        const letterPart = numberLength ? houseNumber.substring(numberLength).trim().toUpperCase() : null

        let letterCode: string

        switch (letterPart) {
            case "BIS":
                letterCode = "B"
                break
            case "TER":
                letterCode = "C"
                break
            case "QUATER":
                letterCode = "D"
                break
            case "QUINQUIES":
                letterCode = "E"
                break
            case "SEXIES":
                letterCode = "F"
                break
            default:
                letterCode = letterPart
        }
        return { number: number$ || null, letter: letterCode || null }
    }

    static parseStreet(street: string) {
        if (!street) return { streetTypeLabel: null, streetName: null, streetTypeCode: null }

        const typesList = Object.keys(streetTypeCodeMap)

        for (const type of typesList) {
            const regexp = RegExp(`\\b(${type})\\b`, "i")

            if (regexp.test(street)) {
                return {
                    streetTypeLabel: type,
                    streetName: street.replace(regexp, "").trim(),
                    streetTypeCode: streetTypeCodeMap[type], //default to rue
                }
            }
        }

        //else
        return {
            streetTypeLabel: "",
            streetName: street,
            streetTypeCode: "R", //default to rue
        }
    }

    static async geocode(query: string, type: PlaceType = "housenumber") {
        const results = await this.search({ query, autocomplete: false, type, limit: 1 })

        const firstResult = results?.[0]
        if (firstResult) {
            return this.cleanResult(firstResult)
        } else {
            return null
        }
    }
}

export const streetTypeCodeMap = {
    Allée: "ALL",
    Avenue: "AV",
    Batiment: "BAT",
    Boulevard: "BD",
    Cours: "C",
    Canal: "CAN",
    Chemin: "CHE",
    Chaussée: "CHS",
    Cité: "CI",
    Clos: "CL",
    Cottage: "COG",
    Faubourg: "FG",
    Hameau: "HAM",
    Immeuble: "IMB",
    Impasse: "IMP",
    Lotissement: "LOT",
    Maison: "MAI",
    Montée: "MTE",
    Parc: "PAR",
    Passage: "PAS",
    Pavillon: "PAV",
    Place: "PL",
    Porte: "POR",
    Quai: "Q",
    Quartier: "QU",
    Rue: "R",
    Rampe: "RAM",
    Résidence: "RES",
    Route: "RTE",
    Ruelle: "RUL",
    Sentier: "SEN",
    Square: "SQ",
    Voie: "VO",
}
