import { Alert, CircularProgress } from "@mui/material"
import { Center } from "core/misc"
import { openapi } from "core/services/openapi"
import { useAuthContext } from "core/technic/auth"
import { useMutation } from "react-query"
import { useParams, useSearchParams } from "react-router-dom"
import { useEffectOnce } from "react-use"

export function GocarlessCompleteRedirectFlowPopup() {
    const [urlSearchParams, setSearchParams] = useSearchParams()
    const { familyId } = useParams<{ familyId: string }>()
    const auth = useAuthContext()
    const mutation = useMutation({
        mutationFn: async () => {
            await auth.ensureValidToken()
            await openapi.families.completeGocardlessRedirectFlow(+familyId, urlSearchParams.get("redirect_flow_id"))
            urlSearchParams.append("autoclose", "true")
            setSearchParams(urlSearchParams)
        },
    })

    useEffectOnce(() => {
        if (mutation.isIdle) {
            mutation.mutate()
        }
    })

    return (
        <Center height="100%">
            {mutation.isLoading && <CircularProgress />}
            {mutation.isSuccess && <Alert severity="success">Client ajouté</Alert>}
        </Center>
    )
}
