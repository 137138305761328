import BoltIcon from "@mui/icons-material/Bolt"
import { IconButton, TextField, TextFieldProps } from "@mui/material"
import { withGrid, WithGridProps } from "core/misc"
import type { ChangeEvent, ChangeEventHandler } from "react"

const cancel = Symbol("cancel")
type Cancel = typeof cancel

type TextFieldOnChange<V> = (event: TextFieldChangeEvent<V>, value: V) => any
type TextFieldChangeEvent<V> = Omit<ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, "target"> & { target: Omit<ChangeEvent<HTMLInputElement | HTMLTextAreaElement>["target"], "value"> & { value: V } }

export type RawTextFieldProps<V extends string | number = string | number> = WithGridProps<
    Omit<TextFieldProps, "variant" | "value" | "onChange"> & {
        value?: V | null
        onChange?: TextFieldOnChange<V | null>
        necessary?: boolean
        missing?: boolean
        powerAction?: (textFieldProps: RawTextFieldProps<V>) => any
        variant?: "outlined" | "standard" | "filled"
        transformOutput?: (value: V | null, cancel: Cancel) => V | null | Cancel
        transformInput?: (value: V | null) => V | null
        numeric?: boolean
    }
>
export const RawTextFieldUntyped = withGrid(<V extends string | number>(props: RawTextFieldProps<V>) => {
    const { necessary, missing, powerAction, variant, transformInput, transformOutput, numeric, ...textFieldProps } = props

    if (transformInput) textFieldProps.value = transformInput(textFieldProps.value as V | null)
    //@ts-ignore
    if (textFieldProps.value === null) textFieldProps.value = ""

    textFieldProps.size = textFieldProps.size ?? "small"

    const isMissing = missing ?? (necessary && (textFieldProps.value === undefined || textFieldProps.value === null || textFieldProps.value === ""))

    if (!textFieldProps.error && isMissing) {
        textFieldProps.label = `⚠️ ${textFieldProps.label}`
        textFieldProps.helperText = textFieldProps.helperText ? `${textFieldProps.helperText} - Nécessaire` : "Nécessaire"
    }

    if (numeric) {
        if (!textFieldProps.inputMode) textFieldProps.inputMode = "numeric"
    }

    //convert null to "" and "" to null
    const oldOnChange = textFieldProps.onChange
    //@ts-ignore
    textFieldProps.onChange = undefined

    textFieldProps.onChange = (e) => {
        let value: V | null
        if (e.target.value === "") {
            value = null
        } else {
            value = e.target.value
        }

        if (textFieldProps.type === "number") {
            if (typeof value === "string") value = value.replace(/\,/, ".") as V
            //@ts-ignore
            value = convertNumber(value)
        }

        if (transformOutput) {
            const transformed = transformOutput(value, cancel)
            if (transformed === cancel) return
            else value = transformed
        }

        const event = { ...new Event("change"), ...e, target: { ...e.target, value: value } }
        //@ts-ignore
        oldOnChange && oldOnChange(event as any, event.target.value)
    }

    if (powerAction) {
        textFieldProps.InputProps = textFieldProps.InputProps ?? {}

        if (textFieldProps.InputProps.endAdornment) {
            textFieldProps.InputProps.endAdornment = (
                <>
                    {textFieldProps.InputProps.endAdornment}
                    <IconButton onClick={() => powerAction(props)} edge="end" children={<BoltIcon />} disabled={textFieldProps.disabled} />
                </>
            )
        } else {
            textFieldProps.InputProps.endAdornment = <IconButton onClick={() => powerAction(props)} edge="end" children={<BoltIcon />} />
        }
    }

    return (
        /* @ts-ignore */
        <TextField
            variant={variant}
            {...textFieldProps}
            sx={{
                "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                },

                writingMode: "initial",
                ...(textFieldProps.error && {
                    "& label, & label.Mui-focused": {
                        color: (theme) => theme.palette.error.light,
                    },
                }),
                ...(isMissing &&
                    !textFieldProps.error && {
                        // '& label:after':{
                        //   content:'" - Nécessaire !"'
                        // },
                        "& label, & label.Mui-focused,& .MuiFormHelperText-root": {
                            color: (theme) => theme.palette.warning.light,
                        },
                        "& .MuiInput-underline:after": {
                            borderBottomColor: (theme) => theme.palette.warning.light,
                        },
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "white",
                            "& fieldset": {
                                borderColor: (theme) => theme.palette.warning.light,
                            },
                            "&:hover fieldset": {
                                borderColor: (theme) => theme.palette.warning.light,
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: (theme) => theme.palette.warning.light,
                            },
                        },
                    }),
            }}
        />
    )
})

function convertNumber(text: string) {
    const parsed = parseFloat(text)
    if (isNaN(parsed)) return null
    else return parsed
}

export function RawTextField<V extends string | number>(props: RawTextFieldProps<V> & WithGridProps<{}>) {
    return (<RawTextFieldUntyped {...(props as any)} />) as any as ReturnType<React.FC<RawTextFieldProps<V> & WithGridProps<{}>>>
}
