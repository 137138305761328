import { DateTime } from "luxon"
import { useEffect, useState } from "react"

export type UseMonthIncrementPickerParams = { initialMonth: string | null; maxMonth?: string; minMonth?: string }

export function useMonthIncrementPicker(params: UseMonthIncrementPickerParams) {
    let { initialMonth, minMonth, maxMonth } = params
    initialMonth ??= DateTime.now().startOf("month").toISODate()

    const [currentMonth, setCurrentMonth] = useState(initialMonth)
    const [isTouched, setIsTouched] = useState(false)

    useEffect(() => {
        if (initialMonth !== currentMonth) setCurrentMonth(initialMonth)
    }, [initialMonth])

    return {
        increment() {
            if (maxMonth && currentMonth >= maxMonth) return
            const newMonth = DateTime.fromISO(currentMonth).plus({ months: 1 }).toISODate()
            setCurrentMonth(newMonth)
            if (!isTouched) setIsTouched(true)
        },
        decrement() {
            if (minMonth && currentMonth <= minMonth) return
            const newMonth = DateTime.fromISO(currentMonth).minus({ months: 1 }).toISODate()
            setCurrentMonth(newMonth)
            if (!isTouched) setIsTouched(true)
        },
        isMax: !!(maxMonth && currentMonth >= maxMonth),
        isMin: !!(minMonth && currentMonth <= minMonth),
        reset() {
            setCurrentMonth(initialMonth)
        },
        currentMonth,
        minusMonth: DateTime.fromISO(currentMonth).minus({ months: 1 }).toISODate(),
        plusMonth: DateTime.fromISO(currentMonth).plus({ months: 1 }).toISODate(),
        isDirty: initialMonth !== currentMonth,
        isTouched,
    }
}
