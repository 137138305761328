import { EzModel } from "core/store/base"
import { Action, Model, Property, Thunk } from "easy-peasy-classes"

let timer: any = undefined

let lastTime = 0
let isRunning = false

@Model
export class commonStopWatchModel extends EzModel {
    @Property
    value: number = 0

    @Thunk
    start() {
        lastTime = new Date().getTime()
        isRunning = true
        if (timer) return

        timer = setInterval(() => {
            if (isRunning) this.update()
        }, 100)
    }

    @Action
    update() {
        const newTime = new Date().getTime()
        if (isRunning) this.value += newTime - lastTime
        lastTime = newTime
    }

    @Action
    pause() {
        isRunning = false
    }

    @Action
    reset() {
        this.value = 0
    }

    @Thunk
    restart() {
        this.reset()
        this.start()
    }

    @Action
    setValueFromSec(seconds: number) {
        this.value = seconds ? seconds * 1000 : null
    }
}
