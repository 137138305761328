import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { Autocomplete, AutocompleteProps, Checkbox, TextField, TextFieldProps } from "@mui/material"
import { useEffect, useState } from "react"

import { RawTextField, RawTextFieldProps } from "./RawTextField.com"

//freesolo : recuperation de la valeur a la frappe
//autrement : récupération de la valeur a la selection
//le texte de l'input est donc non controllé et est

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export type RawComboBoxProps<OptionData, M extends boolean> = Omit<AutocompleteProps<Option<OptionData>, M, any, any>, "onChange" | "renderInput" | "options"> &
    Pick<RawTextFieldProps, "label" | "necessary" | "missing" | "size" | "fullWidth" | "helperText" | "placeholder" | "variant" | "error"> & {
        multiple?: M
        options: Array<OptionData>
        onChange?: M extends true ? (selectedValue: Array<any>, selectedData: Array<OptionData>) => void : (selectedValue: any, selectedData: OptionData) => void
        autocomplete?: boolean
        configure: (option: OptionData) => { value: any; label: any; disabled?: boolean }
        checkboxes?: boolean
    }

type Option<OptionData = any> = { value: any; label: string; disabled: boolean; data: OptionData }

export function RawComboBox<OptionData, Multiple extends boolean>(props: RawComboBoxProps<OptionData, Multiple>) {
    const { configure, autocomplete, options, value, onChange, onBlur, multiple, missing, necessary, checkboxes = false, label, size, fullWidth = true, helperText, placeholder, variant, error, ...autocompleteProps } = props

    const textFieldProps = { label, necessary, missing, size, fullWidth, helperText, placeholder, variant, error, onBlur: onBlur as (...args: any) => void }

    const optionsX = options.map((option) => {
        const optX = configure(option)
        return {
            ...optX,
            data: option,
        }
    }) as Array<Option<OptionData>>

    if (checkboxes)
        autocompleteProps.renderOption = (props, option, { selected }) => (
            <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {(option as Option).label}
            </li>
        )

    const handleChange = (selectedOption) => {
        let selectedData, selectedValue

        if (multiple) {
            const selectedOptions = selectedOption
            selectedData = []
            selectedValue = []

            selectedOptions.forEach((opt) => {
                const optFix = opt.data ? opt : optionsX.find((o) => o.value === opt)
                selectedData.push(optFix.data)
                selectedValue.push(optFix.value)
            })
        } else {
            if (selectedOption !== undefined && selectedOption !== null) {
                const selectedOptionX = configure(selectedOption.data)
                selectedData = selectedOption.data
                selectedValue = selectedOptionX.value
            } else {
                selectedData = null
                selectedValue = null
            }
        }

        onChange && onChange(selectedValue, selectedData)
    }

    const handleChangeFreeSolo = (newText: string) => {
        // if(newText === undefined)return
        onChange && onChange(newText, undefined)
    }

    const isMissing = missing ?? (necessary && (multiple ? !(value && Array.isArray(value) && value.length) : value === undefined || value === null || value === ""))

    ///@ts-ignore
    autocompleteProps.size = autocompleteProps.size ?? "small"

    return (
        <Autocomplete<Option<OptionData>, Multiple>
            multiple={multiple}
            value={value}
            // {...(autocompleteProps.freeSolo ? {inputValue: value } : undefined) as any}
            // inputValue={autocompleteProps.freeSolo ? (value as string || '') : undefined}
            // onChange={autocompleteProps.freeSolo ? undefined :( (e, newValue) => {  handleChange(newValue)})}
            onChange={(e, newValue) => {
                handleChange(newValue)
            }}
            onInputChange={autocompleteProps.freeSolo ? (e, newValue) => handleChangeFreeSolo(newValue) : undefined}
            options={optionsX}
            getOptionLabel={(opt: Option<OptionData>) => opt.label ?? (autocompleteProps.freeSolo ? (opt as any) : optionsX.find((o) => o.value === opt)?.label) ?? ""}
            getOptionDisabled={(opt) => opt.disabled}
            isOptionEqualToValue={(opt, val: any) => val === opt.value}
            autoHighlight
            fullWidth={true ?? fullWidth}
            {...autocompleteProps}
            renderInput={(params) => {
                const missingLive = autocompleteProps.freeSolo ? (!params.inputProps as any).value : isMissing
                return (
                    <RawTextField
                        {...textFieldProps}
                        {...params}
                        value={(params.inputProps as any).value}
                        necessary={necessary}
                        missing={missingLive}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            form: {
                                // ...params.inputProps?.form
                                autocomplete: "off",
                            },
                        }}
                    />
                )
            }}

            // sx={{
            //     writingMode: 'initial',
            //     ...(isMissing && !error && {
            //         // '& label:after':{
            //         //   content:'" - Nécessaire !"'
            //         // },
            //         '& label, & label.Mui-focused ': {
            //             color: theme => theme.palette.warning.light,
            //         },
            //         '& .MuiInput-underline:after': {
            //             borderBottomColor: theme => theme.palette.warning.light,
            //         },
            //         '& .MuiOutlinedInput-root': {
            //             '& fieldset': {
            //                 borderColor: theme => theme.palette.warning.light,
            //             },
            //             '&:hover fieldset': {
            //                 borderColor: theme => theme.palette.warning.light,
            //             },
            //             '&.Mui-focused fieldset': {
            //                 borderColor: theme => theme.palette.warning.light,
            //             },
            //         },
            //     })
            // }}
        />
    )
}

// return (
//     <Autocomplete
//     {...{freeSolo, fullWidth}}
//       style={{width: "100%"}}
//       id={id}

//       options={props.options || unitState.rows}
//       getOptionLabel={(option:any) => option[unitState.labelKey] || ''}
//       isOptionEqualToValue={(option:any, value)=>option && value && option[unitState.lookupKey]===value[unitState.lookupKey] }
//       //style={{ width: 300 }}
//       renderInput={(params) => <TextField
//           {...params}
//            {...rest as any}
//            label={props.label || unitState.label}
//            helperText={unitState.helperText}
//       error = {unitState.isError}

//            />}
//       autoHighlight
//       value={unitState.value}
//       onChange={(e,newValue)=>unitActions.handleChange(newValue)}
//       inputValue={unitState.inputValue}
//       onInputChange={(e,newValue)=> unitActions.handleInputChange(newValue)} //{(e, newInputValue) =>unitActions.handleInputChange(newInputValue)}

//     />
//   );

// if (!multiple && (value !== undefined ,| value !== null)) {
//     const selectedOpt = optionsX.find(optX => optX.value === value)
//     if (selectedOpt) selectedOpt.selected = true
// }
// else if(multiple && Array.isArray(value)){
//     const values = value
//     values.map(val=>{
//         const selectedOpt = optionsX.find(optX => optX.value === val)
//         if (selectedOpt) selectedOpt.selected = true
//     })
// }

// const [currentValues, setCurrentValues] = useState(multiple ? (value || []) : (value !== undefined) ? [value] : [])

// useEffect(()=>{setCurrentValue(value)},[value])

// const handleChange = (selectedOption) => {
//     // setCurrentValues(newValue)
//     console.log(selectedOption)
//     let selectedData, selectedValue;

//     if (multiple) {

//         const selectedOptions = selectedOption
//         selectedData = []
//         selectedValue = []

//         selectedOptions.forEach(opt => {
//             const selectedOptionX = configure(opt)
//             selectedData.push(selectedOption)
//             selectedValue.push(selectedOptionX.value)
//         })
//     } else {
//         const selectedOptionX = configure(selectedOption)
//         selectedData = selectedOption
//         selectedValue = selectedOptionX.value
//     }

//     onChange && onChange(selectedValue, selectedData)
// }
