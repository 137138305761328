import { createStore } from "easy-peasy-classes"
import { cloneDeep } from "lodash"

import { topModel } from "./store.model"

//Generates the store model object
const storeModel = topModel.obj
console.log(storeModel)

//Creates the store from the storeModel and type it
export const store = createStore<topModel>(storeModel)

//Freeze the store to forbid state mutation outside actions (work only at init, easy-peasy action return new unfreezed state, because immer freezing is internally disabled by easy-peasy )
//deepFreeze(store)

//save initial values for the resetState() thunk from EzBase base class
const storeState = store.getState()
export const initialState = cloneDeep(storeState)
store.getActions().setInitialState(initialState)
