import { FormControlLabel, FormHelperText, Switch, SwitchProps } from "@mui/material"
import { createLocalHooks } from "core/store"

import type { commonSwitchModel } from "./switch.model"

type Switch$Props = {
    mapper: (s: any) => any
    label?: string
    labelPlacement?: "end" | "start" | "top" | "bottom"
} & Omit<SwitchProps, "value" | "checked" | "onChange" | "disabled">

export function Switch$(props: Switch$Props) {
    const { mapper: µ, label, labelPlacement, ...switchProps } = props

    const { useLocalState, useLocalActions } = createLocalHooks<commonSwitchModel>((s) => µ(s))

    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    return (
        <>
            <FormControlLabel label={label || unitState.label} labelPlacement={labelPlacement} control={<Switch {...switchProps} value={unitState.value} checked={unitState.value} onChange={(e) => unitActions.handleChange(e.target.checked)} disabled={unitState.isDisabled} />} />
            {unitState.helperText && <FormHelperText>{unitState.helperText}</FormHelperText>}
        </>
    )
}
