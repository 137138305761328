import { withGrid, WithGridProps } from "core/misc"
import { RawRadioGroup, RawRadioGroupProps } from "core/rawFields"
import { useField, useFormikContext } from "formik"

export interface FmkRadioGroupProps<OptionData extends object, M extends boolean> extends Omit<RawRadioGroupProps<OptionData, M>, "value" | "onChange" | "error"> {
    name: string
    validateImmediately?: boolean
}

const FmkRadioGroupUntyped = withGrid(<OptionData extends object, M extends boolean>(props: FmkRadioGroupProps<OptionData, M>) => {
    const { name, validateImmediately, ...otherProps } = props
    const [field, meta, { setValue, setTouched }] = useField<string>(name)
    const { isSubmitting } = useFormikContext()

    const handleChange = (newValue: any, optionData: any) => {
        setTouched(true, false)
        setValue(newValue, true)
    }

    const defaultProps: Partial<RawRadioGroupProps<OptionData, M>> = {
        size: "small",
        onChange: handleChange as any,
    }

    const config = {
        ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
        ...defaultProps,
        ...otherProps,
    }
    if (meta && (meta.touched || validateImmediately) && meta.error) {
        config.error = true
        config.helperText = meta.error
    }

    config.disabled = config.disabled ?? isSubmitting

    return <RawRadioGroup {...config} />
})

export function FmkRadioGroup<OptionData extends object, M extends boolean>(props: FmkRadioGroupProps<OptionData, M> & WithGridProps<{}>) {
    return (<FmkRadioGroupUntyped {...props} />) as any
}

// import { TextFieldProps, TextField, Grid, alertTitleClasses } from '@mui/material'
// import { withGrid } from 'core/misc'
// import { useField, useFormikContext } from 'formik'
// import React from 'react'

// export interface FmkTextFieldProps extends Omit<TextFieldProps, 'value' | 'error'> { name: string}

// export const FmkTextField = withGrid((props: FmkTextFieldProps) => {

//     const { name,...otherProps } = props
//     const [field, meta] = useField<string>(name)
//     const { isSubmitting } = useFormikContext()

//     const defaultProps: TextFieldProps = {
//         fullWidth: true,
//         variant: 'outlined',
//         size:'small'
//     }

//     const config = {
//         ...field, //props from formik to field (value, onChange, onBlur, name, multiple, checked )
//         ...defaultProps,
//         ...otherProps,
//     }

//     if (meta && meta.touched && meta.error) {
//         config.error = true
//         config.helperText = meta.error
//     }

//     config.disabled = config.disabled ?? isSubmitting

//     return (<TextField {...config} />)

// })
