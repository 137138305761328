import { Route, Routes } from 'react-router-dom';
import { Exemple } from './exemple/Example.com';

export function Playground() {
    return (

        <Routes>
            <Route path='' element={<div>Playground</div>} />
            <Route path='example' element={<Exemple />} />
        </Routes>
    )
}