import { Checkbox, Grid,List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material"
import * as colors from "@mui/material/colors"
import { createLocalHooks } from "core/store"
import { isNil } from "lodash"

import type { commonTeacherTeachingLevelsModel } from "./teacherTeachingLevels.model"

const listStyle = { overflowY: "auto", maxHeight: "60vh" } as const

export function TeacherTeachingLevels$(props: { mapper: (s: any) => any; id?: string }) {
    const { mapper: µ } = props
    const { useLocalState, useLocalActions } = createLocalHooks<commonTeacherTeachingLevelsModel>((s) => µ(s))
    const unitState = useLocalState((s) => s)
    const unitActions = useLocalActions((s) => s)

    if (!unitState) return null
    const { TeachingLevels, allTeachings, currentTeachingIndex } = unitState
    const currentTeaching = allTeachings[currentTeachingIndex]

    //useEffect(()=>{unitActions.init(teacherId)},[]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <List sx={listStyle}>
                    {allTeachings.map((teaching, teachingIndex) => {
                        //const labelId = `checkbox-list-label-${value}`;

                        return (
                            <ListItem key={teaching.id} role={undefined} dense button onClick={() => unitActions.setCurrentTeaching(teachingIndex)} style={{ backgroundColor: currentTeachingIndex === teachingIndex ? colors.indigo[100] : "" }}>
                                <ListItemIcon>
                                    <Checkbox edge="start" checked={TeachingLevels.filter((tl) => tl.Teaching === teaching.id).length > 0} tabIndex={-1} disableRipple />
                                </ListItemIcon>
                                <ListItemText id={teaching.id.toString()} primary={teaching.name} />
                                <ListItemSecondaryAction />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>

            <Grid item xs={6}>
                <List sx={listStyle}>
                    {!isNil(currentTeachingIndex) &&
                        currentTeaching.TeachingLevels.sort((a, b) => a.Level.orderNb - b.Level.orderNb).map((tl) => {
                            //const labelId = `checkbox-list-label-${value}`;

                            return (
                                <ListItem key={tl.Teaching + "-" + tl.Level.id} dense button onClick={() => unitActions.handleLevelToggle(tl.Level.id)}>
                                    <ListItemIcon>
                                        <Checkbox edge="start" checked={TeachingLevels.some((TL) => TL.Teaching === currentTeaching.id && TL.Level === tl.Level.id)} tabIndex={-1} disableRipple />
                                    </ListItemIcon>
                                    <ListItemText primary={tl.Level.nameShort} />
                                    <ListItemSecondaryAction />
                                </ListItem>
                            )
                        })}
                </List>
            </Grid>
        </Grid>
    )
}
