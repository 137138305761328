import { Box, BoxProps } from "@mui/material"
import React from "react"

export const HFlex = React.forwardRef(function HFlex(props: Omit<BoxProps, "display">, ref) {
    const props2 = { ...props }

    props2.flexDirection = props2.flexDirection ?? "row"
    props2.alignItems = props2.alignItems ?? "center"

    return <Box {...props2} display="flex" ref={ref} />
})

export const VFlex = React.forwardRef(function VFlex(props: Omit<BoxProps, "display">, ref) {
    const props2 = { ...props }

    props2.flexDirection = props2.flexDirection ?? "column"
    props2.alignItems = props2.alignItems ?? "stretch"

    return <Box {...props2} display="flex" />
})

export const Spacer = React.forwardRef(function Spacer(props: BoxProps, ref) {
    return <Box flexGrow={1} {...props} />
})

export const Center = React.forwardRef(function Center(props: Omit<BoxProps, "display"> & { fullHeight?: boolean }, ref) {
    const { fullHeight, ...props2 } = { ...props }

    props2.flexDirection = props2.flexDirection ?? "row"
    props2.alignItems = props2.alignItems ?? "center"
    props2.justifyContent = props2.justifyContent ?? "center"
    props2.width = props2.width ?? "100%"

    if (fullHeight) {
        props2.height = props2.height ?? "100%"
        props2.flexGrow = props2.flexGrow ?? 1
    }

    return <Box {...props2} display="flex" />
})
