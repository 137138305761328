import type { GridActionsColDef, GridColDef, GridRenderEditCellParams, GridRowParams,GridSingleSelectColDef } from "@mui/x-data-grid-pro"
import { DayDate, isIsoDateString } from "core/utils"
import type { LiteralUnion } from "core/utils/types"
import { DependencyList, ReactNode, useMemo } from "react"

export type GridColumnTyped<R extends object, V, F> = Omit<GridColDef<R, V, F>, "valueGetter" | "id" | "field" | "type" | "renderEditCell"> & {
    valueGetter: Exclude<Extract<keyof R, string> | GridColDef<R>["valueGetter"], undefined>
    type: Exclude<ColumnType, "actions">
    headerName: string
    renderEditCell?: (params: GridRenderEditCellParams<V, R, F>) => ReactNode
    valueOptions?: GridSingleSelectColDef<R>["valueOptions"]
}

export type ActionColumnTyped<R extends object> = Omit<GridActionsColDef, "id" | "field" | "type" | "getActions" | "renderCell"> & {
    type: "actions"
    getAction?: (params: GridRowParams<R>) => ReactNode
    getActions?: (params: GridRowParams<R>) => ReactNode[]
}

export function useGridColumns<R extends object, Fld extends string>(
    data: R[] | undefined,
    columnsFactory: (defCol: <V = void, F = V>(colDef: void extends V ? ActionColumnTyped<R> : GridColumnTyped<R, V, F>) => V extends void ? GridActionsColDef : GridColDef<R, V, F>) => Record<Fld, any>,
    deps: DependencyList
): { fields: Record<Fld, string>; columns: GridColDef<R>[] } {
    function defineColumn<V, F = V>(colDef: GridColumnTyped<R, V, F> | ActionColumnTyped<R>) {
        if (typeof colDef.valueGetter !== "function" && colDef.valueGetter !== undefined) {
            const key = colDef.valueGetter
            //@ts-ignore
            colDef.valueGetter = ({ row }) => row[key]
        }
        if (colDef.type === "actions") {
            const actionDef = colDef as ActionColumnTyped<any>
            //@ts-ignore
            if (actionDef.getAction) actionDef.renderCell = actionDef.getAction
        }
        if (colDef.type === "date") {
            const rawValueGetter = colDef.valueGetter
            colDef.valueGetter = ({ row }) => {
                //@ts-ignore
                const val = rawValueGetter({ row })
                if (DayDate.isDayDateString(val)) {
                    return DayDate.fromISOString(val).toJsDate()
                } else {
                    return val
                }
            }
        }
        return colDef as GridColDef<R, V, F>
    }

    return useMemo(() => {
        const defMap = columnsFactory(defineColumn as any)

        const fieldsArr = Object.keys(defMap) as Fld[]

        const columns = []
        const fieldsMap: any = {}
        for (const field of fieldsArr) {
            const column = defMap[field]
            column.field = field
            columns.push(column)

            fieldsMap[field] = field
        }

        return { fields: fieldsMap, columns }
    }, deps) as any
}

export type ColumnTypeBuiltIn = "string" | "number" | "date" | "dateTime" | "boolean" | "singleSelect" | "actions"
export type ColumnTypeCustom = "trimester" | "multipleSelect"
export type ColumnType = LiteralUnion<ColumnTypeBuiltIn | ColumnTypeCustom>
