import { rasmik, ReadData, Teaching, TeachingLevel } from "core/services/rasmik"
import { EzModel } from "core/store/base"
import { Action, Child, Computed, Model, Property, Thunk } from "easy-peasy-classes"

//TODO: Test

@Model
export class commonTeacherTeachingLevelsModel extends EzModel {
    @Property
    allTeachings: ReadData<Teaching, { children: { TeachingLevels: { children: { Level: true } } } }>[] = []

    @Property
    TeachingLevels: ReadData<TeachingLevel, { include: [] }>[] = []

    @Property
    currentTeachingIndex: number = null

    // @Thunk
    // export() {
    //     return this.TeachingLevels
    // }

    // @Thunk
    // import(TeachingLevels: Loaded<TeachingLevel>[]) {
    //     this.setTeachingLevels(TeachingLevels)
    // }

    @Thunk
    async init() {
        this.resetState()

        // eslint-disable-next-line pedagome/ensure-valid-token-typescript
        const allTeachings = await rasmik
            .readMany(Teaching)
            .options({ children: { TeachingLevels: { children: { Level: true } } } })
            .run()

        this.setStateFn((s) => {
            s.allTeachings = allTeachings
        })
    }

    @Action
    setTeachingLevels(TeachingLevels: ReadData<TeachingLevel>[]) {
        this.TeachingLevels = TeachingLevels
    }

    @Action
    setCurrentTeaching(teachingIndex: number) {
        this.currentTeachingIndex = teachingIndex
    }

    @Action
    handleLevelToggle(levelId: number) {
        const currentTeaching = this.allTeachings[this.currentTeachingIndex]

        const currentAllTLS = currentTeaching.TeachingLevels.sort((a, b) => a.Level.orderNb - b.Level.orderNb)
        const TLindex = currentAllTLS.findIndex((TL) => TL.Level.id === levelId)

        const op = this.TeachingLevels.some((tl) => tl.Teaching === currentTeaching.id && tl.Level === levelId) ? "uncheck" : "check"

        for (let i = 0; i <= TLindex; i++) {
            if (op === "check") checkLevel(this, currentAllTLS[i].Level.id)
            else if (op === "uncheck") uncheckLevel(this, currentAllTLS[i].Level.id)
        }
    }

    @Computed
    get getTeachingsCount() {
        return new Set(this.TeachingLevels.map((tl) => tl.Teaching)).size
    }
}

//NoAction
function checkLevel(state: commonTeacherTeachingLevelsModel, levelId: number) {
    const currentTeaching = state.allTeachings[state.currentTeachingIndex]

    if (state.TeachingLevels.some((tl) => tl.Teaching === currentTeaching.id && tl.Level === levelId)) return
    state.TeachingLevels.push({
        Teaching: currentTeaching.id,
        Level: levelId,
    })
}

//NoAction
function uncheckLevel(state: commonTeacherTeachingLevelsModel, levelId: number) {
    const currentTeaching = state.allTeachings[state.currentTeachingIndex]

    const tlIndex = state.TeachingLevels.findIndex((tl) => tl.Teaching === currentTeaching.id && tl.Level === levelId)

    if (tlIndex > -1) {
        state.TeachingLevels.splice(tlIndex, 1)
    }
}
