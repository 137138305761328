import { Grid, GridSize,Typography } from "@mui/material"
import type { ReactNode } from "react"

interface Props {
    size: GridSize
    label: string
    data: ReactNode
    noBottom?: boolean
    left?: boolean
    right?: boolean
    top?: boolean

    borders?: {
        t: number
        b: number
        r: number
        l: number
    }

    borderColor?: string
}

export const DataDisplayItem = ({ size = 12, label = "", data = "/", noBottom, left, right, top, borders = { t: 0, b: 1, r: 0, l: 0 }, borderColor = "rgba(0, 0, 0, 0.12)" }: Props) => {
    if (noBottom) borders.b = 1
    if (left) borders.l = 1
    if (top) borders.t = 1
    if (right) borders.r = 1

    return (
        <Grid
            container
            item
            xs={size}
            style={{
                padding: "10px",
                borderTop: `${borders.t.toString()}px solid ${borderColor}`,
                borderBottom: `${borders.b.toString()}px solid ${borderColor}`,
                borderLeft: `${borders.l.toString()}px solid ${borderColor}`,
                borderRight: `${borders.r.toString()}px solid ${borderColor}`,
            }}
        >
            <Grid item xs={12}>
                <Typography variant="caption">{label}</Typography>
                {typeof data === "string" ? <Typography>{data}</Typography> : data}
            </Grid>
        </Grid>
    )
}
